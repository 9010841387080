import { select, takeEvery, put, call, StrictEffect } from 'redux-saga/effects';

import storageAvailable from 'utils/storageAvailable';
import { apiDropdownLinks } from './requests';
import {
  fetchSearchHistorySuccess,
  fetchSearchHistoryFailure,
  fetchDropdownLinksSuccess,
  fetchDropdownLinksFailure
} from './action-creators';

import { SearchLink } from './types';
import { ApplicationState } from 'types/rootState';

function* searchResultsSaga(): Generator<StrictEffect> {
  yield takeEvery('SEARCH/FETCH_HISTORY', fetchSearchHistory);
  yield takeEvery('SEARCH/FETCH_DROPDOWN_LINKS', fetchDropdownLinks);
}

function* fetchSearchHistory(): Generator<StrictEffect, any, SearchLink[]> {
  if (storageAvailable('localStorage')) {
    const recentSearchState = (state: ApplicationState) =>
      state.search.searchHistory;
    const recentSearch: SearchLink[] = yield select(recentSearchState);
    const localStorageKey = 'searchHistory';
    const searchHistoryData = localStorage.getItem(localStorageKey);
    const history = searchHistoryData && JSON.parse(searchHistoryData);

    if (history && recentSearch !== history) {
      try {
        yield put(fetchSearchHistorySuccess(history));
      } catch (e) {
        yield put(fetchSearchHistoryFailure());
      }
    }
  }
}

function* fetchDropdownLinks(): Generator<StrictEffect, any, any> {
  try {
    const dropdownLinks = yield call(apiDropdownLinks);
    yield put(fetchDropdownLinksSuccess(dropdownLinks.data.data));
  } catch (err) {
    yield put(fetchDropdownLinksFailure());
  }
}

export default searchResultsSaga;
