import reducer from './reducer';
import * as actionCreators from './action-creators';

export const {
  initializeEppo,
  initializeEppoSuccess,
  initializeEppoFailure,
  fetchVariationSuccess,
  fetchVariationFailure,
  setFeaturePreview
} = actionCreators;

export type { ExperimentsState } from './types';

export default reducer;
