import * as React from 'react';

/* 
  This Hook is used for checking if an element is in visible in the viewport.
  Threshold is the percentage of the element that needs to be visible in the 
  viewport for the element to be considered visible.
  Passing 0 means that the element is considered visible when the element enters the viewport.
  1 means that the entire element must be visible in the viewport for it to be considered visible.  
  0.5 means that half of the element must be visible in the viewport for it to be considered visible.
  
*/

const useIsVisible = (
  threshold: number
): [boolean, React.LegacyRef<HTMLDivElement>] => {
  const [elementVisible, setElementVisible] = React.useState<boolean>(false);
  const element = React.useRef<HTMLDivElement | null>(null);
  const observerRef = React.useRef<IntersectionObserver | null>(null);

  React.useEffect(() => {
    if ('IntersectionObserver' in window) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [threshold]
      };

      observerRef.current = new IntersectionObserver(([entries]) => {
        setElementVisible(entries.isIntersecting);
      }, options);
    }
  }, [threshold]);

  React.useEffect(() => {
    element.current && observerRef?.current?.observe(element.current);
    () => {
      observerRef?.current?.disconnect();
    };
  }, [element]);

  return [elementVisible, element];
};

export default useIsVisible;
