// @flow

import api from 'utils/huckberryApi';

type AboutRequestType = {|
  type: string,
  quantity?: number
|};

export function fetchInstagramBlocks(action: AboutRequestType) {
  return api.get('/api/instagram_blocks', {
    params: { quantity: action?.quantity }
  });
}
