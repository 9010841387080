import huckberryApi from 'utils/huckberryApi';

import {
  AttentiveSuccessResponseType,
  AttentiveFailureResponseType
} from './types';

const unsubscribe = (
  phone: string,
  email: string,
  onSuccessHandler: (response: AttentiveSuccessResponseType) => void,
  onFailureHandler: (response: AttentiveFailureResponseType) => void
) => {
  huckberryApi
    .post('/api/attentive/unsubscribe', {
      user: { phone, email }
    })
    .then(({ data }) => {
      onSuccessHandler(data);
    })
    .catch(({ response: { data } }) => {
      onFailureHandler(data);
    });
};

export default unsubscribe;
