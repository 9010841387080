import { BundlesDataType } from './types';

const getBundles = (productIds: number[]) => ({
  type: 'BUNDLES/GET',
  productIds
});

const getBundlesSuccess = (bundlesData: BundlesDataType) => ({
  type: 'BUNDLES/GET_SUCCESS',
  bundlesData
});

const getBundlesFailure = () => ({
  type: 'BUNDLES/GET_FAILURE'
});

const markActiveBundle = (productId: number) => ({
  type: 'BUNDLES/MARK_ACTIVE_BUNDLE',
  productId
});

export { getBundles, getBundlesSuccess, getBundlesFailure, markActiveBundle };
