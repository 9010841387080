import { OrderStateType, OrderActionType } from './types';

const initialState: OrderStateType = {
  number: '',
  shipments: [],
  lineItems: [],
  billAddress: null,
  shipAddress: null,
  paymentDescription: '',
  ccType: null,
  ccLastDigits: '',
  displayItemTotal: '',
  displayShipTotal: '',
  displayAdditionalTaxTotal: '',
  displayStoreCreditTotal: '',
  displayPromoTotal: '',
  displayTotal: '',
  totalSavings: '',
  total: 0,
  hasSavings: false,
  displayStatus: null,
  completedAt: '',
  deliveryNotificationDescription: '',
  newOrder: false,
  coupon: '',
  userCancelPermitted: false,
  loading: false,
  loaded: false,
  error: null
};

const reducer = (
  state: OrderStateType = initialState,
  action: OrderActionType
) => {
  switch (action.type) {
    case 'ORDER/FETCH':
    case 'ORDER/CANCEL':
      return { ...state, loading: true, error: null };
    case 'ORDER/FETCH_SUCCESS':
      return {
        ...state,
        ...action.data,
        loading: false,
        loaded: true,
        error: null
      };
    case 'ORDER/FETCH_FAILURE':
      return { ...state, error: action.error, loading: false, loaded: true };
    default:
      return state;
  }
};

export default reducer;
