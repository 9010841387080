import cloneDeep from 'lodash/cloneDeep';

import { BundlesStateType, BundlesActionType } from './types';

const initialState: BundlesStateType = {
  bundlesData: {},
  error: false,
  loading: false
};

const reducer = (
  state: BundlesStateType = initialState,
  action: BundlesActionType
) => {
  switch (action.type) {
    case 'BUNDLES/GET':
      return { ...state, loading: true };
    case 'BUNDLES/GET_SUCCESS':
      return {
        bundlesData: { ...state.bundlesData, ...action.bundlesData },
        error: false,
        loading: false
      };
    case 'BUNDLES/GET_FAILURE':
      return {
        bundlesData: {},
        error: true,
        loading: false
      };
    case 'BUNDLES/MARK_ACTIVE_BUNDLE': {
      const newBundlesState = cloneDeep(state.bundlesData);

      for (const id in newBundlesState) {
        newBundlesState[id].selected =
          Boolean(newBundlesState[id].name) && action.productId === Number(id);
      }

      return {
        bundlesData: newBundlesState,
        error: false,
        loading: false
      };
    }
    default:
      return state;
  }
};

export default reducer;
