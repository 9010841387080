// @flow

import './SignupEmailStep.scss';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import AuthenticationContainer, {
  type AuthenticationProps
} from 'components/Authentication/Container';
import EmailForm from 'components/Shared/AuthForm/TwoStepSignup/EmailForm';
import CTALink from 'components/Shared/AuthForm/CTALink';
import AutoLink from 'components/utils/AutoLink';

import { close } from 'modules/auth-modal';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (Views) => void
|};

const SignupEmailStep = ({ onChangeView }: Props) => {
  const dispatch = useDispatch();

  const onChangeViewHandler = React.useCallback(() => onChangeView('login'), [
    onChangeView
  ]);

  const onTermsClickHandler = React.useCallback(() => dispatch(close()), [
    dispatch
  ]);

  return (
    <div className="SignupEmailStep">
      <div className="SignupEmailStep__wrapper tc">
        <p className="SignupEmailStep__eyebrow fg-condensed--medium fw--600">
          Only Sitewide Sale of 2023
        </p>
        <p className="SignupEmailStep__title fg-condensed--medium fw--600">
          15% Off Sitewide Today
        </p>
        <p className="SignupEmailStep__content fg-condensed--medium">
          Ends 11/16/23 Midnight PT
        </p>
        <div className="SignupEmailStep__form">
          <AuthenticationContainer trait="signup-from-sitewide-sale-soft-gate-modal">
            {({
              onClickFacebookSignup,
              onClickGoogleSignup
            }: AuthenticationProps) => (
              <EmailForm
                buttonValue="Sign me up"
                onClickFacebookSignup={onClickFacebookSignup}
                onClickGoogleSignup={onClickGoogleSignup}
                trigger="Sitewide Sale View"
              />
            )}
          </AuthenticationContainer>
          <CTALink
            textBefore="Already a member?"
            linkText="Log in"
            onClick={onChangeViewHandler}
          />
        </div>
      </div>

      <div className="SignupEmailStep__footer fg-condensed--medium">
        *Some exclusions apply.
        <br />
        By continuing you agree to our{' '}
        <AutoLink to="/terms" target="_blank" onClick={onTermsClickHandler}>
          terms and conditions
        </AutoLink>
      </div>
    </div>
  );
};

export default SignupEmailStep;
