import { EppoInitializationError, VariationValue } from './types';

export const initializeEppo = () => ({
  type: 'EXPERIMENTS/INITIALIZE_EPPO_REQUEST'
});

export const initializeEppoSuccess = () => ({
  type: 'EXPERIMENTS/INITIALIZE_EPPO_SUCCESS'
});

export const initializeEppoFailure = (error: EppoInitializationError) => ({
  type: 'EXPERIMENTS/INITIALIZE_EPPO_FAILURE',
  payload: error
});

export const fetchVariationSuccess = (
  featureName: string,
  variation: VariationValue,
  variationTypes: string[],
  previewLabel: string
) => ({
  type: 'EXPERIMENTS/FETCH_VARIATION_SUCCESS',
  featureName,
  variation,
  variationTypes,
  previewLabel
});

export const fetchVariationFailure = (error: EppoInitializationError) => ({
  type: 'EXPERIMENTS/INITIALIZE_EPPO_FAILURE',
  error
});

export const setFeaturePreview = (
  featureName: string,
  variation: string,
  variationTypes: string[],
  previewLabel: string
) => ({
  type: 'EXPERIMENTS/SET_FEATURE_PREVIEW',
  featureName,
  variation,
  variationTypes,
  previewLabel
});
