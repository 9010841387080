// @flow

let history;

if (typeof window !== 'undefined') {
  const { createBrowserHistory } = require('history');
  const qhistory = require('qhistory').default;
  const { stringify, parse } = require('qs');

  history = qhistory(createBrowserHistory(), stringify, parse);

  history.listen((location) => {
    if (typeof window.ga === 'function') {
      window.ga('set', 'page', location.pathname);
      window.ga('send', 'pageview');
    }

    if (window._sift !== undefined) {
      window._sift.push(['_trackPageview']);
    }
  });
}

export default history;
