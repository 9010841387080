// @flow

import type { SitemapStateType, SitemapActionType } from './types';

const reducer = (
  state: SitemapStateType = {
    links: { pages: [], posts: [], products: [] }
  },
  action: SitemapActionType
) => {
  Object.freeze(state);
  switch (action.type) {
    case 'SITEMAP/FETCH':
      return { links: { ...state.links }, loading: true };
    case 'SITEMAP/FETCH_SUCCESS':
      return { links: { ...state.links, ...action.data }, loading: false };
    case 'SITEMAP/FETCH_FAILURE':
      return { links: { ...state.links }, loading: false };
    default:
      return state;
  }
};

export default reducer;
