import './SMSInput.scss';

import * as React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Button from 'components/Shared/AuthForm/Button';
import { events, trackAction } from 'modules/analytics';
import useAttentive from 'hooks/useAttentive';
import formatPhoneNumber from 'utils/formatPhoneNumber';

import { ApplicationState } from 'types/rootState';
import { AttentiveSuccessResponseType } from 'api/attentive/types';

type Props = {
  smsSignupSuccess: (phoneNumber: string) => void;
  trigger: string;
  buttonTitle: string;
  inlineStyle?: boolean;
  placeholderText?: string;
  lightTheme?: boolean;
};

// Shared Input for all SMS signup logic
// Utilized in Account Dropdown and SMSImageModal
const SMSInput = ({
  smsSignupSuccess,
  trigger,
  buttonTitle,
  inlineStyle = false,
  placeholderText,
  lightTheme
}: Props) => {
  const [attentiveState, attentiveApi] = useAttentive();
  const [formError, setFormError] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const currentUserEmail = useSelector(
    (state: ApplicationState) => state.session.signedInUser.email || ''
  );
  const isMobile = useSelector(
    (state: ApplicationState) => state.ui.browserWidth <= 1000
  );

  React.useEffect(
    () =>
      trackAction(events.SMS_REGISTRATION_VIEWED, {
        trigger,
        button_title: buttonTitle
      }),
    [buttonTitle, trigger]
  );

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Enter or Return
      smsHandler('subscribe');
    }
  };

  React.useEffect(() => {
    if (!attentiveState.hasRun) {
      return;
    }

    if (attentiveState.success) {
      smsSignupSuccess(
        (attentiveState.attentiveResponse as AttentiveSuccessResponseType)?.user
          ?.phone || ''
      );
    }

    if (!attentiveState.success) {
      setFormError('There was a problem signing up. Please try again');
    }
  }, [attentiveState, smsSignupSuccess]);

  const smsHandler = (action: 'subscribe') => {
    if (!phoneNumber) {
      return;
    }
    trackAction(events.USER_SMS_REGISTERED, {
      trigger,
      button_title: buttonTitle,
      entry: phoneNumber
    });

    attentiveApi(phoneNumber, currentUserEmail, action);
  };

  const onPhoneNumberChange = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(formatPhoneNumber(event.currentTarget.value));
  };

  const errorMessage = () => {
    if (formError.length > 0) {
      return (
        <small
          className={classnames('db padb1 fg-condensed--xxxsmall', {
            tl: inlineStyle,
            'SMSInput__error--light': lightTheme,
            'color--red': !lightTheme
          })}
        >
          {formError}
        </small>
      );
    }
  };

  return (
    <div className="SMSInput">
      <div
        className={classnames('SMSInput__container', {
          'SMSInput__container--inline': inlineStyle
        })}
      >
        <div className="SMSInput__input--wrapper">
          {!placeholderText && (
            <p className="SMSInput__input--placeholder fg-condensed--xxxsmall">
              Mobile Phone Number
            </p>
          )}
          <input
            type="tel"
            placeholder={placeholderText || ''}
            onChange={onPhoneNumberChange}
            value={phoneNumber}
            className="SMSInput__input"
            autoFocus={!isMobile && !inlineStyle}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
              onInputKeyDown(event)
            }
          />
        </div>
        {!inlineStyle && errorMessage()}
        <Button value={buttonTitle} onClick={() => smsHandler('subscribe')} />
      </div>
      {inlineStyle && errorMessage()}
      <p
        className={classnames('SMSInput__text--small', {
          padt3: formError.length <= 0 && !inlineStyle,
          padt1: formError.length > 0,
          tc: !inlineStyle,
          tl: inlineStyle,
          'color--faded': !lightTheme,
          'color--white': lightTheme
        })}
      >
        By submitting this form, you agree to receive recurring automated
        promotional and personalized marketing text messages (e.g. cart
        reminders) from Huckberry at the cell number used when signing up.
        Consent is not a condition of any purchase. Reply HELP for help and STOP
        to cancel. Msg frequency varies. Msg & data rates may apply. View{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://attnl.tv/p/gRJ"
          className="link--underline"
        >
          Terms
        </a>{' '}
        &{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://attnl.tv/p/gRJ"
          className="link--underline"
        >
          Privacy
        </a>
        .
      </p>
    </div>
  );
};

export default SMSInput;
