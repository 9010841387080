import { actionTypes } from './index';
import { takeEvery } from 'redux-saga/effects';

export default function* uiSaga() {
  yield takeEvery(actionTypes.ENTER_FULLSCREEN, function* () {
    yield document.documentElement.classList.add('ui--fullscreen');
  });

  yield takeEvery(actionTypes.EXIT_FULLSCREEN, function* () {
    yield document.documentElement.classList.remove('ui--fullscreen');
  });
}
