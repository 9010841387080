// @flow

import api from 'utils/huckberryApi';

export const fetchShippingRules = (
  shippableId: number,
  shippableType: string,
  ruleType: string,
  shippingMethodId: ?number
) =>
  api.post('/api/shipping/rules', {
    shippable: { shippableId, shippableType, ruleType, shippingMethodId }
  });
