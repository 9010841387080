// @flow

import type { Saga } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';

import { fetchSitemap } from './requests';
import { getSitemapSuccess, getSitemapFailure } from './action-creators';

function* sitemapSaga(): Saga {
  yield takeEvery('SITEMAP/FETCH', getSitemapSaga);
}

function* getSitemapSaga() {
  try {
    const response = yield call(fetchSitemap);
    const { data } = response.data;
    yield put(getSitemapSuccess(data));
  } catch (err) {
    const { error } = err.response.data;
    yield put(getSitemapFailure(error));
  }
}

export default sitemapSaga;
