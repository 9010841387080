// @flow

import type { AuthModalState, AuthModalAction } from './types.js';

import types from './action-types';

const initialState = {
  open: false,
  view: 'initial',
  authDomain: null,
  email: null
};

const reducer = (
  state: AuthModalState = initialState,
  action: AuthModalAction
) => {
  switch (action.type) {
    case types.AUTH_MODAL_OPEN_SUCCESS:
      return {
        ...state,
        open: true,
        view: action.view,
        authDomain: action.authDomain
      };
    case types.AUTH_MODAL_CLOSE:
      return {
        ...state,
        open: false,
        view: 'initial',
        authDomain: 'plain',
        force: false
      };
    case types.AUTH_MODAL_CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
        force: false
      };
    case types.AUTH_MODAL_CLEAR_EMAIL:
      return {
        ...state,
        email: null,
        open: false
      };
    case types.AUTH_MODAL_NEWSLETTER_OPTIN:
      return {
        ...state,
        email: action.email
      };
    default:
      return state;
  }
};

export default reducer;
