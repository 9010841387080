// @flow

const updateRedirects = (data: Array<{ from: string, to: string }>) => {
  return {
    type: 'REDIRECTS/UPDATE',
    data
  };
};

export { updateRedirects };
