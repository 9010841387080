const address = (
  state = {
    isFetching: false
  },
  action
) => {
  switch (action.type) {
    case 'ADDRESS_FIELDS/REQUEST_STATES':
      return Object.assign({}, state, {
        [action.countryId]: Object.assign({}, state[action.countryId], {
          isFetching: true
        })
      });
    case 'ADDRESS_FIELDS/RECEIVE_STATES':
      return Object.assign({}, state, {
        [action.countryId]: Object.assign({}, state[action.countryId], {
          isFetching: false,
          states: action.states
        })
      });
    default:
      return state;
  }
};

export default address;
