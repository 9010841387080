// @flow
import type { GiftCardVariantType, NewGiftCardDataType } from './types';

export const getGiftCardVariants = () => ({
  type: 'GIFT_CARD_VARIANTS/FETCH'
});

export const getGiftCardVariantsSuccess = (
  data: Array<GiftCardVariantType>
) => ({
  type: 'GIFT_CARD_VARIANTS/FETCH_SUCCESS',
  data
});

export const getGiftCardVariantsFailure = (err: any) => ({
  type: 'GIFT_CARD_VARIANTS/FETCH_FAILURE',
  err
});

export const createGiftCard = (giftCardData: NewGiftCardDataType) => ({
  type: 'GIFT_CARD/CREATE',
  giftCardData
});

export const createGiftCardSuccess = () => ({
  type: 'GIFT_CARD/CREATE_SUCCESS'
});

export const createGiftCardFailure = (err: any) => ({
  type: 'GIFT_CARD/CREATE_FAILURE',
  err
});
