// @flow

const extractKeyword = (str: string) => {
  // Lookbehind assertion is not supported in some versions of Safari.
  // One way to achieve the say while avoiding Lookbehind is to use a non-capturing group
  // for 'keyword' and then extract what goes after it.
  const matched = str.match(/(?:\?keywords=)([^&]*)/);
  return matched ? decodeURIComponent(matched[1]) : '';
};

export default extractKeyword;
