// @flow

import * as React from 'react';

const useRetrieveChildByName = (): ((
  children: ?React.Node,
  displayName: string
) => null | React.Node) => {
  const retrieveChildByName = (children, displayName): React.Node | null => {
    if (children === null) {
      return null;
    }

    const subComponents = React.Children.map(children, (child) =>
      child && child.type.displayName === displayName ? child : null
    );

    return subComponents?.filter((component) => component != null)[0] || null;
  };

  return retrieveChildByName;
};

export default useRetrieveChildByName;
