// @flow

import api from 'utils/huckberryApi';

import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import qs from 'qs';

type FacetsType = {|
  sizes: Array<string>,
  prices: Array<string>,
  brand: Array<string>,
  color: Array<string>,
  inseam: Array<string>,
  fit: Array<string>,
  clearance: Array<string>,
  bestseller: Array<string>,
  finalSale: Array<string>,
  lined: Array<string>,
  weight: Array<string>,
  plaidType: Array<string>,
  printType: Array<string>
|};

type Params = {
  keywords: string,
  page?: number,
  perPage?: number,
  type?: string,
  facets?: FacetsType
};

export const apiSearchResults = ({
  keywords,
  page,
  perPage,
  type,
  facets
}: Params) =>
  api.get('/api/search', {
    params: omitBy(
      { keywords, page: page || 1, per_page: perPage || 24, type, facets },
      isNil
    ),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    }
  });
