// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export type {
  ShippingTouchpointsType,
  ShippingTouchpointsStateType
} from './types';

export const { getShippingTouchpoints } = actionCreators;

export default reducer;
