import reducer from './reducer';

import * as actionCreators from './action-creators';

export const {
  yotpoWidgetLoad,
  yotpoWidgetLoadSuccess,
  yotpoWidgetLoadFailure
} = actionCreators;

export type { YotpoStateType } from './types';

export default reducer;
