// @flow

import type { Saga } from 'redux-saga';
import { call, takeLatest, put, select } from 'redux-saga/effects';

import types from './action-types';
import { fetchCallouts } from './requests';
import {
  getCalloutsSuccess,
  getCalloutsFailure,
  getCalloutsCancel
} from './action-creators';

import type { CalloutsStateType } from './types';
import type { ApplicationState } from 'types';

function* calloutsSaga(): Saga {
  yield takeLatest(types.CALLOUTS_FETCH, getCalloutsSaga);
}

function* getCalloutsSaga(action) {
  const calloutsState: CalloutsStateType = yield select(
    (state: ApplicationState) => state.callouts
  );

  const calloutIdsToRequest = action.ids.reduce((newCalloutIds, calloutId) => {
    const calloutExists = Object.values(calloutsState).some(
      (calloutEntry: any) => {
        if (typeof calloutEntry === 'string') {
          return false;
        }

        return calloutEntry?.id === calloutId;
      }
    );

    // don't bother requesting callout that is already in redux store
    if (calloutExists) {
      return newCalloutIds;
    }

    newCalloutIds.push(calloutId);
    return newCalloutIds;
  }, []);

  if (calloutIdsToRequest.length > 0) {
    try {
      const response = yield call(fetchCallouts, action.ids);

      yield put(getCalloutsSuccess(response.data.data));
    } catch (err) {
      yield put(getCalloutsFailure(err));
    }
  } else {
    yield put(getCalloutsCancel());
  }
}

export default calloutsSaga;
