import reducer from './reducer';
import * as actionCreators from './action-creators';

export const { openSMSModal, closeSMSModal } = actionCreators;

export type SMSModalState = {
  modalOpen: boolean;
  trigger: string;
};

export type SMSModalActions =
  | {
      type: 'SMS_MODAL/OPEN';
      trigger: string;
      modalOpen: boolean;
    }
  | {
      type: 'SMS_MODAL/CLOSE';
    };

export default reducer;
