import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  initializeEppoFailure,
  initializeEppoSuccess
} from './action-creators';
import { Session } from './types';

import { IAssignmentLogger, init } from '@eppo/js-client-sdk';

import { events, trackAction } from 'modules/analytics';

const getEppoApiKey = (state: Session) => state.session.config.eppoApiKey;

export function analyticsId(): { id?: string; isAnonymous?: boolean } {
  if (
    typeof window === 'undefined' ||
    typeof window.rudderanalytics === 'undefined'
  ) {
    return {};
  }

  const analyticsId =
    window.rudderanalytics.getUserId && window.rudderanalytics.getUserId();
  if (analyticsId) {
    return {
      id: analyticsId,
      isAnonymous: false
    };
  }

  const anonymousId =
    window.rudderanalytics.getAnonymousId &&
    window.rudderanalytics.getAnonymousId();

  return { id: anonymousId, isAnonymous: true };
}

const assignmentLogger: IAssignmentLogger = {
  logAssignment(assignment) {
    trackAction(events.EPPO_ASSIGNMENT, {
      userId: assignment.subject,
      event: 'Eppo Randomized Assignment',
      type: 'track',
      properties: { ...assignment }
    });
  }
};

function* experimentsSaga(): SagaIterator {
  yield takeLatest('EXPERIMENTS/INITIALIZE_EPPO_REQUEST', initializeEppoSaga);
}

function* initializeEppoSaga() {
  try {
    const eppoApiKey: string = yield select(getEppoApiKey);
    yield call(init, { apiKey: eppoApiKey, assignmentLogger });
    yield put(initializeEppoSuccess());
  } catch (error) {
    yield put(initializeEppoFailure(error));
  }
}

export default experimentsSaga;
