// @flow

import './PasswordForm.scss';

import * as React from 'react';

import CSRFTokenField from 'components/utils/CSRFTokenField';
import EventMessage from 'components/Shared/EventMessage/EventMessage';

import Input from '../Input';
import PasswordInput from '../PasswordInput';
import Button from '../Button';

import type { FromType } from 'components/Authentication/Container';
import type { Trait } from 'modules/authentication';
import type { Views } from 'modules/auth-modal/types';

import infoIcon from 'assets/images/info@2x.png';

type Props = {|
  buttonValue: string,
  newUserUrl: string,
  trait: Trait,
  userEmail: string,
  password: string,
  isEmailErrorVisible: boolean | Array<string>,
  emailErrorMessage: string,
  isPasswordErrorVisible: boolean | Array<string>,
  passwordErrorMessage: string,
  onChangePassword: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onSubmitSignupForm?: (
    SyntheticEvent<HTMLFormElement>,
    from: FromType,
    checkError?: boolean
  ) => void,
  onNotYouClick?: (Views) => void
|};

const PasswordForm = ({
  buttonValue,
  newUserUrl,
  trait,
  userEmail,
  password,
  isEmailErrorVisible,
  emailErrorMessage,
  isPasswordErrorVisible,
  passwordErrorMessage,
  onChangePassword,
  onSubmitSignupForm,
  onNotYouClick: onNotYouClickHandler
}: Props) => {
  const [showSuggestionTooltip, setShowSuggestionTooltip] = React.useState(
    false
  );

  const onSubmitHandler = (event) =>
    onSubmitSignupForm && onSubmitSignupForm(event, 'modal');

  const onSuggestionTooltipClickHandler = () =>
    setShowSuggestionTooltip((oldState) => !oldState);

  return (
    <form
      className="PasswordForm"
      method="post"
      action={newUserUrl}
      onSubmit={onSubmitHandler}
    >
      <CSRFTokenField />

      <input type="hidden" name="traits[]" value={trait} />

      <div className="PasswordForm__input-wrapper">
        <Input
          placeholder="Your Email Address"
          readonly
          type="email"
          required
          name="user[email]"
          value={userEmail}
        >
          <Input.Suggestion>
            Not You?
            <div className="PasswordForm__link" onClick={onNotYouClickHandler}>
              Change email
            </div>
            <img
              className="PasswordForm__suggestion-icon"
              src={infoIcon}
              alt="Info Icon"
              onClick={onSuggestionTooltipClickHandler}
            />
            {showSuggestionTooltip && (
              <div className="PasswordForm__message-wrapper">
                <EventMessage showCarrotIcon hideCloseButton>
                  We pulled an email through the magic of cookies. If you came
                  from a forwarded email, this may not be yours. Click
                  &ldquo;change email&rdquo; to make any adjustments.
                </EventMessage>
              </div>
            )}
          </Input.Suggestion>
          {isEmailErrorVisible && (
            <Input.Error>{emailErrorMessage}</Input.Error>
          )}
        </Input>
      </div>
      <div className="PasswordForm__input-wrapper">
        <PasswordInput
          id="user_password"
          placeholder="Create Password"
          name="user[password]"
          suggestion="6 Characters Minimum"
          onInputChange={onChangePassword}
          value={password}
          error={isPasswordErrorVisible ? passwordErrorMessage : null}
        />
      </div>
      <div className="PasswordForm__button">
        <Button value={buttonValue} type="submit" />
      </div>
    </form>
  );
};

export default PasswordForm;
