// @flow

import * as React from 'react';

import JournalSignupView from './JournalSignupView';
import EmailOnlySignupView from './EmailOnlySignupView/EmailOnlySignupView';
import BigEventComingSignupView from './BigEventComingSignupView/BigEventComingSignupView';
import SitewideSaleSignupView from './SitewideSaleSignupView/SitewideSaleSignupView';
import PappyDaySignupView from './PappyDaySignupView/PappyDaySignupView';
import EmailOnlyExitIntentView from './EmailOnlyExitIntentView/EmailOnlyExitIntentView';
import PromotionSignupView from './PromotionSignupView/PromotionSignupView';
import BackInStockSignupView from './BackInStockSignupView';
import FavoritesSignupView from './FavoritesSignupView';
import PlainSignupView from './PlainSignupView';

import { events as analytics, trackAction } from 'modules/analytics';

import type { Views, AuthDomain } from 'modules/auth-modal/types';

type Props = {|
  modalDomain: AuthDomain,
  onChangeView: (Views) => void,
  onClose: (method: string) => void
|};

const SignupView = ({ modalDomain, onChangeView, onClose }: Props) => {
  React.useEffect(() => {
    if (modalDomain !== 'email-only') {
      trackAction(analytics.SOFT_GATE_STEP_VIEWED, {
        label: modalDomain,
        context_step_sequence: 'signup'
      });
    }
  }, [modalDomain]);

  switch (modalDomain) {
    case 'journal':
      return <JournalSignupView onChangeView={onChangeView} />;
    case 'big-event-coming':
      return (
        <BigEventComingSignupView
          onChangeView={onChangeView}
          onClose={onClose}
        />
      );
    case 'sitewide-sale':
      return (
        <SitewideSaleSignupView onChangeView={onChangeView} onClose={onClose} />
      );
    case 'pappy-day':
      return (
        <PappyDaySignupView onChangeView={onChangeView} onClose={onClose} />
      );
    case 'email-only':
      return (
        <EmailOnlySignupView onChangeView={onChangeView} onClose={onClose} />
      );
    case 'exit-intent':
      return <EmailOnlyExitIntentView onChangeView={onChangeView} />;
    case 'back-in-stock':
      return <BackInStockSignupView onChangeView={onChangeView} />;
    case 'favorites':
      return <FavoritesSignupView onChangeView={onChangeView} />;
    case 'promotion':
      return (
        <PromotionSignupView onChangeView={onChangeView} onClose={onClose} />
      );
    case 'plain':
      return <PlainSignupView onChangeView={onChangeView} />;
    default:
      return null;
  }
};

export default SignupView;
