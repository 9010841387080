import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchQuery } from './requests';

import { FetchQueryAction, QueryData } from './types';
import { fetchQueryFailure, fetchQuerySuccess } from './action-creators';

function* querySaga(): SagaIterator {
  yield takeEvery('QUERY/FETCH', getQuery);
}

function* getQuery(action: FetchQueryAction) {
  const queryIds = action.queryIds;

  try {
    const { data } = yield call(fetchQuery, queryIds);
    const queries: QueryData = data.data;
    yield put(fetchQuerySuccess(queries));
  } catch (error) {
    yield put(fetchQueryFailure(error));
  }
}

export default querySaga;
