// @flow

import './Input.scss';

import * as React from 'react';

import useRetrieveChildByName from 'hooks/useRetrieveChildByName';

type Type = 'text' | 'password' | 'email';

type Props = {|
  id?: string,
  children?: React.Node,
  value?: string,
  name?: string,
  type?: Type,
  placeholder?: string,
  required?: boolean,
  readonly?: boolean,
  inputRef?: { current: null | HTMLInputElement },
  onInputChange?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  autoFocus?: boolean
|};

const Input = ({
  id,
  children,
  value: outsideValue = '',
  name,
  type = 'text',
  placeholder = '',
  required = false,
  readonly = false,
  inputRef,
  onInputChange,
  autoFocus = false
}: Props) => {
  const retrieveChildByName = useRetrieveChildByName();

  const [value, setValue] = React.useState(outsideValue);

  const onChangeHandler = React.useCallback(
    (event) => {
      if (onInputChange) {
        onInputChange(event);
      }

      setValue(event.target.value);
    },
    [onInputChange]
  );

  const suggestion = React.useMemo(
    () => retrieveChildByName(children, 'Suggestion'),
    [children, retrieveChildByName]
  );

  const error = React.useMemo(() => retrieveChildByName(children, 'Error'), [
    children,
    retrieveChildByName
  ]);

  return (
    <div className="Input">
      <input
        id={id}
        ref={inputRef}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
        required={required}
        readOnly={readonly}
        autoFocus={autoFocus}
      />
      {!error && suggestion}
      {error && error}
    </div>
  );
};

const Suggestion = ({ children }) => (
  <span className="Input__suggestion fg-condensed--medium">{children}</span>
);
Suggestion.displayName = 'Suggestion';
Input.Suggestion = Suggestion;

const Error = ({ children }) => (
  <span className="Input__error fg-condensed--medium">{children}</span>
);
Error.displayName = 'Error';
Input.Error = Error;

Input.displayName = 'Input';

export default Input;
