// @flow

import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';

const usePageViewCount = (
  history: RouteComponentProps,
  callback: null | (() => void) = null
) => {
  const prevLocation = React.useRef<string>('/');
  const pageViewCount = React.useRef<number>(1);

  React.useEffect(() => {
    const unlisten = history.listen((location) => {
      if (prevLocation.current !== location.pathname) {
        callback && callback();
        pageViewCount.current += 1;
        prevLocation.current = location.pathname;
      }
    });

    return () => {
      unlisten();
    };
  }, [callback, history]);

  return pageViewCount.current;
};

export default usePageViewCount;
