import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { fetchBundles } from './requests';
import {
  getBundlesSuccess,
  getBundlesFailure,
  markActiveBundle
} from './action-creators';
import { GetBundlesActionType } from './types';

export function* getBundlesSaga(action: GetBundlesActionType): SagaIterator {
  try {
    const {
      data: { data }
    } = yield call(fetchBundles, action.productIds);

    yield put(getBundlesSuccess(data));

    const cartState = yield select((state) => state.cart);

    const firstProductId = cartState.items[0].product.id;

    if (firstProductId in data) {
      yield put(markActiveBundle(firstProductId));
    }
  } catch {
    yield put(getBundlesFailure());
  }
}

function* bundlesSaga(): SagaIterator {
  yield takeLatest('BUNDLES/GET', getBundlesSaga);
}

export default bundlesSaga;
