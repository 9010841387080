// @flow

import type { Saga } from 'redux-saga';
import { call, takeEvery, select, put } from 'redux-saga/effects';

import { fetchGiftCardVariants, createGiftCard } from './requests';

import {
  getGiftCardVariantsSuccess,
  getGiftCardVariantsFailure,
  createGiftCardSuccess,
  createGiftCardFailure
} from './action-creators';

import { open } from 'modules/cart-widget/action-creators';
import { fetchRequest } from 'modules/cart/action-creators';

function* giftCardsSaga(): Saga {
  yield takeEvery('GIFT_CARD_VARIANTS/FETCH', getGiftCardVariants);
  yield takeEvery('GIFT_CARD/CREATE', createGiftCardSaga);
  yield takeEvery('GIFT_CARD/CREATE_SUCCESS', handleCartAdd);
}

function* getGiftCardVariants() {
  try {
    const response = yield call(fetchGiftCardVariants);
    const { data } = response.data;
    yield put(getGiftCardVariantsSuccess(data));
  } catch (err) {
    const { error } = err.response.data;
    yield put(getGiftCardVariantsFailure(error));
  }
}
function* createGiftCardSaga(action) {
  try {
    yield call(createGiftCard, action.giftCardData);
    yield put(createGiftCardSuccess());
  } catch (err) {
    const { error } = err.response.data;
    yield put(createGiftCardFailure(error));
  }
}

function* handleCartAdd(): Saga<void> {
  const { order } = yield select((state) => ({
    cart: state.cart,
    order: state.session.cart
  }));

  if (order.orderNumber) {
    yield put(fetchRequest(order.orderNumber, order.token));
    yield put(open());
  }
}

export default giftCardsSaga;
