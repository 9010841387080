// @flow

import type { Saga } from 'redux-saga';
import {
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { push, replace } from 'connected-react-router';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import { apiSearchResults } from './requests';
import {
  clearSearchFilters,
  fetchMoreFilteredProductResultsSuccess,
  fetchMoreProductResultsSuccess,
  fetchSearchResultsFailure,
  fetchSearchResultsSuccess,
  filterSearchResultsSuccess
} from './action-creators';

import storageAvailable from 'utils/storageAvailable';
import extractKeyword from './utils/extractKeyword';

function* searchResultsSaga(): Saga {
  yield takeLatest('SEARCH_RESULTS/FETCH', getSearchResults);
  yield takeEvery('SEARCH_RESULTS/CLEAR_FILTERS', clearFilters);
  yield debounce(
    150,
    ['SEARCH_RESULTS/TOGGLE_FILTER', 'SEARCH_RESULTS/SET_FILTERS'],
    filterSearchResults
  );
}

function* getSearchResults(action) {
  try {
    const fetchingMore = action.fetchMore;

    const { filtersActive, filtersAreApplied } = yield select(
      (state) => state.searchResults
    );
    const facets = omitBy(filtersActive, (value) => isEmpty(value));

    const searchResults = yield call(apiSearchResults, {
      keywords: action.query,
      page: action.page,
      perPage: action.perPage,
      facets,
      type: fetchingMore && 'product'
    });

    if (!fetchingMore && storageAvailable('localStorage')) {
      const queryInfo = {
        queryID: searchResults.data.queryId,
        indexName: searchResults.data.indexName
      };
      localStorage.setItem('queryInfo', JSON.stringify(queryInfo));
    }

    if (fetchingMore) {
      if (filtersAreApplied) {
        yield put(fetchMoreFilteredProductResultsSuccess(searchResults.data));
        return;
      }

      yield put(fetchMoreProductResultsSuccess(searchResults.data));
    } else {
      yield put(fetchSearchResultsSuccess(searchResults.data));
    }
  } catch (err) {
    yield put(fetchSearchResultsFailure(err));
  }
}

function* filterSearchResults() {
  try {
    const searchParams = yield select((state) => state.router.location.search);
    const searchQuery = extractKeyword(searchParams);

    const filtersActive = yield select(
      (state) => state.searchResults.filtersActive
    );
    const facets = omitBy(filtersActive, (value) => isEmpty(value));

    // When the last filter was untoggled, we reset search results
    if (isEmpty(facets)) {
      yield put(clearSearchFilters());
      return;
    }

    const queryFilters = qs.stringify(facets, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true
    });

    const searchResults = yield call(apiSearchResults, {
      keywords: searchQuery,
      facets,
      perPage: 24,
      type: 'product'
    });

    // Ensure both facets and searchQuery are present in the URL
    const updatedSearch = `?keywords=${encodeURIComponent(
      searchQuery
    )}&${queryFilters}`;

    if (searchParams !== updatedSearch) {
      yield put(push({ search: updatedSearch }));
    }

    yield put(filterSearchResultsSuccess(searchResults.data));
  } catch (err) {
    yield put(fetchSearchResultsFailure(err));
  }
}

function* clearFilters() {
  const searchParams = yield select(
    (state) => state.router.location.search.split('&')[0]
  );
  const replaceWith = `/search${searchParams}`;

  yield put(replace(replaceWith));
}

export default searchResultsSaga;
