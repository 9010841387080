const formatPhoneNumber = (newValue: string) => {
  const currentNumbers = newValue.replace(/\D/g, '');

  if (currentNumbers.length < 4) {
    return currentNumbers;
  }

  if (currentNumbers.length < 7) {
    return `(${currentNumbers.slice(0, 3)}) ${currentNumbers.slice(3)}`;
  }

  return `(${currentNumbers.slice(0, 3)}) ${currentNumbers.slice(
    3,
    6
  )}-${currentNumbers.slice(6, 10)}`;
};

export default formatPhoneNumber;
