// @flow

import './SignupPasswordStep.scss';

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AuthenticationContainer, {
  type AuthenticationProps
} from 'components/Authentication/Container';
import PasswordForm from 'components/Shared/AuthForm/TwoStepSignup/PasswordForm';
import CTALink from 'components/Shared/AuthForm/CTALink';
import AutoLink from 'components/utils/AutoLink';

import type { Views } from 'modules/auth-modal/types';

const MAYBE_LATER = 'Maybe Later';

type Props = {|
  onChangeView: (Views) => void,
  onClose: (method: string) => void
|};

const SignupPasswordStep = ({ onChangeView, onClose }: Props) => {
  const dispatch = useDispatch();

  const userEmail = useSelector((state) => state.authModal.email);

  const onChangeViewHandler = React.useCallback(() => onChangeView('login'), [
    onChangeView
  ]);

  const onNotYouClickHandler = React.useCallback(
    () => onChangeView('update-email'),
    [onChangeView]
  );

  const onCloseHandler = React.useCallback(() => onClose(MAYBE_LATER), [
    onClose
  ]);

  const onTermsClickHandler = React.useCallback(() => dispatch(close()), [
    dispatch
  ]);

  return (
    <div className="SignupPasswordStep">
      <div className="SignupPasswordStep__wrapper tc">
        <p className="SignupPasswordStep__eyebrow fg-condensed--medium fw--600 tt--u">
          Add a password
        </p>
        <p className="SignupPasswordStep__title fg-condensed--medium fw--600 tt--u">
          Unlock free shipping
        </p>
        <p className="SignupPasswordStep__content fg-condensed--medium">
          Complete your account and enjoy free shipping on your first order
        </p>
        <div className="SignupPasswordStep__form">
          <AuthenticationContainer
            email={userEmail}
            trait="signup-from-pappy-day-soft-gate-modal"
          >
            {({
              newUserUrl,
              onSubmitSignupForm,
              onChangePassword,
              password,
              isPasswordErrorVisible,
              passwordErrorMessage,
              isEmailErrorVisible,
              emailErrorMessage,
              trait
            }: AuthenticationProps) => (
              <PasswordForm
                buttonValue="Complete Account"
                newUserUrl={newUserUrl}
                password={password}
                isPasswordErrorVisible={isPasswordErrorVisible}
                passwordErrorMessage={passwordErrorMessage}
                isEmailErrorVisible={isEmailErrorVisible}
                emailErrorMessage={emailErrorMessage}
                trait={trait}
                userEmail={userEmail}
                onSubmitSignupForm={onSubmitSignupForm}
                onChangePassword={onChangePassword}
                onNotYouClick={onNotYouClickHandler}
              />
            )}
          </AuthenticationContainer>
          <CTALink linkText={MAYBE_LATER} onClick={onCloseHandler} />
          <CTALink
            textBefore="Already a member?"
            linkText="Log in"
            onClick={onChangeViewHandler}
          />
        </div>
      </div>

      <div className="SignupPasswordStep__footer fg-condensed--medium">
        *Offer excludes international and expedited orders. By continuing you
        agree to our{' '}
        <AutoLink to="/terms" target="_blank" onClick={onTermsClickHandler}>
          terms and conditions
        </AutoLink>
      </div>
    </div>
  );
};

export default SignupPasswordStep;
