// @flow

import { DateTime } from 'luxon';

const shippingWindowActionConverter = (
  template: string,
  minDays: number,
  maxDays: number,
  orderDate: number
) => {
  const minString = DateTime.fromSeconds(orderDate)
    .plus({ days: minDays })
    .toFormat('MMM dd');
  const maxString = DateTime.fromSeconds(orderDate)
    .plus({ days: maxDays })
    .toFormat('MMM dd');

  return template.replace('$min', minString).replace('$max', maxString);
};

export default shippingWindowActionConverter;
