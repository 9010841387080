// @flow

import './PlainLoginView.scss';

import * as React from 'react';
import { useSelector } from 'react-redux';

import AuthenticationContainer, {
  type AuthenticationProps
} from 'components/Authentication/Container';
import AuthForm from 'components/Shared/AuthForm';

import { type Views } from 'modules/auth-modal/types';

const modalViewText = { title: 'Welcome Back', button: 'Log In' };

type Props = {|
  onChangeView: (view: Views) => void
|};

const PlainLoginView = ({ onChangeView }: Props) => {
  const authModalEmail: string | null = useSelector(
    (state) => state.authModal.email
  );

  return (
    <div className="PlainLoginView tc">
      <h3 className="PlainLoginView__title fg-condensed--medium fw--600">
        {modalViewText.title}
      </h3>
      <div className="PlainLoginView__auth--wrapper">
        <AuthenticationContainer
          trait="signup-from-account-link-modal"
          email={authModalEmail || ''}
        >
          {(authenticationProps: AuthenticationProps) => (
            <AuthForm
              view="login"
              onChangeView={onChangeView}
              submitText={modalViewText.button}
              {...authenticationProps}
            />
          )}
        </AuthenticationContainer>
      </div>
      <div className="PlainLoginView__footer fg-condensed--body">
        Not a Member?{' '}
        <p
          onClick={() => onChangeView('signup')}
          className="PlainLoginView__footer--cta link--nudge"
        >
          Sign Up
        </p>
      </div>
    </div>
  );
};

export default PlainLoginView;
