import { SMSModalState, SMSModalActions } from 'modules/sms-modal';

const defaultState = {
  modalOpen: false,
  trigger: ''
};

const reducer = (
  state: SMSModalState = defaultState,
  action: SMSModalActions
) => {
  switch (action.type) {
    case 'SMS_MODAL/OPEN':
      return {
        ...state,
        modalOpen: true,
        trigger: action.trigger
      };
    case 'SMS_MODAL/CLOSE':
      return {
        ...state,
        modalOpen: false
      };
    default:
      return state;
  }
};

export default reducer;
