// @flow

import types from './action-types';

import type { Views, AuthDomain } from './types.js';

export const open = (view: Views, authDomain?: AuthDomain) => ({
  type: types.AUTH_MODAL_OPEN,
  view,
  authDomain
});

export const openSuccess = (
  view: Views,
  authDomain: AuthDomain,
  force: boolean
) => ({
  type: types.AUTH_MODAL_OPEN_SUCCESS,
  view,
  authDomain,
  force
});

export const close = () => ({
  type: types.AUTH_MODAL_CLOSE
});

export const changeView = (view: Views) => ({
  type: types.AUTH_MODAL_CHANGE_VIEW,
  view
});

export const clearEmail = () => ({
  type: types.AUTH_MODAL_CLEAR_EMAIL
});

export const newsletterOptin = (email: string | null) => ({
  type: types.AUTH_MODAL_NEWSLETTER_OPTIN,
  email
});
