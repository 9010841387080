// @flow

import isEmpty from 'lodash/isEmpty';

import type { ProductTile } from 'types/products';

import { events as analyticsEvents, trackAction } from 'modules/analytics';

const transformFilters = (filters) =>
  Object.entries(filters).map(([key, value]) => ({ type: key, value }));

export default function recordImpressions(
  tiles: Array<ProductTile>,
  page: number,
  indexName: string,
  queryID: string,
  listName: ?string,
  listCategory: ?string,
  filters?: { [string]: string }
): void {
  if (typeof window === 'undefined') {
    return;
  }

  // New PLPs use 0 to represent the first page, but we historically used 1. This
  // transforms the page for reporting to keep external analytics consistent.
  const reportingPage = page + 1;
  const productTiles: Array<ProductTile> = tiles.reduce(
    (productTiles: Array<ProductTile>, tile: ProductTile) => {
      if (tile.type === 'product') {
        productTiles.push(tile);
      }

      return productTiles;
    },
    []
  );

  const impressionProperties = {
    value: 0.0,
    list_id: listName,
    category: listCategory,
    products: productTiles.map((tile, index) => {
      return {
        product_id: tile.sku,
        sku: tile.sku,
        category: tile.category,
        name: tile.name,
        brand: tile.brandTitle,
        price: parseFloat(tile.price.replace('$', '')),
        position: (index + 1) * reportingPage,
        url: tile.url,
        image_url: tile.imageUrl,
        objectID: tile.sku,
        currency: 'USD'
      };
    })
  };

  if (!isEmpty(filters)) {
    trackAction(analyticsEvents.PRODUCT_LIST_FILTERED, {
      ...impressionProperties,
      filters: transformFilters(filters)
    });
  } else {
    trackAction(analyticsEvents.PRODUCT_LIST_VIEWED, impressionProperties);
  }
}
