import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './action-creators';
import * as requests from './requests';

function* addressFieldsSaga() {
  yield takeEvery('ADDRESS_FIELDS/REQUEST_STATES', fetchStatesSaga);
}

function* fetchStatesSaga(action) {
  const { countryId } = action;

  const response = yield call(requests.fetchStates, countryId);
  yield put(actions.receiveStates(countryId, response.data));
}

export default addressFieldsSaga;
