// @flow

import type {
  ShippingTouchpointsActionType,
  ShippingTouchpointsStateType
} from './types';

const initialState: ShippingTouchpointsStateType = {
  id: '',
  addToCartLeftText: '',
  addToCartLeftTextOverride: false,
  shippingMethodText: '',
  shippingMethodTextShown: false,
  loading: false,
  loaded: false
};

const reducer = (
  state: ShippingTouchpointsStateType = initialState,
  action: ShippingTouchpointsActionType
) => {
  Object.freeze(state);
  switch (action.type) {
    case 'SHIPPING_TOUCHPOINTS/FETCH':
      return { ...state, loading: true };
    case 'SHIPPING_TOUCHPOINTS/FETCH_SUCCESS':
      return {
        ...state,
        ...action.data,
        loading: false,
        loaded: true
      };
    case 'SHIPPING_TOUCHPOINTS/FETCH_FAILURE':
      return { ...state, loading: false, loaded: false, error: action.err };
    default:
      return state;
  }
};

export default reducer;
