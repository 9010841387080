import {
  SearchAutocompleteState,
  SearchLink,
  DropdownLinks
} from 'modules/search/types';

export const updateSearchResults = (
  results: SearchAutocompleteState[],
  query: string
) => ({
  type: 'SEARCH/UPDATE_RESULTS',
  results,
  query
});

export const clearSearchResults = () => ({
  type: 'SEARCH/CLEAR_RESULTS'
});

export const loadSearchResults = (query: string) => ({
  type: 'SEARCH/LOAD_RESULTS',
  query
});

export const fetchSearchHistory = () => ({
  type: 'SEARCH/FETCH_HISTORY'
});

export const fetchSearchHistorySuccess = (history: SearchLink[]) => ({
  type: 'SEARCH/FETCH_HISTORY_SUCCESS',
  history
});

export const fetchSearchHistoryFailure = () => ({
  type: 'SEARCH/FETCH_HISTORY_FAILURE'
});

export const updateSearchHistorySuccess = (searchHistory: SearchLink[]) => ({
  type: 'SEARCH/UPDATE_SEARCH_HISTORY_SUCCESS',
  searchHistory
});

export const fetchDropdownLinks = () => ({
  type: 'SEARCH/FETCH_DROPDOWN_LINKS'
});

export const fetchDropdownLinksSuccess = (links: DropdownLinks) => ({
  type: 'SEARCH/FETCH_DROPDOWN_LINKS_SUCCESS',
  links
});

export const fetchDropdownLinksFailure = () => ({
  type: 'SEARCH/FETCH_DROPDOWN_LINKS_FAILURE'
});
