// @flow

import './ForgotPasswordForm.scss';

import * as React from 'react';
import classnames from 'classnames';

import CSRFTokenField from 'components/utils/CSRFTokenField';
import { events as analytics, trackAction } from 'modules/analytics';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView?: (view: Views) => void,
  textColor?: 'light' | 'dark'
|};

const ForgotPasswordForm = ({ onChangeView, textColor = 'light' }: Props) => {
  React.useEffect(() => {
    trackAction(analytics.SOFT_GATE_STEP_VIEWED, {
      context_step_sequence: 4,
      context_step_sequence_total: 4,
      context_step_title: 'Forgot Your Password?',
      context_step_button_name: 'Reset Password'
    });
  }, []);

  return (
    <div
      className={classnames('ForgotPasswordForm fg-condensed--body tc', {
        'color--white': textColor === 'light',
        'color--blue': textColor === 'dark'
      })}
    >
      <form
        className="ForgotPasswordForm__form"
        action="/password/recover"
        method="post"
      >
        <CSRFTokenField />
        <h3 className="ForgotPasswordForm__title">Forgot Your Password?</h3>
        <p className="ForgotPasswordForm__message">
          It happens to the best of us. Enter your email in the box below and
          we&apos;ll send you a link to make a new password.
        </p>
        <input
          type="email"
          name="user[email]"
          id="user_email"
          className="ForgotPasswordForm__input fg-condensed--body"
          placeholder="Enter Email Address"
          autoFocus
        />
        <button type="submit" className="uppercase ForgotPasswordForm__button">
          Reset Password
        </button>
        <p
          className="ForgotPasswordForm__link link--nudge"
          onClick={() => onChangeView && onChangeView('login')}
        >
          Cancel
        </p>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
