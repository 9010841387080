// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export type { UserFavoritesState, UserFavoritesActions } from './types';

const {
  fetchUserFavorites,
  fetchMoreFavoriteProducts,
  fetchFavoritesHash,
  addToUserFavorites,
  removeFromUserFavorites
} = actionCreators;

export {
  fetchUserFavorites,
  fetchMoreFavoriteProducts,
  fetchFavoritesHash,
  addToUserFavorites,
  removeFromUserFavorites
};

export default reducer;
