import { call, takeEvery, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { setNotification, getNotifications } from './requests';
import {
  fetchNotificationsSuccess,
  fetchNotificationsFailure
} from './action-creators';
import { NotificationsUpdateType } from './types';

function* notificationsSaga(): SagaIterator {
  yield takeEvery('NOTIFICATIONS/FETCH', getNotificationsSaga);
  yield takeEvery('NOTIFICATIONS/UPDATE', setNotificationsSaga);
}

function* getNotificationsSaga(): SagaIterator {
  try {
    const response = yield call(getNotifications);
    const { data } = response.data;
    yield put(fetchNotificationsSuccess(data));
  } catch {
    yield put(fetchNotificationsFailure());
  }
}

function* setNotificationsSaga(action: NotificationsUpdateType): SagaIterator {
  try {
    const { name, viewed } = action;
    const response = yield call(setNotification, name, viewed);
    const { data } = response.data;
    yield put(fetchNotificationsSuccess(data));
  } catch {
    yield put(fetchNotificationsFailure());
  }
}

export default notificationsSaga;
