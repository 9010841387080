// @flow

const ADD_ITEM_FAILURE = 'CART/ADD_ITEM_FAILURE';
const ADD_ITEM_REQUEST = 'CART/ADD_ITEM_REQUEST';
const ADD_ITEM_SUCCESS = 'CART/ADD_ITEM_SUCCESS';
const DELETE_ITEM_FAILURE = 'CART/DELETE_ITEM_FAILURE';
const DELETE_ITEM_REQUEST = 'CART/DELETE_ITEM_REQUEST';
const DELETE_ITEM_SUCCESS = 'CART/DELETE_ITEM_SUCCESS';
const FETCH_FAILURE = 'CART/FETCH_FAILURE';
const FETCH_REQUEST = 'CART/FETCH_REQUEST';
const FETCH_SUCCESS = 'CART/FETCH_SUCCESS';
const FETCH_CURRENT = 'CART/FETCH_CURRENT';
const UPDATE_ITEM_FAILURE = 'CART/UPDATE_ITEM_FAILURE';
const UPDATE_ITEM_REQUEST = 'CART/UPDATE_ITEM_REQUEST';
const UPDATE_ITEM_SUCCESS = 'CART/UPDATE_ITEM_SUCCESS';
const APPLY_COUPON_CODE_REQUEST = 'CART/APPLY_COUPON_CODE_REQUEST';
const APPLY_COUPON_CODE_SUCCESS = 'CART/APPLY_COUPON_CODE_SUCCESS';
const APPLY_COUPON_CODE_FAILURE = 'CART/APPLY_COUPON_CODE_FAILURE';
const CLEAR_ERRORS = 'CART/CLEAR_ERRORS';

export default {
  ADD_ITEM_FAILURE,
  ADD_ITEM_REQUEST,
  ADD_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_CURRENT,
  UPDATE_ITEM_FAILURE,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  APPLY_COUPON_CODE_REQUEST,
  APPLY_COUPON_CODE_SUCCESS,
  APPLY_COUPON_CODE_FAILURE,
  CLEAR_ERRORS
};
