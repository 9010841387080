const eventBus = {
  on(event: string, listener: EventListener) {
    document.addEventListener(event, (event) => listener(event));
  },
  dispatch(event: string, data?: EventListener) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, listener: EventListener) {
    document.removeEventListener(event, listener);
  }
};

export default eventBus;
