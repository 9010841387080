// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export type {
  GiftCardVariantType,
  NewGiftCardDataType,
  GiftCardsStateType,
  GiftCardsInfo,
  GiftCardsInfoParam
} from './types';

export const { getGiftCardVariants, createGiftCard } = actionCreators;

export default reducer;
