// @flow

import type { WishlistState, WishlistActions } from './types';

const defaultState = {
  name: '',
  notes: '',
  shareUrl: '',
  shareId: null,
  sharingEnabled: true,
  loading: false,
  message: null,
  tiles: [],
  render404: false
};

const reducer = (
  state: WishlistState = defaultState,
  action: WishlistActions
): WishlistState => {
  switch (action.type) {
    case 'WISHLIST/FETCH':
      return {
        ...state,
        loading: true
      };
    case 'WISHLIST/FETCH_SUCCESS':
      return {
        ...state,
        ...action.wishlistData,
        loading: false,
        render404: !action.wishlistData.sharingEnabled
      };
    case 'WISHLIST/FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        render404: true
      };
    case 'WISHLIST/CLEAR':
      return {
        ...state,
        ...defaultState
      };
    case 'WISHLIST/UPDATE_SUCCESS':
      return {
        ...state,
        ...action.wishlistData,
        message: state.shareId ? 'Successfully Updated List' : 'List Created'
      };
    case 'WISHLIST/UPDATE_CLEANUP':
      return {
        ...state,
        message: null
      };
    default:
      return state;
  }
};

export default reducer;
