// @flow

import type { Saga } from 'redux-saga';
import { call, takeEvery, put, select } from 'redux-saga/effects';

import { fetchShippingRules } from './requests';

import {
  getShippingRulesSuccess,
  getShippingRulesFailure
} from './action-creators';

import type { FetchShippingRules } from './types';
import type { ApplicationState } from 'types';

function* shippingRulesSaga(): Saga {
  yield takeEvery('SHIPPING_RULES/FETCH', getShippingRulesSaga);
}

function* getShippingRulesSaga(action: FetchShippingRules) {
  const {
    shippableId,
    shippableType,
    ruleType,
    shippingMethodId
  } = action.payload;

  const shippableKey = [
    shippableType,
    shippableId,
    shippingMethodId || 'noship',
    ruleType
  ].join('_');

  const currentShippingRuleData = yield select(
    (state: ApplicationState) => state.shippingRules[shippableKey]
  );

  if (currentShippingRuleData) {
    return;
  }

  try {
    const response = yield call(
      fetchShippingRules,
      shippableId,
      shippableType,
      ruleType,
      shippingMethodId
    );
    yield put(getShippingRulesSuccess(response.data.data, shippableKey));
  } catch {
    yield put(getShippingRulesFailure());
  }
}
export default shippingRulesSaga;
