// @flow

import { type SearchResultsActionState } from './types';
import type { ActivePageFiltersState } from 'modules/page';

export const fetchSearchResults = (
  query: string,
  page: number,
  perPage: number,
  fetchMore?: boolean
) => ({
  type: 'SEARCH_RESULTS/FETCH',
  query,
  page,
  perPage,
  fetchMore
});

export const fetchSearchResultsSuccess = (
  results: SearchResultsActionState
) => ({
  type: 'SEARCH_RESULTS/FETCH_SUCCESS',
  results
});

export const toggleSearchFilter = (type: string, value: string) => ({
  type: 'SEARCH_RESULTS/TOGGLE_FILTER',
  payload: {
    type,
    value
  }
});

export const setSearchFilters = (filters: ActivePageFiltersState) => ({
  type: 'SEARCH_RESULTS/SET_FILTERS',
  filters
});

export const filterSearchResultsSuccess = (
  results: SearchResultsActionState
) => ({
  type: 'SEARCH_RESULTS/FILTER_SUCCESS',
  results
});

export const clearSearchFilters = () => ({
  type: 'SEARCH_RESULTS/CLEAR_FILTERS'
});

export const fetchSearchResultsFailure = (errorType: string) => ({
  type: 'SEARCH_RESULTS/FETCH_FAILURE',
  errorType
});

export const fetchMoreProductResultsSuccess = (
  results: SearchResultsActionState
) => ({
  type: 'SEARCH_RESULTS/FETCH_MORE_PRODUCTS_SUCCESS',
  results
});

export const fetchMoreFilteredProductResultsSuccess = (
  results: SearchResultsActionState
) => ({
  type: 'SEARCH_RESULTS/FETCH_MORE_FILTERED_PRODUCTS_SUCCESS',
  results
});
