import type {
  ExperimentActions,
  ExperimentsState,
  FetchVariationSuccess,
  FetchVariationFailure
} from 'modules/experiments/types';

export const DEFAULT_VARIATIONS = {
  'softgate-bg-test': {
    variation: 'truck',
    variationTypes: ['truck', 'cabin'],
    previewLabel: 'Softgate background test'
  }
};

const defaultState: ExperimentsState = {
  eppoIsLoading: false,
  eppoIsLoaded: false,
  variations: DEFAULT_VARIATIONS,
  error: null
};

const reducer = (
  state: ExperimentsState = defaultState,
  action: ExperimentActions
): ExperimentsState => {
  switch (action.type) {
    case 'EXPERIMENTS/INITIALIZE_EPPO_REQUEST': {
      return {
        ...state,
        eppoIsLoading: true
      };
    }
    case 'EXPERIMENTS/INITIALIZE_EPPO_SUCCESS': {
      return {
        ...state,
        eppoIsLoading: false,
        eppoIsLoaded: true
      };
    }
    case 'EXPERIMENTS/INITIALIZE_EPPO_FAILURE': {
      return {
        ...state,
        eppoIsLoading: false
      };
    }
    case 'EXPERIMENTS/FETCH_VARIATION_SUCCESS':
    case 'EXPERIMENTS/SET_FEATURE_PREVIEW': {
      const fetchVariationSuccessAction = action as FetchVariationSuccess;
      const {
        featureName,
        variation,
        variationTypes,
        previewLabel
      } = fetchVariationSuccessAction;
      return {
        ...state,
        variations: {
          ...state.variations,
          [featureName]: { variation, variationTypes, previewLabel }
        }
      };
    }
    case 'EXPERIMENTS/FETCH_VARIATION_FAILURE': {
      const fetchVariationFailureAction = action as FetchVariationFailure;
      return {
        ...state,
        error: fetchVariationFailureAction.error
      };
    }
    default:
      return state;
  }
};

export default reducer;
