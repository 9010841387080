import reducer from './reducer';

export {
  getLogOut,
  getLogOutSuccess,
  getLogOutFailure,
  setupSessionComplete,
  setupMessages,
  fetchSession,
  fetchSessionComplete,
  setSessionId,
  setPhoneNumber,
  addViewedFeature,
  addViewedFeatureSuccess
} from './action-creators';

export { getSession } from './requests';

export type { SessionState } from './types';

export default reducer;
