// @flow

import types from './action-types';

import type { CalloutsStateType } from './types';

const initialState: CalloutsStateType = {
  loading: false,
  message: ''
};

const reducer = (
  state: CalloutsStateType = initialState,
  action: CalloutsStateType
) => {
  switch (action.type) {
    case types.CALLOUTS_FETCH:
      return { ...state, loading: true, message: '' };
    case types.CALLOUTS_FETCH_SUCCESS: {
      const calloutEntries = Object.entries(action.data);
      const capitalizedCalloutEntries = calloutEntries.map((callout) => [
        [callout[0][0].toUpperCase() + callout[0].slice(1)],
        callout[1]
      ]);
      const capitalizedCallouts = Object.fromEntries(capitalizedCalloutEntries);

      return Object.assign(
        { ...state, loading: false, message: '' },
        capitalizedCallouts
      );
    }
    case types.CALLOUTS_FETCH_FAILURE:
      return { loading: false, message: action.message };
    case types.CALLOUTS_FETCH_CANCEL:
      return {
        ...state,
        loading: false,
        message: 'All requested callouts are currently present'
      };
    default:
      return state;
  }
};

export default reducer;
