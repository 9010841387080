declare global {
  interface Window {
    yotpoWidgetsContainer: {
      initWidgets: () => void;
    };
  }
}

export const loadYotpo = (yotpoAppKey: string | null | undefined) => {
  if (typeof yotpoAppKey !== 'string') {
    return;
  }

  const e = document.createElement('script');
  e.async = true;
  e.src = `https://cdn-widgetsrepository.yotpo.com/v1/loader/${yotpoAppKey}`;
  const t = document.getElementsByTagName('script')[0];
  t.parentNode?.insertBefore(e, t);
};
