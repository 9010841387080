// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import type { AuthDomain } from 'modules/auth-modal/types';
import useCurrentDate from 'hooks/useCurrentDate';

const ALLOWED_AUTH_DOMAIN_OVERRIDE = ['email-only', 'promotion'];

const useSoftGate = (
  force: boolean,
  authDomain: AuthDomain | null,
  experiment?: AuthDomain | null
) => {
  const path = useSelector((state) => state.router.location.pathname);
  const currentDate = useCurrentDate();

  const [modalDomain, setModalDomain] = React.useState<null | AuthDomain>(null);

  React.useEffect(() => {
    if (
      !ALLOWED_AUTH_DOMAIN_OVERRIDE.includes(experiment || authDomain) ||
      !authDomain
    ) {
      if (authDomain) {
        setModalDomain(experiment || authDomain);
      }

      return;
    }

    if (path.startsWith('/journal')) {
      setModalDomain('journal');
      return;
    }

    const pappyDayStart = DateTime.utc(2024, 9, 3, 7, 1); // Adjusted for Sept 3 12:01 AM PST
    const pappyDayEnd = DateTime.utc(2024, 9, 27, 6, 59); // Adjusted for Sept 26 11:59 PM PST

    if (currentDate >= pappyDayStart && currentDate < pappyDayEnd) {
      setModalDomain('pappy-day');
    } else {
      setModalDomain(experiment || authDomain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authDomain, experiment]);

  return modalDomain;
};

export default useSoftGate;
