import React from 'react';
import Imgix, { Source } from 'react-imgix';

import { SharedMediaProps } from './responsive-types';

const ResponsiveImage = ({
  fit,
  crop,
  alt,
  useHeight,
  mediaSource = '',
  width,
  height,
  sizes,
  mediaProps: { style, className, ...mediaProps } = {},
  isPicture = false,
  trim,
  aspectRatio,
  lazyLoad,
  trimColor,
  trimMeanDifference,
  fill,
  fillColor
}: SharedMediaProps) => {
  const defaultStyle =
    height && !useHeight ? { height: 'unset' } : Object.freeze({});

  // If the src starts with slash or no image, does not use the imgix component
  if (mediaSource.match(/^(\/|noimage)/)) {
    return (
      <img
        src={mediaSource}
        className={className}
        style={style}
        height={height}
        width={width}
        sizes={sizes}
        {...mediaProps}
      />
    );
  }

  const sharedProps = {
    disablePathEncoding: true,
    imgixParams: {
      auto: 'format, compress',
      crop: `${crop ? crop : 'top'}`,
      fit: `${fit ? fit : 'clip'}`,
      cs: 'tinysrgb',
      w: width,
      trim: trim && 'auto',
      ar: aspectRatio && aspectRatio,
      'trim-color': trimColor,
      fill: fill && fill,
      'fill-color': fillColor,
      'trim-md': trimMeanDifference
    },
    htmlAttributes: {
      ...mediaProps,
      style: {
        ...defaultStyle,
        ...style
      },
      alt,
      loading: lazyLoad ? 'lazy' : null
    },
    srcSetOptions: {
      devicePixelRatios: [1, 2],
      maxWidth: 2800
    },
    src: mediaSource,
    sizes,
    className,
    width,
    height
  };

  // convert to Source when using Picture component from imgix -- https://docs.imgix.com/tutorials/using-imgix-picture-element
  return isPicture ? <Source {...sharedProps} /> : <Imgix {...sharedProps} />;
};

export default ResponsiveImage;
