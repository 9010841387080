// @flow

import api from 'utils/huckberryApi';

export const toggleUserFavorites = (variantId: number) =>
  api.post('/api/user_favorites/toggle.json', { userFavorites: { variantId } });

export const getUserFavorites = (page: number, limit: number) =>
  api.get('/api/user_favorites.json', {
    params: {
      page_number: page,
      limit
    }
  });

export const getUserFavoritesHash = () =>
  api.get('/api/user_favorites.json', {
    params: {
      as_hash: true
    }
  });
