import { CartWidgetActionState } from 'modules/cart-widget';

const initialState = { isOpen: false };

const reducer = (state = initialState, action: CartWidgetActionState) => {
  switch (action.type) {
    case 'CART_WIDGET/CLOSE':
      return {
        isOpen: false
      };
    case 'CART_WIDGET/OPEN':
      return {
        isOpen: true
      };
    default:
      return state;
  }
};

export default reducer;
