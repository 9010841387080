import api from 'utils/huckberryApi';

export const fetchAccountDetails = () => api.get('/api/account/details');

export const updateAccountDetails = (data) =>
  api.put('/api/account/details', data);

export const fetchOrderHistory = (monthsBack) =>
  api.get(`/api/account/orders/${monthsBack}`);

export const fetchAccountPreferences = () =>
  api.get('/api/account/preferences');

export const updateEmailFrequency = (data) =>
  api.put('/api/newsletter-subscription.json', data);

export const updateShipmentNotifMethod = (data) =>
  api.put('/api/account/preferences', data);

export const fetchAccountList = () => api.get('/api/account/my-lists');

export const deleteRestockNotification = (id) =>
  api.delete(`/api/restock-notifications/${id}.json`);
