// @flow

import type { InstagramBlocksType } from './index';

export const getInstagramBlocks = (quantity: number) => ({
  type: 'ABOUT/FETCH_INSTAGRAM_BLOCKS',
  quantity
});

export const getInstagramBlocksSuccess = (data: InstagramBlocksType) => ({
  type: 'ABOUT/FETCH_INSTAGRAM_BLOCKS_SUCCESS',
  data
});

export const getInstagramBlocksFailure = (data: any) => ({
  type: 'ABOUT/FETCH_INSTAGRAM_BLOCKS_FAILURE',
  data
});
