import api from 'utils/huckberryApi';

import qs from 'qs';

import { FetchGoogleRecommendationsParams } from './types';

export const fetchCartAddons = () => api.get('/api/cart_addons');

export const fetchGoogleRecommendations = ({
  algorithm = 'recommended_pskus',
  productGroupIds
}: FetchGoogleRecommendationsParams) => {
  return api.get('/api/recommendations', {
    params: {
      algo: algorithm,
      product_group_id: productGroupIds
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'brackets' })
  });
};
