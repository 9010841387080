import * as actionCreators from './action-creators';
import reducer from './reducer';

import { RestockNotificationState } from './types';

const {
  fetchRestockNotifications,
  fetchRestockNotificationsStart,
  addRestockNotification
} = actionCreators;

export {
  fetchRestockNotifications,
  fetchRestockNotificationsStart,
  addRestockNotification,
  RestockNotificationState
};

export default reducer;
