const fetchRestockNotifications = () => ({
  type: 'RESTOCK_NOTIFICATIONS/FETCH'
});

const fetchRestockNotificationsStart = () => ({
  type: 'RESTOCK_NOTIFICATIONS/FETCH_START'
});

const fetchRestockNotificationsSuccess = (variantIds: Array<number>) => ({
  type: 'RESTOCK_NOTIFICATIONS/FETCH_SUCCESS',
  variantIds
});

const addRestockNotification = (variantId: number) => ({
  type: 'RESTOCK_NOTIFICATIONS/ADD',
  variantId
});

const addRestockNotificationSuccess = (variantId: number) => ({
  type: 'RESTOCK_NOTIFICATIONS/ADD_SUCCESS',
  variantId
});

const restockNotificationFailure = () => ({
  type: 'RESTOCK_NOTIFICATIONS/FAILURE'
});

export {
  fetchRestockNotifications,
  fetchRestockNotificationsStart,
  fetchRestockNotificationsSuccess,
  addRestockNotification,
  addRestockNotificationSuccess,
  restockNotificationFailure
};
