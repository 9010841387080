// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import Cookies from 'js-cookie';

import useQuery from 'hooks/useQuery';
import usePageViewCount from 'hooks/usePageViewCount';

import SMSImageModal from './SMSImageModal';

import { setupMessages, setPhoneNumber } from 'modules/session';
import { openSMSModal, closeSMSModal } from 'modules/sms-modal';
import { updateNotification } from 'modules/notifications';

import type { ApplicationState } from 'types';

const SMS_MODAL_TITLE =
  'Want first access to big sale events and exclusive drops that sell out quickly?';
const SMS_MODAL_BUTTON = 'Give me early access';

const SMSModalContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQuery();
  const pageViewCount = usePageViewCount(history);
  const timeoutRef = React.useRef(null);

  const { modalOpen, trigger } = useSelector((state) => state.smsModal);
  const { signedIn } = useSelector((state) => state.session);
  const savedPhoneNumber = useSelector(
    (state: ApplicationState) => state.session.signedInUser.phoneNumber
  );

  const smsModalLastViewed = useSelector(
    (state: ApplicationState) => state.notifications?.smsModal || 0
  );
  const cooldownDays = useSelector(
    (state: ApplicationState) => state.session.config.smsModalCooldownDays || 14
  );

  const smsModalCooldownExpired = React.useMemo(() => {
    // NOTE: cookie logic can be removed 14 days after deploy
    const modalCookie = Cookies.get('sms_prompt_trigger_type');

    if (modalCookie) {
      const daysTilExpiration = JSON.parse(modalCookie).expires;
      dispatch(updateNotification('sms_modal', true, daysTilExpiration));
      Cookies.remove('sms_prompt_trigger_type');

      return false;
    }

    // Has the current time passed the time that the modal was last viewed
    // plus the number of days for cooldown?
    return (
      DateTime.now() >
      DateTime.fromSeconds(smsModalLastViewed).plus({ days: cooldownDays })
    );
  }, [dispatch, smsModalLastViewed, cooldownDays]);

  React.useEffect(() => {
    // Firing SMS modal after a user has just signed up
    const welcomeType = queryParams.get('welcome');

    if (signedIn && welcomeType === 'true' && !savedPhoneNumber) {
      timeoutRef.current = setTimeout(() => {
        dispatch(openSMSModal('NEW_SIGN_UP'));
        dispatch(updateNotification('sms_modal', true));
      }, 90000);
    }
  }, [dispatch, queryParams, signedIn, savedPhoneNumber]);

  React.useEffect(() => {
    // SMS Modal Trigger
    // Fire if: cooldown has expired && 2nd page has been viewed
    if (smsModalCooldownExpired && pageViewCount === 2) {
      dispatch(openSMSModal('SECOND_VISIT'));
      dispatch(updateNotification('sms_modal', true));
    }
  }, [dispatch, pageViewCount, smsModalCooldownExpired]);

  const smsSignupSuccess = React.useCallback(
    (phoneNumber: string) => {
      dispatch(setPhoneNumber(phoneNumber));
      dispatch(closeSMSModal());
      dispatch(
        setupMessages([{ type: 'default', content: 'Thanks for signing up' }])
      );
    },
    [dispatch]
  );

  return (
    <>
      {modalOpen && (
        <SMSImageModal
          title={SMS_MODAL_TITLE}
          button={SMS_MODAL_BUTTON}
          trigger={trigger}
          handleClose={() => dispatch(closeSMSModal())}
          smsSignupSuccess={smsSignupSuccess}
        />
      )}
    </>
  );
};

export default SMSModalContainer;
