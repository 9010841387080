import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, throttle } from 'redux-saga/effects';

import {
  fetchRestockNotificationsStart,
  fetchRestockNotificationsSuccess,
  addRestockNotificationSuccess,
  restockNotificationFailure
} from './action-creators';

import { fetchRestockNotifications, addRestockNotification } from './requests';

function* restockNotificationSaga(): SagaIterator {
  yield throttle(300000, 'RESTOCK_NOTIFICATIONS/FETCH', fetchNotificationsSaga);
  yield takeEvery('RESTOCK_NOTIFICATIONS/ADD', addNotificationSaga);
}

function* fetchNotificationsSaga(): SagaIterator {
  try {
    yield put(fetchRestockNotificationsStart());

    const response = yield call(fetchRestockNotifications);

    yield put(fetchRestockNotificationsSuccess(response.data));
  } catch (error) {
    yield put(restockNotificationFailure());
  }
}

function* addNotificationSaga(action: {
  type: 'RESTOCK_NOTIFICATIONS/ADD';
  variantId: number;
}): SagaIterator {
  try {
    yield call(addRestockNotification, action.variantId);
    yield put(addRestockNotificationSuccess(action.variantId));
  } catch (error) {
    yield put(restockNotificationFailure());
  }
}

export default restockNotificationSaga;
