// @flow

import type { $AxiosXHR, AxiosXHRConfig } from 'axios';

import axios from 'axios';
import snakeReplacer from 'utils/snakeReplacer';

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token':
      typeof document !== 'undefined'
        ? document
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute('content')
        : ''
  },
  transformRequest: [
    function (data) {
      return JSON.stringify(data, snakeReplacer);
    }
  ]
});

api.interceptors.request.use(function (
  config: AxiosXHRConfig<any>
): AxiosXHRConfig<any> {
  if (typeof window.axiosActive === 'number') {
    window.axiosActive += 1;
  } else {
    window.axiosActive = 1;
  }
  return config;
});

api.interceptors.response.use(
  function (response: $AxiosXHR<any>): $AxiosXHR<any> {
    if (typeof window.axiosActive === 'number') {
      window.axiosActive -= 1;
    } else {
      window.axiosActive = 0;
    }
    return response;
  },
  (err: $AxiosXHR<any>) => {
    if (typeof window.axiosActive === 'number') {
      window.axiosActive -= 1;
    } else {
      window.axiosActive = 0;
    }
    throw err;
  }
);

export default api;
