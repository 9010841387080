// @flow

import './PlainSignupView.scss';

import * as React from 'react';

import AuthenticationContainer, {
  type AuthenticationProps
} from 'components/Authentication/Container';
import AuthForm from 'components/Shared/AuthForm';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (Views) => void
|};

const PlainSignupView = ({ onChangeView }: Props) => (
  <div className="PlainSignupView tc">
    <h2 className="PlainSignupView__title fg-condensed--medium fw--600">
      Join The 1-Million+ Huckberry Community
    </h2>
    <h4 className="PlainSignupView__subtitle fg-condensed--xxsmall">
      No spam, ever
      <br />
      Continental U.S. Only
    </h4>
    <div className="PlainSignupView__auth--wrapper">
      <AuthenticationContainer trait="signup-from-account-link-modal">
        {(authenticationProps: AuthenticationProps) => (
          <AuthForm
            view="signup"
            submitText="Send it"
            {...authenticationProps}
          />
        )}
      </AuthenticationContainer>
    </div>
    <div className="PlainSignupView__footer fg-condensed--body">
      Already a member?{' '}
      <div
        onClick={() => onChangeView('login')}
        className="PlainSignupView__footer--cta link--nudge"
      >
        Log In
      </div>
    </div>
  </div>
);

export default PlainSignupView;
