import {
  AnnouncementType,
  FetchAnnouncementsAction,
  FetchAnnouncementByIdAction,
  FetchAnnouncementsSuccessAction,
  FetchAnnouncementsErrorAction
} from './types';

const fetchAnnouncements = (): FetchAnnouncementsAction => ({
  type: 'ANNOUNCEMENTS/FETCH'
});

const fetchAnnouncementById = (id: string): FetchAnnouncementByIdAction => ({
  type: 'ANNOUNCEMENTS/FETCH_BY_ID',
  id
});

const fetchAnnouncementsSuccess = (
  announcements: AnnouncementType[]
): FetchAnnouncementsSuccessAction => ({
  type: 'ANNOUNCEMENTS/FETCH_SUCCESS',
  announcements
});

const fetchAnnouncementsError = (): FetchAnnouncementsErrorAction => ({
  type: 'ANNOUNCEMENTS/FETCH_ERROR'
});

export {
  fetchAnnouncements,
  fetchAnnouncementById,
  fetchAnnouncementsSuccess,
  fetchAnnouncementsError
};
