// @flow

export type RedirectsState = $Shape<{
  [string]: string
}> & {};

type RedirectUpdateAction = {
  type: 'REDIRECTS/UPDATE',
  data: Array<{ from: string, to: string }>
};

const defaultRedirectsState = {};

const reducer = (
  state: RedirectsState = defaultRedirectsState,
  action: RedirectUpdateAction
) => {
  switch (action.type) {
    case 'REDIRECTS/UPDATE': {
      const redirects = action.data.reduce(function (map, data) {
        map[data.from] = data.to;
        return map;
      }, {});

      return {
        ...state,
        ...redirects
      };
    }
    default:
      return state;
  }
};

export default reducer;
