// @flow

import type { Saga } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';

import { fetchInstagramBlocks } from './requests';
import {
  getInstagramBlocksSuccess,
  getInstagramBlocksFailure
} from './action-creators';

function* aboutSaga(): Saga {
  yield takeEvery('ABOUT/FETCH_INSTAGRAM_BLOCKS', getInstagramBlocksSaga);
}

function* getInstagramBlocksSaga(quantity: number) {
  try {
    const response = yield call(fetchInstagramBlocks, quantity);
    const { data } = response.data;
    yield put(getInstagramBlocksSuccess(data));
  } catch (err) {
    const { error } = err.response.data;
    yield put(getInstagramBlocksFailure(error));
  }
}

export default aboutSaga;
