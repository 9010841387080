import api from 'utils/huckberryApi';

import { ViewedFeatures } from './types';

export const getSession = () => api.get('/api/session.json');

export const logOut = () => api.get('/api/logout.json');

export const addViewedFeature = (viewedFeature: ViewedFeatures) =>
  api.post('/api/user_viewed_features', { viewed_feature: viewedFeature });
