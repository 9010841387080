// @flow

import type {
  FavoritedProduct,
  UserFavoriteProduct,
  UserFavoriteMeta
} from './types';

const fetchUserFavorites = ({
  page,
  limit
}: {
  page: number,
  limit?: number
}) => ({
  type: 'USER_FAVORITES/FETCH',
  page,
  limit
});

const fetchMoreFavoriteProducts = ({
  page,
  limit
}: {
  page: number,
  limit: number
}) => ({
  type: 'USER_FAVORITES/FETCH_MORE_PRODUCTS',
  page,
  limit
});

const fetchUserFavoritesSuccess = (
  favorites: Array<UserFavoriteProduct>,
  meta: UserFavoriteMeta
) => ({
  type: 'USER_FAVORITES/FETCH_SUCCESS',
  payload: {
    favorites,
    meta
  }
});

const fetchFavoritesHash = () => ({
  type: 'USER_FAVORITES/FETCH_HASH'
});

const fetchFavoritesHashSuccess = (favoritesHash: Array<string>) => ({
  type: 'USER_FAVORITES/FETCH_HASH_SUCCESS',
  payload: {
    favoritesHash
  }
});

const fetchUserFavoritesFailure = (error: boolean) => ({
  type: 'USER_FAVORITES/FETCH_FAILURE',
  error
});

const addToUserFavorites = (product: FavoritedProduct) => ({
  type: 'USER_FAVORITES/ADD',
  payload: product
});

const addToUserFavoritesSuccess = (product: UserFavoriteProduct) => ({
  type: 'USER_FAVORITES/ADD_SUCCESS',
  payload: product
});

const addToUserFavoritesFailure = (err: any) => ({
  type: 'USER_FAVORITES/ADD_FAILURE',
  err
});

const removeFromUserFavorites = (product: FavoritedProduct) => ({
  type: 'USER_FAVORITES/REMOVE',
  payload: product
});

const removeFromUserFavoritesSuccess = (product: UserFavoriteProduct) => ({
  type: 'USER_FAVORITES/REMOVE_SUCCESS',
  payload: product
});

const removeFromUserFavoritesFailure = (err: any) => ({
  type: 'USER_FAVORITES/REMOVE_FAILURE',
  err
});

export {
  fetchUserFavorites,
  fetchUserFavoritesSuccess,
  fetchMoreFavoriteProducts,
  fetchFavoritesHash,
  fetchFavoritesHashSuccess,
  fetchUserFavoritesFailure,
  addToUserFavorites,
  addToUserFavoritesSuccess,
  addToUserFavoritesFailure,
  removeFromUserFavorites,
  removeFromUserFavoritesSuccess,
  removeFromUserFavoritesFailure
};
