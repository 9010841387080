import React from 'react';

import { SharedMediaProps } from './responsive-types';

const ResponsiveVideo = ({
  useHeight,
  mediaSource = '',
  width,
  height,
  sizes,
  mediaProps: { style, className } = {}
}: SharedMediaProps) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const defaultStyle =
    height && !useHeight ? { height: 'unset' } : Object.freeze({});

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <video
      ref={videoRef}
      loop
      muted
      width={width}
      height={height}
      className={className}
      style={{
        ...defaultStyle,
        ...style
      }}
    >
      <source src={`${mediaSource}?fm=mp4`} type="video/mp4" sizes={sizes} />
      <source src={`${mediaSource}?fm=webp`} type="video/webp" sizes={sizes} />
    </video>
  );
};

export default ResponsiveVideo;
