// @flow

import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';

import {
  addToUserFavoritesSuccess,
  addToUserFavoritesFailure,
  fetchUserFavoritesSuccess,
  fetchFavoritesHashSuccess,
  fetchUserFavoritesFailure,
  removeFromUserFavoritesSuccess,
  removeFromUserFavoritesFailure
} from './action-creators';

import {
  getUserFavorites,
  getUserFavoritesHash,
  toggleUserFavorites
} from './requests';

import { addViewedFeature } from 'modules/session/action-creators';

import { events as analytics, trackAction } from 'modules/analytics';

const convertPriceToNumber = (price: string) => {
  return parseFloat(price.replace(/[$,]/g, ''));
};

function* userFavoritesSaga(): Generator<any, any, any> {
  yield takeLatest('USER_FAVORITES/FETCH', fetchUserFavoritesSaga);
  yield takeLatest(
    'USER_FAVORITES/FETCH_MORE_PRODUCTS',
    fetchUserFavoritesSaga
  );
  yield takeEvery('USER_FAVORITES/FETCH_HASH', fetchFavoritesHashSaga);
  yield takeEvery('USER_FAVORITES/ADD', addToUserFavoritesSaga);
  yield takeEvery('USER_FAVORITES/REMOVE', removeFromUserFavoritesSaga);
}

function* addToUserFavoritesSaga(action) {
  const {
    masterVariantId,
    brand,
    imageUrl,
    name,
    position,
    price,
    sku,
    url,
    category
  } = action.payload;

  try {
    const response = yield call(toggleUserFavorites, masterVariantId);
    const { data } = response.data;

    yield put(addToUserFavoritesSuccess(data));

    const eventPayload = {
      product_id: data.id,
      name,
      brand,
      imageUrl,
      url,
      sku,
      price: convertPriceToNumber(price),
      position,
      quantity: 1,
      category,
      currency: 'USD'
    };

    trackAction(analytics.PRODUCT_ADDED_TO_WISHLIST, eventPayload);

    const userFavorites = yield select(
      (state) => state.userFavorites.favorites
    );
    const tenFavoriteProducts = userFavorites.slice(0, 10);

    trackAction(analytics.WISHLIST_UPDATED, {
      ...eventPayload,
      items: tenFavoriteProducts
    });

    const viewedFeatures = yield select(
      (state) => state.session.signedInUser.viewedFeatures
    );

    if (!viewedFeatures.includes('favorite-added')) {
      yield put(addViewedFeature('favorite-added'));
    }
  } catch (err) {
    yield put(addToUserFavoritesFailure(err));
  }
}

function* removeFromUserFavoritesSaga(action) {
  const {
    masterVariantId,
    brand,
    imageUrl,
    name,
    position,
    price,
    sku,
    url,
    category
  } = action.payload;

  try {
    const response = yield call(toggleUserFavorites, masterVariantId);
    const { data } = response.data;

    yield put(removeFromUserFavoritesSuccess(data));

    trackAction(analytics.PRODUCT_REMOVED_FROM_WISHLIST, {
      product_id: data.id,
      name,
      brand,
      imageUrl,
      url,
      sku,
      price: convertPriceToNumber(price),
      position,
      quantity: 1,
      category
    });
  } catch (err) {
    const { error } = err.response.data;
    yield put(removeFromUserFavoritesFailure(error));
  }
}

function* fetchUserFavoritesSaga(action) {
  try {
    const response = yield call(getUserFavorites, action.page, action.limit);
    const { favorites, meta } = response.data.data;
    yield put(fetchUserFavoritesSuccess(favorites, meta));
  } catch (error) {
    yield put(fetchUserFavoritesFailure(true));
  }
}

function* fetchFavoritesHashSaga() {
  const { favoritesHash, fetchingHash } = yield select(
    (state) => state.userFavorites
  );
  if (!favoritesHash && fetchingHash) {
    try {
      const response = yield call(getUserFavoritesHash);
      yield put(fetchFavoritesHashSuccess(response.data.data));
    } catch (error) {
      yield put(fetchUserFavoritesFailure(true));
    }
  }
}

export default userFavoritesSaga;
