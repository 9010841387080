// @flow
import './PromotionSignupView.scss';
import './SignupEmailStep.scss';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import AuthenticationContainer, {
  type AuthenticationProps
} from 'components/Authentication/Container';
import EmailForm from 'components/Shared/AuthForm/TwoStepSignup/EmailForm';
import CTALink from 'components/Shared/AuthForm/CTALink';
import AutoLink from 'components/utils/AutoLink';

import { close } from 'modules/auth-modal';
import type { Views } from 'modules/auth-modal/types';

//  Eppo AB setup start
import { ExperimentsState } from 'types/rootState';
//  Eppo AB setup end

type Props = {|
  onChangeView: (Views) => void
|};

const SignupEmailStep = ({ onChangeView }: Props) => {
  const dispatch = useDispatch();

  const onChangeViewHandler = React.useCallback(() => onChangeView('login'), [
    onChangeView
  ]);

  const onTermsClickHandler = React.useCallback(() => dispatch(close()), [
    dispatch
  ]);

  //  Eppo AB setup start
  const variation = useSelector(
    (state: ExperimentsState) =>
      state.experiments.variations['softgate-bg-test']?.variation
  );
  //  Eppo AB setup end

  return (
    <div className="SignupEmailStep">
      <div
        className={classnames('SignupEmailStep__wrapper tc', {
          'temp-padding-for-exp': variation === 'cabin'
        })}
      >
        <p className="SignupEmailStep__eyebrow fg-condensed--medium fw--600">
          signup for our newsletter and
        </p>
        <p className="SignupEmailStep__title fg-condensed--medium fw--600">
          Get{' '}
          <span className="SignupEmailStep__title--yellow">Free Shipping</span>{' '}
          Today
        </p>
        <p className="SignupEmailStep__content fg-condensed--medium">
          You&apos;ll also receive the coolest gear, inspirational stories, and
          a hell of a lot more delivered straight to your inbox. You know, just
          the good stuff.
        </p>
        <div className="SignupEmailStep__form">
          <AuthenticationContainer trait="signup-from-promotion-soft-gate-modal">
            {({
              onClickFacebookSignup,
              onClickGoogleSignup
            }: AuthenticationProps) => (
              <EmailForm
                buttonValue="Get Free Shipping"
                onClickFacebookSignup={onClickFacebookSignup}
                onClickGoogleSignup={onClickGoogleSignup}
                trigger="Promo Signup View"
              />
            )}
          </AuthenticationContainer>
          <CTALink
            textBefore="Already a member?"
            linkText="Log in"
            onClick={onChangeViewHandler}
          />
        </div>
      </div>

      <div className="SignupEmailStep__footer fg-condensed--medium">
        *Offer excludes international and expedited orders.
        <br />
        By continuing you agree to our{' '}
        <AutoLink to="/terms" target="_blank" onClick={onTermsClickHandler}>
          terms and conditions
        </AutoLink>
      </div>
    </div>
  );
};

export default SignupEmailStep;
