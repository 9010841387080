import huckberryApi from 'utils/huckberryApi';

import {
  AttentiveSuccessResponseType,
  AttentiveFailureResponseType
} from './types';

const eligibility = (
  phone: string,
  email: string,
  onSuccessHandler: (response: AttentiveSuccessResponseType) => void,
  onFailureHandler: (response: AttentiveFailureResponseType) => void
) => {
  huckberryApi
    .get(`/api/attentive/eligibility?phone=${phone || ''}&email=${email || ''}`)
    .then(({ data }) => {
      onSuccessHandler(data);
    })
    .catch(({ response: { data } }) => {
      onFailureHandler(data);
    });
};

export default eligibility;
