// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';
import mailcheck from 'mailcheck';

import oauthPostSubmitForm from 'utils/oauthPostSubmitForm';
import { validateEmail, validatePassword } from 'utils/formValidator';

import { events as analytics, trackAction } from 'modules/analytics';
import type { UserAuthenticationState, Trait } from 'modules/authentication';

const DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'comcast.net',
  'me.com',
  'msn.com',
  'live.com',
  'mac.com',
  'sbcglobal.net',
  'outlook.com',
  'icloud.com',
  'att.net',
  'verizon.net',
  'ymail.com',
  'cox.net',
  'bellsouth.net',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'mail.com',
  'mail.ru',
  'yahoo.ca',
  'hotmail.fr'
];

export type AuthContainerState = {|
  email: string,
  password: string,
  isEmailErrorVisible: boolean | Array<string>,
  isPasswordErrorVisible: boolean | Array<string>,
  isPasswordResetErrorVisible: boolean | Array<string>,
  suggestedEmail: string
|};

export type FromType = 'modal' | 'full_page' | 'checkout';

type Props = {|
  children: (AuthenticationProps) => React.Node,
  trait: Trait, // This prop should be required!
  email?: string
|};

export type AuthenticationProps = {|
  ...AuthContainerState,
  signupCta: string,
  loginCta: string,
  forgotPasswordCta: string,
  forgotPasswordDescription: string,
  forgotPasswordSuccess: ?string,
  socialFooterCta: string,
  changePasswordCta: string,
  emailTooltipMessage: string,
  user: UserAuthenticationState,

  imageDesktop: ?{| title: string, description: string, image: string |},
  imageMobile: ?{| title: string, description: string, image: string |},
  avatarUrls: Array<string>,

  modalImageUrl: string,

  termsUrl: string,
  privacyPolicyUrl: string,

  newUserUrl: string,
  loginUrl: string,
  forgotPasswordUrl: string,
  newPasswordUrl: string,
  responseErrors: {|
    email: boolean | Array<string>,
    password: boolean | Array<string>,
    resetPasswordToken: boolean | Array<string>
  |},

  emailErrorMessage: string,
  passwordErrorMessage: string,
  resetPasswordToken: string,
  user: UserAuthenticationState,
  trait: Trait,

  // Actions
  onChangeEmail: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onChangePassword: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onClickFacebookSignup: (from: FromType, newAuth?: boolean) => void,
  onClickGoogleSignup: (from: FromType, newAuth?: boolean) => void,
  onSubmitSignupForm: (
    SyntheticEvent<HTMLFormElement>,
    from: FromType,
    checkError?: boolean
  ) => void,
  onSubmitLoginForm: (SyntheticEvent<HTMLFormElement>, from: FromType) => void,
  onSubmitForgotPasswordForm: (SyntheticEvent<HTMLFormElement>) => void,
  onSubmitChangePasswordForm: (SyntheticEvent<HTMLFormElement>) => void,

  // Container props that are not in FullPageAuthProps
  context: 'login' | 'signup',
  resetErrors: () => void
|};

const AuthenticationContainer = ({
  children,
  trait,
  email: outsideEmail
}: Props) => {
  const location = useSelector((state) => state.router.location);
  const authenticationState = useSelector((state) => state.authentication);
  const authenticationInterface = useSelector(
    (state) => state.authenticationInterface
  );
  const authentication = { ...authenticationState, ...authenticationInterface };
  const {
    signupCta,
    loginCta,
    forgotPasswordCta,
    forgotPasswordDescription,
    forgotPasswordSuccess,
    socialFooterCta,
    changePasswordCta,
    emailTooltipMessage,
    imageDesktop,
    imageMobile,
    modalImageUrl,
    avatarUrls,
    links,
    user
  } = authentication;

  const [email, setEmail] = React.useState(
    outsideEmail || user.email || location.query.kemail || ''
  );
  const [password, setPassword] = React.useState('');
  const [isEmailErrorVisible, setEmailErrorVisible] = React.useState(
    user.errors.email
  );
  const [isPasswordErrorVisible, setPasswordErrorVisible] = React.useState(
    user.errors.password
  );
  const [
    isPasswordResetErrorVisible,
    setPasswordResetErrorVisible
  ] = React.useState(user.errors.resetPasswordToken);
  const [suggestedEmail, setSuggestedEmail] = React.useState('');

  const prevPathRef = React.useRef();

  React.useEffect(() => {
    prevPathRef.current = location.pathname;
  });

  React.useEffect(() => {
    prevPathRef.current !== location.pathname && resetErrors();
  }, [location.pathname]);

  const resetErrors = () => {
    setEmailErrorVisible(false);
    setPasswordErrorVisible(false);
    setPasswordResetErrorVisible(false);
  };

  const getEmailErrorMessage = () => {
    if (suggestedEmail.length > 0) {
      return `Did you mean ${suggestedEmail}?`;
    }
    return 'Please enter a valid email address';
  };

  const getPasswordErrorMessage = () => {
    if (
      /\/login/.test(location.pathname) ||
      authentication.user.context === 'login'
    ) {
      return 'Invalid email or password';
    }

    return 'Password must be at least 6 characters';
  };

  const onClickFacebookSignup = (from: FromType, newAuth?: boolean = true) => {
    const trackingEvent = newAuth
      ? analytics.USER_REGISTRATION_SUBMITTED
      : analytics.USER_LOG_IN_SUBMITTED;

    trackAction(trackingEvent, {
      trigger: from || '',
      authorization_type: 'facebook',
      label: trait
    });

    oauthPostSubmitForm(links.facebookUrl, trait);
  };

  const onClickGoogleSignup = (from: FromType, newAuth?: boolean = true) => {
    const trackingEvent = newAuth
      ? analytics.USER_REGISTRATION_SUBMITTED
      : analytics.USER_LOG_IN_SUBMITTED;

    trackAction(trackingEvent, {
      trigger: from || '',
      authorization_type: 'google',
      label: trait
    });

    oauthPostSubmitForm(links.googleUrl, trait);
  };

  const errorCheck = (event: SyntheticEvent<HTMLFormElement>) => {
    const isEmailErrorVisible = !validateEmail(email);
    const isPasswordErrorVisible = !validatePassword(password);
    if (isEmailErrorVisible || isPasswordErrorVisible) {
      event.preventDefault();
      setEmailErrorVisible(isEmailErrorVisible);
      setPasswordErrorVisible(isPasswordErrorVisible);
    }
  };

  const onSubmitSignupForm = (
    event: SyntheticEvent<HTMLFormElement>,
    from: FromType,
    checkError?: boolean = true
  ) => {
    checkError && errorCheck(event);

    if (!isEmailErrorVisible || !isPasswordErrorVisible) {
      trackAction(analytics.USER_REGISTRATION_SUBMITTED, {
        trigger: from || '',
        authorization_type: 'email',
        label: trait
      });
    }
  };

  const onSubmitLoginForm = (
    event: SyntheticEvent<HTMLFormElement>,
    from: FromType
  ) => {
    trackAction(analytics.USER_LOG_IN_SUBMITTED, {
      trigger: from || '',
      authorization_type: 'email',
      label: trait
    });
  };

  const onSubmitForgotPasswordForm = (
    event: SyntheticEvent<HTMLFormElement>
  ) => {
    if (!email) {
      setSuggestedEmail('Please provide an email address.');
    }
    const emailError = !validateEmail(email);
    if (emailError) {
      event.preventDefault();
      setEmailErrorVisible(emailError);
    }
  };

  const onSubmitChangePasswordForm = (
    event: SyntheticEvent<HTMLFormElement>
  ) => {
    if (!validatePassword(password)) {
      event.preventDefault();
      setPasswordErrorVisible(isPasswordErrorVisible);
    }
  };

  const onChangeEmail = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const email = event.currentTarget.value;
    const domain = email.split('@')[1];

    if (email && suggestedEmail === 'Please provide an email address.') {
      setSuggestedEmail('');
    }

    if (domain?.indexOf('.') > 0) {
      mailcheck.run({
        email,
        domains: DOMAINS,
        suggested: (suggestion) => {
          setEmailErrorVisible(true);
          setSuggestedEmail(suggestion.full);
        },
        empty: () => {
          const emailError = isEmailErrorVisible
            ? !validateEmail(email)
            : false;
          setEmailErrorVisible(emailError);
          setSuggestedEmail('');
        }
      });
    }
    setEmail(email);
  };

  const onChangePassword = (
    event: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    const enteredPassword = event.currentTarget.value;
    setPassword(enteredPassword);
    const passwordError = isPasswordErrorVisible
      ? !validatePassword(enteredPassword)
      : false;
    setPasswordErrorVisible(passwordError);
  };

  const sharedProps: AuthenticationProps = {
    //local state
    email,
    password,
    isEmailErrorVisible,
    isPasswordErrorVisible,
    isPasswordResetErrorVisible,
    suggestedEmail,

    signupCta,
    loginCta,
    forgotPasswordCta,
    forgotPasswordDescription,
    forgotPasswordSuccess,
    socialFooterCta,
    changePasswordCta,
    emailTooltipMessage,
    imageDesktop,
    imageMobile,
    modalImageUrl,
    avatarUrls,
    termsUrl: links.termsUrl,
    privacyPolicyUrl: links.privacyPolicyUrl,
    newUserUrl: links.newUserUrl,
    loginUrl: links.loginUrl,
    forgotPasswordUrl: links.forgotPasswordUrl,
    newPasswordUrl: links.newPasswordUrl,

    emailErrorMessage: getEmailErrorMessage(),
    passwordErrorMessage: getPasswordErrorMessage(),

    responseErrors: user.errors,
    user,
    trait,

    resetErrors,
    onChangeEmail,
    onChangePassword,
    onClickFacebookSignup,
    onClickGoogleSignup,
    onSubmitSignupForm,
    onSubmitLoginForm,
    onSubmitForgotPasswordForm,
    onSubmitChangePasswordForm,

    resetPasswordToken: location.query.reset_password_token,

    context: user.context
  };

  return children(sharedProps);
};

export default AuthenticationContainer;
