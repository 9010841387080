// @flow

import type { GiftCardsStateType, GiftCardsActionType } from './types';

const initialState: GiftCardsStateType = {
  giftCardVariants: [],
  loading: false,
  error: ''
};

const reducer = (
  state: GiftCardsStateType = initialState,
  action: GiftCardsActionType
) => {
  Object.freeze(state);
  switch (action.type) {
    case 'GIFT_CARD_VARIANTS/FETCH':
    case 'GIFT_CARD/CREATE':
      return { ...state, error: '', loading: true };

    case 'GIFT_CARD_VARIANTS/FETCH_SUCCESS':
      return {
        ...state,
        giftCardVariants: [...action.data],
        error: '',
        loading: false
      };
    case 'GIFT_CARD/CREATE_SUCCESS':
      return { ...state, error: '', loading: false, success: true };

    case 'GIFT_CARD_VARIANTS/FETCH_FAILURE':
    case 'GIFT_CARD/CREATE_FAILURE':
      return { ...state, error: action.err, loading: false, success: false };
    default:
      return state;
  }
};

export default reducer;
