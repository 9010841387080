import { SagaIterator } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';

import { fetchOrder, cancelOrder } from './requests';
import { getOrder, getOrderSuccess, getOrderFailure } from './action-creators';
import { FetchOrder, CancelOrder } from './types';

function* orderSaga(): SagaIterator {
  yield takeEvery('ORDER/FETCH', getOrderSaga);
  yield takeEvery('ORDER/CANCEL', cancelOrderSaga);
}

function* getOrderSaga(action: FetchOrder): SagaIterator {
  const orderNumber = action.orderNumber;
  try {
    const response = yield call(fetchOrder, orderNumber);
    const { data } = response?.data;
    yield put(getOrderSuccess(data));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    const { error } = err?.response?.data;
    yield put(getOrderFailure(error));
  }
}

function* cancelOrderSaga(action: CancelOrder): SagaIterator {
  const orderNumber = action.orderNumber;

  const response = yield call(cancelOrder, orderNumber);
  console.log(response.data.success);

  yield put(getOrder(action.orderNumber));
}

export default orderSaga;
