import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import types from './action-types';

/* ---------- Helpers ---------- */

const _updateCartSubtotalAndDiscountTotal = (newState) => {
  const { items, discountAdjustments } = newState;
  let discountTotal = 0;
  let adjustmentTotal = 0;

  items.map((item) => {
    discountTotal += item.product.discount * item.quantity;
  });

  discountAdjustments.map((adjustment) => {
    adjustmentTotal += parseFloat(adjustment.amount, 10);
  });

  return {
    ...newState,
    adjustmentTotal,
    discountTotal
  };
};

const _updateCartState = (state, newState) =>
  Object.assign({}, state, newState);

const renderSkeletonItem = (cartItems, newItem) => {
  if (!cartItems) {
    return null;
  }

  const noItemsInCart = cartItems?.length === 0;
  const variantInCart = find(
    cartItems,
    (item) => item.variant.id === newItem.variantId
  );

  if (!variantInCart || noItemsInCart) {
    return newItem;
  }

  return null;
};

/* ---------- Reducers ---------- */

const commonFailureReducer = (state, { error, id }) => {
  const itemIndex = findIndex(state.items, ['id', id]);

  return _updateCartState(state, {
    items: [
      ...state.items.slice(0, itemIndex),
      Object.assign(
        {},
        {
          ...state.items[itemIndex],
          deleteInProgress: false,
          updateInProgress: false,
          error
        }
      ),
      ...state.items.slice(itemIndex + 1)
    ]
  });
};

const addItemRequest = (state, { data }) => {
  return {
    ...state,
    skeletonItem: renderSkeletonItem(state.items, data),
    addingInProgress: true
  };
};

const addItemSuccess = (state, { data }) => {
  return {
    ...state,
    ...data,
    addingInProgress: false,
    skeletonItem: null
  };
};

const addItemFailure = (state, { error }) => {
  return Object.assign({}, state, { addingInProgress: false, error });
};

const deleteItemRequest = (state, { id }) => {
  const itemIndex = findIndex(state.items, ['id', id]);

  return _updateCartState(state, {
    error: null,
    promoCodeError: null,
    items: [
      ...state.items.slice(0, itemIndex),
      Object.assign(
        {},
        {
          ...state.items[itemIndex],
          deleteInProgress: true,
          updateInProgress: false,
          error: null
        }
      ),
      ...state.items.slice(itemIndex + 1)
    ]
  });
};

const deleteItemSuccess = (state, { id, cart }) => {
  const itemIndex = findIndex(state.items, ['id', id]);

  return _updateCartState(
    state,
    _updateCartSubtotalAndDiscountTotal({
      subtotal: parseFloat(cart.displayItemTotal.replace(/[$,]/g, ''), 10),
      adjustedTotal: parseFloat(cart.displayTotal.replace(/[$,]/g, ''), 10),
      discountAdjustments: cart.discountAdjustments,
      items: [
        ...state.items.slice(0, itemIndex),
        ...state.items.slice(itemIndex + 1)
      ]
    })
  );
};

const fetchRequest = (state) => {
  return {
    ...state,
    fetchInProgress: true
  };
};

const fetchSuccess = (state, { data }) => {
  return {
    ...state,
    ...data,
    fetchInProgress: false,
    loaded: true
  };
};

const fetchFailure = (state, { error }) => {
  return {
    ...state,
    fetchInProgress: false,
    error
  };
};

const updateItemRequest = (state, { id }) => {
  const itemIndex = findIndex(state.items, ['id', id]);

  return _updateCartState(state, {
    error: null,
    promoCodeError: null,
    items: [
      ...state.items.slice(0, itemIndex),
      Object.assign(
        {},
        {
          ...state.items[itemIndex],
          deleteInProgress: false,
          updateInProgress: true,
          error: null
        }
      ),
      ...state.items.slice(itemIndex + 1)
    ]
  });
};

const updateItemSuccess = (state, { id, quantity, cart }) => {
  const itemIndex = findIndex(state.items, ['id', id]);

  return _updateCartState(
    state,
    _updateCartSubtotalAndDiscountTotal({
      subtotal: parseFloat(cart.displayItemTotal.replace(/[$,]/g, ''), 10),
      adjustedTotal: parseFloat(cart.displayTotal.replace(/[$,]/g, ''), 10),
      discountAdjustments: cart.discountAdjustments,
      items: [
        ...state.items.slice(0, itemIndex),
        Object.assign(
          {},
          {
            ...state.items[itemIndex],
            deleteInProgress: false,
            updateInProgress: false,
            error: null,
            quantity
          }
        ),
        ...state.items.slice(itemIndex + 1)
      ]
    })
  );
};

const applyCouponCodeRequest = (state) => {
  return {
    ...state,
    fetchInProgress: true
  };
};

const applyCouponCodeSuccess = (state, { data }) => {
  return {
    ...state,
    ...data,
    fetchInProgress: false
  };
};

const applyCouponCodeFailure = (state, { error }) => {
  return {
    ...state,
    fetchInProgress: false,
    errors: [error],
    promoCodeError: error
  };
};

const clearErrors = (state) => {
  return {
    ...state,
    error: null,
    promoCodeError: null
  };
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_ITEM_REQUEST:
      return addItemRequest(state, action);
    case types.ADD_ITEM_SUCCESS:
      return addItemSuccess(state, action);
    case types.ADD_ITEM_FAILURE:
      return addItemFailure(state, action);
    case types.DELETE_ITEM_REQUEST:
      return deleteItemRequest(state, action);
    case types.DELETE_ITEM_SUCCESS:
      return deleteItemSuccess(state, action);
    case types.DELETE_ITEM_FAILURE:
      return commonFailureReducer(state, action);
    case types.FETCH_REQUEST:
    case types.FETCH_CURRENT:
      return fetchRequest(state);
    case types.FETCH_SUCCESS:
      return fetchSuccess(state, action);
    case types.FETCH_FAILURE:
      return fetchFailure(state, action);
    case types.UPDATE_ITEM_REQUEST:
      return updateItemRequest(state, action);
    case types.UPDATE_ITEM_SUCCESS:
      return updateItemSuccess(state, action);
    case types.UPDATE_ITEM_FAILURE:
      return commonFailureReducer(state, action);
    case types.APPLY_COUPON_CODE_REQUEST:
      return applyCouponCodeRequest(state, action);
    case types.APPLY_COUPON_CODE_SUCCESS:
      return applyCouponCodeSuccess(state, action);
    case types.APPLY_COUPON_CODE_FAILURE:
      return applyCouponCodeFailure(state, action);
    case types.CLEAR_ERRORS:
      return clearErrors(state);
    default:
      return state;
  }
};

export default reducer;
