import reducer from './reducer';

import * as actionCreators from './action-creators';

export const {
  fetchQuery,
  fetchQuerySuccess,
  fetchQueryFailure
} = actionCreators;

export type { QueryAction, QueryStateType } from './types';

export default reducer;
