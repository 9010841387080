// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export const getAddresses = actionCreators.getAddresses;
export const createAddress = actionCreators.createAddress;
export const updateAddress = actionCreators.updateAddress;
export const deleteAddress = actionCreators.deleteAddress;

export type { AddressBookState, Address } from './types';

export default reducer;
