// Slightly modified function from here:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API

const storageAvailable = (type: 'localStorage' | 'sessionStorage') => {
  let storage;
  try {
    storage = window[type];
    const testText = '__storage_test__';
    storage.setItem(testText, testText);
    storage.removeItem(testText);
    return true;
  } catch (error) {
    return false;
  }
};

export default storageAvailable;
