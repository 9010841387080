import {
  AnnouncementType,
  AnnouncementState,
  AnnouncementAction
} from './types';

const initialState = {
  active: []
};

const reducer = (
  state: AnnouncementState = initialState,
  action: AnnouncementAction
) => {
  switch (action.type) {
    case 'ANNOUNCEMENTS/FETCH_SUCCESS':
      return {
        ...state,
        active: action.announcements
      };
    case 'ANNOUNCEMENTS/FETCH_ERROR':
      return initialState;
    default:
      return state;
  }
};

export {
  fetchAnnouncements,
  fetchAnnouncementById,
  fetchAnnouncementsSuccess
} from './action-creators';

export type { AnnouncementType, AnnouncementState };

export default reducer;
