import { QueryAction, QueryStateType } from './types';

const initialQueryState: QueryStateType = {
  tiles: {},
  fetchFailure: false,
  queriesLoaded: false
};
const reducer = (state = initialQueryState, action: QueryAction) => {
  switch (action.type) {
    case 'QUERY/FETCH':
      return {
        ...state,
        queriesLoaded: false
      };
    case 'QUERY/FETCH_SUCCESS':
      return {
        ...state,
        tiles: {
          ...state.tiles,
          ...action.queries
        },
        queriesLoaded: true
      };
    case 'QUERY/FETCH_FAILURE':
      return {
        ...state,
        fetchFailure: true,
        queriesLoaded: false
      };
    default:
      return state;
  }
};

export default reducer;
