// @flow

export type NavigationItemRole =
  | 'VISIBLE_ROLE_VISITOR'
  | 'VISIBLE_ROLE_USER'
  | 'VISIBLE_ROLE_ALL';

export type NavigationFooterLinksProps = {|
  columns: Array<{|
    title: string,
    url: ?string,
    links: Array<{|
      title: string,
      url: string,
      role?: NavigationItemRole
    |}>
  |}>
|};

type Footer = {|
  footerLinks: NavigationFooterLinksProps
|};

const defaultFooterState = {
  footerLinks: {
    columns: []
  }
};

const reducer = (state: Footer = defaultFooterState) => {
  return state;
};

export default reducer;
