// @flow

import { call, put, select, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

import analyticsSelectors from './selectors';
import recordImpressions from './recordImpressions';
import { trackPageView } from './index';
import { fetchAlgoliaQueryInfo } from 'modules/page/utils/analyticsHelpers';
import { fetchNotifications } from 'modules/notifications';
import { fetchFavoritesHash } from 'modules/user-favorites';
import type { RecordImpressionsAction } from './types';
import {
  type SessionState,
  getSession,
  fetchSessionComplete
} from 'modules/session';

function* analyticsSaga(): Generator<any, any, any> {
  yield takeEvery('ANALYTICS/RECORD_IMPRESSIONS', recordImpressionsSaga);
  yield takeEvery(LOCATION_CHANGE, pageLoadedSaga);
}

function* recordImpressionsSaga(action: RecordImpressionsAction) {
  const { tiles, page, filters } = action.payload;

  const state = yield select();
  const queryID = fetchAlgoliaQueryInfo('queryID');
  const indexName =
    fetchAlgoliaQueryInfo('indexName') ||
    state.session.config.algoliaTilesIndex; // defaults to what's in state

  try {
    const listName: ?string = yield select(analyticsSelectors.getListName);
    const listCategory: ?string = yield select(
      analyticsSelectors.getListCategory
    );

    recordImpressions(
      tiles,
      page,
      indexName,
      queryID,
      listName,
      listCategory,
      filters
    );
  } catch (e) {} // eslint-disable-line no-empty
}

function* pageLoadedSaga(router: RouterState) {
  if (router.payload.action !== 'REPLACE') {
    // If the session isn't loaded, we need to load it.
    // If this triggers before the session is loaded, so we need to check if it's loaded or being fetched.
    const { signedInUser, loadedAt } = yield select((state) => state.session);
    const emailIsEmpty = isEmpty(signedInUser.email);
    if (emailIsEmpty && !loadedAt) {
      try {
        const response: { data: SessionState } = yield call(getSession);
        yield put(fetchSessionComplete(true, response.data));
        if (response.data.signedIn) {
          yield put(fetchNotifications());
          yield put(fetchFavoritesHash());
          trackPageView({ email: response.data.signedInUser.email });
          return;
        }
      } catch {
        yield put(fetchSessionComplete(false));
      }
    }

    trackPageView({ email: signedInUser.email });
  }
}

export default analyticsSaga;
