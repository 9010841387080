// @flow

const CALLOUTS_FETCH = 'CALLOUTS_FETCH';
const CALLOUTS_FETCH_SUCCESS = 'CALLOUTS_FETCH_SUCCESS';
const CALLOUTS_FETCH_FAILURE = 'CALLOUTS_FETCH_FAILURE';
const CALLOUTS_FETCH_CANCEL = 'CALLOUTS_FETCH_CANCEL';

export default {
  CALLOUTS_FETCH,
  CALLOUTS_FETCH_SUCCESS,
  CALLOUTS_FETCH_FAILURE,
  CALLOUTS_FETCH_CANCEL
};
