// @flow

import type { OrderHistoryState } from 'types';

const reducer = (
  state: OrderHistoryState = { orders: [], since: '6-months' },
  action: { type: string }
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
