// @flow

import type { Saga } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';

import { fetchShippingTouchpoints } from './requests';

import {
  getShippingTouchpointsSuccess,
  getShippingTouchpointsFailure
} from './action-creators';

function* shippingTouchpointsSaga(): Saga {
  yield takeEvery('SHIPPING_TOUCHPOINTS/FETCH', getShippingTouchpointsSaga);
}

function* getShippingTouchpointsSaga() {
  try {
    const response = yield call(fetchShippingTouchpoints);
    const { data } = response.data;
    yield put(getShippingTouchpointsSuccess(data));
  } catch (err) {
    const { error } = err?.response?.data;
    yield put(getShippingTouchpointsFailure(error));
  }
}

export default shippingTouchpointsSaga;
