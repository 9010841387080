// @flow

import './SignupEmailStep.scss';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import AuthenticationContainer, {
  type AuthenticationProps
} from 'components/Authentication/Container';
import EmailForm from 'components/Shared/AuthForm/TwoStepSignup/EmailForm';
import CTALink from 'components/Shared/AuthForm/CTALink';
import AutoLink from 'components/utils/AutoLink';

import { close } from 'modules/auth-modal';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (Views) => void
|};

const SignupEmailStep = ({ onChangeView }: Props) => {
  const dispatch = useDispatch();

  const onChangeViewHandler = React.useCallback(() => onChangeView('login'), [
    onChangeView
  ]);

  const onTermsClickHandler = React.useCallback(() => dispatch(close()), [
    dispatch
  ]);

  const pappyCopy =
    "To celebrate National Bourbon Heritage Month, we're giving away Pappy Van Winkle's 20 Year Family Reserve ($3,000+ value) and $12,000 cash.";

  return (
    <div className="SignupEmailStep">
      <div className="SignupEmailStep__wrapper tc">
        <p className="SignupEmailStep__eyebrow fg-condensed--medium fw--600">
          11th Annual Pappy Giveaway
        </p>
        <p className="SignupEmailStep__title fg-condensed--medium fw--600 tt--u">
          <span className="SignupEmailStep__title--yellow">
            Win $15,000 worth of
            <br />
            Bourbon and cash
          </span>
        </p>
        <p className="SignupEmailStep__content fg-condensed--medium">
          {pappyCopy}
          <br />
          Sign up to learn more.
        </p>
        <div className="SignupEmailStep__form">
          <AuthenticationContainer trait="signup-from-pappy-day-soft-gate-modal">
            {({
              onClickFacebookSignup,
              onClickGoogleSignup
            }: AuthenticationProps) => (
              <EmailForm
                buttonValue="Take Your Shot"
                onClickFacebookSignup={onClickFacebookSignup}
                onClickGoogleSignup={onClickGoogleSignup}
                trigger="Pappy Day Signup View"
              />
            )}
          </AuthenticationContainer>
          <CTALink
            textBefore="Already a member?"
            linkText="Log in"
            onClick={onChangeViewHandler}
          />
        </div>
      </div>

      <div className="SignupEmailStep__footer fg-condensed--medium">
        <AutoLink to="/terms" target="_blank" onClick={onTermsClickHandler}>
          Terms and conditions
        </AutoLink>{' '}
        apply
      </div>
    </div>
  );
};

export default SignupEmailStep;
