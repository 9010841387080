import api from 'utils/huckberryApi';

export function fetchRestockNotifications() {
  return api.get('/api/restock-notifications.json');
}

export function addRestockNotification(variantId: number) {
  return api.post('/api/restock-notifications.json', {
    variantId
  });
}
