// @flow

import './PlainForgotPasswordView.scss';

import * as React from 'react';

import ForgotPasswordForm from 'components/Shared/ForgotPasswordForm';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (view: Views) => void
|};

const PlainForgotPasswordView = ({ onChangeView }: Props) => (
  <div className="PlainForgotPasswordView">
    <div className="PlainForgotPasswordView__wrapper">
      <ForgotPasswordForm onChangeView={onChangeView} />
    </div>
  </div>
);

export default PlainForgotPasswordView;
