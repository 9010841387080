// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';

import ExitIntentEmailStep from './ExitIntentEmailStep';
import ExitIntentPasswordStep from './ExitIntentPasswordStep';

import { events as analytics, trackAction } from 'modules/analytics';

import type { Views } from 'modules/auth-modal/types';

export type Step = 'email' | 'password' | null;

type Props = {|
  onChangeView: (Views) => void
|};

const EmailOnlySignupView = ({ onChangeView }: Props) => {
  const authModalEmail: string | null = useSelector(
    (state) => state.authModal.email
  );

  React.useEffect(() => {
    const contextStepSequenceSpecification = authModalEmail
      ? 'second-step'
      : 'first-step';

    trackAction(analytics.SOFT_GATE_STEP_VIEWED, {
      label: 'email-only',
      context_step_sequence: 'exit-intent',
      context_step_sequence_specification: contextStepSequenceSpecification
    });
  }, [authModalEmail]);

  if (!authModalEmail) {
    return <ExitIntentEmailStep onChangeView={onChangeView} />;
  }

  return <ExitIntentPasswordStep onChangeView={onChangeView} />;
};

export default EmailOnlySignupView;
