// @flow

import './SMSImageModal.scss';
import huckberryTreeLogoUrl from 'assets/images/shared/icon-huckberry-tree-black.svg';

import * as React from 'react';

import ResponsiveMedia from 'components/Shared/ResponsiveMedia/ResponsiveMedia';
import useOnClickOutside from 'components/utils/useOnClickOutside';

import SMSInput from './SMSInput';
type Props = {|
  title: string,
  button: string,
  trigger: string,
  handleClose: () => void,
  smsSignupSuccess: (string) => void
|};

const desktopImageUrl =
  'https://huckberry.imgix.net/sms_gate/timex-bg--desktop.jpg';
const mobileImageUrl =
  'https://huckberry.imgix.net/sms_gate/timex-bg--mobile.jpg';

const SMSImageModal = ({
  title,
  button,
  trigger,
  handleClose,
  smsSignupSuccess
}: Props) => {
  const modalContainer = React.useRef(null);
  useOnClickOutside(modalContainer, () => handleClose());

  const backgroundImage = (
    <>
      <ResponsiveMedia
        sizes="(max-width: 1000px) 1px, (max-width: 1200px) 980px, 1150px"
        mediaProps={{
          className: 'SMSImageModal__background--desktop'
        }}
        src={desktopImageUrl}
      />
      <ResponsiveMedia
        sizes="(max-width: 1000px) 80vw, 1px"
        mediaProps={{
          className: 'SMSImageModal__background--mobile'
        }}
        src={mobileImageUrl}
      />
    </>
  );
  return (
    <>
      <div className="SMSImageModal" ref={modalContainer}>
        {backgroundImage}
        <div className="SMSImageModal__content tc">
          <img className="SMSImageModal__icon" src={huckberryTreeLogoUrl} />
          <h3 className="SMSImageModal__title padb3--xxs padb2 block__subtitle fw--600">
            {title}
          </h3>
          <div className="SMSImageModal__input tl">
            <SMSInput
              smsSignupSuccess={smsSignupSuccess}
              trigger={trigger}
              buttonTitle={button}
            />
          </div>
        </div>
        <button
          className="SMSImageModal--close-btn"
          onClick={() => handleClose()}
        >
          +
        </button>
      </div>

      <div className="SMSImageModal__overlay" />
    </>
  );
};

export default SMSImageModal;
