// @flow

import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';

import type { ActivePageFiltersState } from '../types';

const sortFilters = (filters: ActivePageFiltersState) => {
  const sortedFilters = reduce<
    ActivePageFiltersState,
    $Shape<ActivePageFiltersState>
  >(
    filters,
    (result, value, key) => {
      if (isArray(value)) {
        result[key] = value.sort();
      } else {
        result[key] = value;
      }

      return result;
    },
    {}
  );

  return sortedFilters;
};

export default sortFilters;
