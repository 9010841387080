// @flow

import shippingWindowActionConverter from 'utils/shippingWindowActionConverter';

import type { ShippingRulesActionType, ShippingRulesStateType } from './types';

const initialState = {};

const reducer = (
  state: ShippingRulesStateType = initialState,
  action: ShippingRulesActionType
) => {
  Object.freeze(state);

  switch (action.type) {
    case 'SHIPPING_RULES/FETCH':
      return { ...state };
    case 'SHIPPING_RULES/FETCH_SUCCESS': {
      const { actionOutput, shippableOrderDate } = action.data;

      const { shippableKey } = action;

      let output = actionOutput;

      if (typeof actionOutput === 'object') {
        if (actionOutput.type === 'shipping_window') {
          const { template, minDays, maxDays } = actionOutput;
          output = shippingWindowActionConverter(
            template,
            minDays,
            maxDays,
            shippableOrderDate
          );
        } else if (actionOutput.type === 'text') {
          output = actionOutput.text;
        }
      }

      return {
        ...state,
        [shippableKey]: output
      };
    }
    case 'SHIPPING_RULES/FETCH_FAILURE':
      return { ...state };
    default:
      return state;
  }
};

export default reducer;
