import isEmpty from 'lodash/isEmpty';

import { put, takeLatest, select, StrictEffect } from 'redux-saga/effects';

import { clearErrors, fetchRequest } from 'modules/cart';
import { ApplicationState } from 'types/rootState';
import { CartState } from 'modules/cart';

interface SessionCartOrder {
  productsCount: number;
  orderNumber: string | null;
  token: string | null;
}

function* cartWidgetSaga(): Generator<StrictEffect> {
  yield takeLatest('CART_WIDGET/OPEN', fetchCart);
  yield takeLatest('CART_WIDGET/CLOSE', removeErrors);
}

function* fetchCart(): Generator<StrictEffect, any, any> {
  const cart: CartState = yield select((state: ApplicationState) => state.cart);
  const order: SessionCartOrder = yield select(
    (state: ApplicationState) => state.session.cart
  );

  if ('replaceState' in history) {
    const location = window && window.location;
    history.replaceState(
      '',
      document.title,
      `${location.pathname + location.search}#cart`
    );
  }

  if (!isEmpty(cart) && cart.promoCodeError) {
    yield put(clearErrors());
  }

  if (isEmpty(cart) && order.orderNumber) {
    yield put(fetchRequest(order.orderNumber, order.token));
  }
}

function* removeErrors(): Generator<StrictEffect> {
  const cartError = (state: ApplicationState) => state.cart.error;
  const error = yield select(cartError);

  if (error) {
    yield put(clearErrors());
  }
}

export default cartWidgetSaga;
