import { OrderType } from './types';

export const getOrder = (orderNumber: string) => ({
  type: 'ORDER/FETCH',
  orderNumber
});

export const cancelOrder = (orderNumber: string) => ({
  type: 'ORDER/CANCEL',
  orderNumber
});

export const getOrderSuccess = (data: OrderType) => ({
  type: 'ORDER/FETCH_SUCCESS',
  data
});

export const getOrderFailure = (error: string) => ({
  type: 'ORDER/FETCH_FAILURE',
  error
});
