// @flow

import './JournalWelcomeView.scss';

import * as React from 'react';

import type { Views } from 'modules/auth-modal/types';

const modalCopy = {
  eyebrow: 'Get our most-loved journal for free',
  title: '50 Books Every Guy Should Read',
  subtitle:
    'These books are essential tools for living a well-lived (well-read) life',
  button: 'Sign me up'
};

type Props = {|
  onChangeView: (Views) => void
|};

const JournalWelcomeView = ({ onChangeView }: Props) => (
  <div className="JournalWelcomeView tc">
    <div className="JournalWelcomeView__form-wrapper">
      {modalCopy.eyebrow && (
        <div className="JournalWelcomeView__eyebrow uppercase block__eyebrow--title--md">
          {modalCopy.eyebrow}
        </div>
      )}
      <h2 className="JournalWelcomeView__title fg-condensed--medium fw--600">
        {modalCopy.title}
      </h2>
      <h4 className="JournalWelcomeView__subtitle fg-condensed--xxsmall">
        {modalCopy.subtitle}
      </h4>
      <button
        type="submit"
        className="JournalWelcomeView__button uppercase fg-condensed--body"
        onClick={() => onChangeView('signup')}
      >
        {modalCopy.button}
      </button>
      <div className="JournalWelcomeView__footer fg-condensed--body">
        Already a member?{' '}
        <div
          onClick={() => onChangeView('login')}
          className="JournalWelcomeView__footer--cta link--nudge dib"
        >
          Log In
        </div>
      </div>
    </div>
  </div>
);

export default JournalWelcomeView;
