import { YotpoStateType, YotpoAction } from './types';

const initialState: YotpoStateType = {
  loaded: false
};

const reducer = (state = initialState, action: YotpoAction) => {
  switch (action.type) {
    case 'YOTPO_WIDGET/LOAD':
      return {
        ...state
      };
    case 'YOTPO_WIDGET/LOAD_SUCCESS':
      return {
        ...state,
        loaded: true
      };
    case 'YOTPO_WIDGET/LOAD_FAILURE':
    default:
      return state;
  }
};

export default reducer;
