// @flow

import analyticsSaga from 'modules/analytics/saga';
import announcementsSaga from 'modules/announcements/saga';
import addressFieldsSaga from 'modules/address-fields/saga';
import cartSaga from 'modules/cart/sagas';
import cartAddonsSaga from 'modules/cart-addons/saga';
import cartWidgetSaga from 'modules/cart-widget/saga';
import uiSaga from 'modules/ui/saga';
import restockNotificationSaga from 'modules/restock-notifications/saga';
import addressBookSaga from 'modules/address-book/saga';
import walletSaga from 'modules/wallet/saga';
import sessionSaga from 'modules/session/saga';
import redirectsSaga from 'modules/redirects/saga';
import backInBrowserSaga from 'modules/back-in-browser/saga';
import experimentsSaga from 'modules/experiments/saga';
import accountSaga from 'modules/account/saga';
import giftCardSaga from 'modules/gift-cards/saga';
import searchResultsSaga from 'modules/search-results/saga';
import shippingTouchpointsSaga from 'modules/shipping-touchpoints/saga';
import sitemapSaga from 'modules/sitemap/saga';
import searchSaga from 'modules/search/saga';
import orderSaga from 'modules/order/saga';
import aboutSaga from 'modules/about/saga';
import userFavoritesSaga from 'modules/user-favorites/saga';
import wishlistSaga from 'modules/wishlist/saga';
import shippingRulesSaga from 'modules/shipping-rules/saga';
import authModalSaga from 'modules/auth-modal/saga';
import calloutsSaga from 'modules/callouts/saga';
import notificationsSaga from 'modules/notifications/saga';
import querySaga from 'modules/query/saga';
import yotpoSaga from 'modules/yotpo/saga';
import styliticsSaga from 'modules/stylitics/saga';
import bundlesSaga from 'modules/bundles/saga';

export default [
  analyticsSaga(),
  announcementsSaga(),
  addressFieldsSaga(),
  cartSaga(),
  cartAddonsSaga(),
  cartWidgetSaga(),
  uiSaga(),
  restockNotificationSaga(),
  addressBookSaga(),
  walletSaga(),
  sessionSaga(),
  redirectsSaga(),
  backInBrowserSaga(),
  experimentsSaga(),
  accountSaga(),
  giftCardSaga(),
  searchResultsSaga(),
  shippingTouchpointsSaga(),
  sitemapSaga(),
  searchSaga(),
  orderSaga(),
  aboutSaga(),
  userFavoritesSaga(),
  wishlistSaga(),
  shippingRulesSaga(),
  authModalSaga(),
  calloutsSaga(),
  notificationsSaga(),
  querySaga(),
  yotpoSaga(),
  styliticsSaga(),
  bundlesSaga()
];
