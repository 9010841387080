// @flow

import { camelCase } from 'change-case';

export default function camelReplacer(
  key: string,
  value: ?string | ?Object | ?Array<any>
): ?string | ?Object | ?Array<any> {
  if (value && typeof value === 'object' && !Array.isArray(value)) {
    const replacement = {};
    for (const k in value) {
      const propertyExists = Object.hasOwnProperty.call(value, k);

      if (propertyExists) {
        // Ignores UUIDs
        if (k.includes('-')) {
          replacement[k] = value[k];
        } else {
          replacement[k && camelCase(k)] = value[k];
        }
      }
    }
    return replacement;
  }

  return value;
}
