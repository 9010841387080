// @flow

import api from 'utils/huckberryApi';

import type { Address } from 'modules/wallet/types';

type PaymentMethodProps = {|
  deviceData: string,
  source: {|
    nameOnSource: string,
    nonce: string,
    addressAttributes: $Shape<Address>
  |}
|};

export function getPaymentMethods() {
  return api.get('/api/wallet/');
}

export function createPaymentMethod(paymentMethod: PaymentMethodProps) {
  return api.post('/api/wallet', paymentMethod);
}

export function deletePaymentMethod(id: number) {
  return api.delete(`/api/wallet/${id}`);
}
