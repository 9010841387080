// @flow

import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

const useCurrentDate = () => {
  const fakeCurrentDate = useSelector(
    (state) => state.session.config.fakeCurrentDate
  );

  const currentDate = fakeCurrentDate
    ? DateTime.fromISO(fakeCurrentDate, { zone: 'utc' })
    : DateTime.utc();

  return currentDate;
};

export default useCurrentDate;
