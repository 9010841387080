// @flow

import loadable from '@loadable/component';

import { getStore } from 'store/createStore';
import { sagaMiddleware } from 'store/setupStore';

const preloadedState =
  typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : {};

const lazyLoad = () =>
  Promise.all([
    import('modules/journal-post'),
    import('modules/journal-post-ad'),
    import('modules/journal-navigation'),
    import('modules/journal-category'),
    import('modules/journal-homepage'),
    import('modules/journal-category/saga'),
    import('modules/journal-post/saga'),
    import('modules/journal-post-ad/saga'),
    import('modules/journal-homepage/saga')
  ]).then((modules) => {
    const reducersArray = [
      'journalPost',
      'journalPostAd',
      'journalNavigation',
      'journalCategory',
      'journalHomepage'
    ];
    const reducers = modules.splice(0, reducersArray.length);
    const sagas = modules;

    const store = getStore();

    reducersArray.forEach((key, index) => {
      store.injectReducer(key, reducers[index].default);

      if (key in preloadedState) {
        store.getState()[key] = preloadedState[key];
      }
    });

    sagas.forEach((saga) => {
      sagaMiddleware?.run(saga.default);
    });

    return import('components/JournalContainer/JournalContainer');
  });

const JournalContainerLoader = loadable(() => lazyLoad());

export default JournalContainerLoader;
