// @flow

import type { AuthenticationState } from './types';

const defaultAuthenticationState: AuthenticationState = {
  user: {
    email: '',
    context: 'signup',
    errors: {
      email: false,
      password: false,
      resetPasswordToken: false
    }
  },
  referredEmail: '',
  forgotPasswordSuccess: null,
  forgotPasswordResponse: null
};

const reducer = (state: AuthenticationState = defaultAuthenticationState) => {
  return state;
};

export default reducer;
