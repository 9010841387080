// @flow

import './PasswordInput.scss';

import * as React from 'react';

import ResponsiveMedia from 'components/Shared/ResponsiveMedia/ResponsiveMedia';

import Input from './Input';

const eyeOpenUrl = 'https://huckberry.imgix.net/softgate/eye-icon-open.png';
const eyeClosedUrl = 'https://huckberry.imgix.net/softgate/eye-icon-closed.png';

const EYE_DIMENSION = 18;

type Props = {|
  id?: string,
  value?: string,
  name?: string,
  placeholder?: string,
  suggestion?: string,
  error?: string | null,
  onInputChange?: (SyntheticKeyboardEvent<HTMLInputElement>) => void
|};

const PasswordInput = ({
  id,
  value,
  name,
  placeholder,
  suggestion,
  error,
  onInputChange
}: Props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const onShowPasswordClickHandler = React.useCallback(
    () => setShowPassword((currentShowPassword) => !currentShowPassword),
    []
  );

  return (
    <div className="PasswordInput">
      <Input
        id={id}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        value={value}
        name={name}
        onInputChange={onInputChange}
      >
        <Input.Suggestion>{suggestion}</Input.Suggestion>
        {error && <Input.Error>{error}</Input.Error>}
      </Input>
      <label
        onClick={onShowPasswordClickHandler}
        className="PasswordInput__eyeball"
        htmlFor={id}
      >
        <ResponsiveMedia
          width={EYE_DIMENSION}
          height={EYE_DIMENSION}
          src={showPassword ? eyeClosedUrl : eyeOpenUrl}
        />
      </label>
    </div>
  );
};

export default PasswordInput;
