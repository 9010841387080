import { SearchState, SearchResultActionState } from './types';
import convertSnakeCaseObjectToCamel from 'utils/convertSnakeCaseObjectToCamel';

const initialState = {
  query: '',
  productResults: [],
  categoryResults: [],
  brandResults: [],
  shopResults: [],
  journalResults: [],
  searchHistory: [],
  dropdownLinks: null,
  loading: false
};

const reducer = (
  state: SearchState = initialState,
  action: SearchResultActionState
) => {
  switch (action.type) {
    case 'SEARCH/UPDATE_RESULTS': {
      // Algolia Query ordering found in SearchAutoComplete.js
      // 0 - Products
      // 1 - Categories
      // 2 - Brands
      // 3 - Shops (Freeform + Query Pages)
      // 4 - Journal Posts
      return {
        ...state,
        query: action.query,
        productResults: convertSnakeCaseObjectToCamel(action.results[0].hits),
        categoryResults: action.results[1].hits,
        brandResults: action.results[2].hits,
        shopResults: action.results[3].hits,
        journalResults: action.results[4].hits,
        loading: false
      };
    }
    case 'SEARCH/LOAD_RESULTS': {
      return {
        ...state,
        query: action.query,
        loading: true
      };
    }
    case 'SEARCH/CLEAR_RESULTS': {
      return {
        ...state,
        query: '',
        productResults: [],
        categoryResults: [],
        brandResults: [],
        shopResults: [],
        journalResults: [],
        loading: false
      };
    }
    case 'SEARCH/FETCH_HISTORY_SUCCESS': {
      return {
        ...state,
        searchHistory: action.history
      };
    }
    case 'SEARCH/FETCH_DROPDOWN_LINKS_SUCCESS': {
      return {
        ...state,
        dropdownLinks: action.links
      };
    }
    case 'SEARCH/UPDATE_SEARCH_HISTORY_SUCCESS': {
      return {
        ...state,
        searchHistory: action.searchHistory
      };
    }
    default:
      return state;
  }
};

export default reducer;
