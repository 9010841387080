import { NotificationsStateType, NotificationsActions } from './types';

const initialState = {
  smsModal: 0,
  loading: false
};

const reducer = (
  state: NotificationsStateType = initialState,
  action: NotificationsActions
) => {
  switch (action.type) {
    case 'NOTIFICATIONS/UPDATE':
    case 'NOTIFICATIONS/FETCH':
      return { ...state, loading: true };
    case 'NOTIFICATIONS/FETCH_SUCCESS':
      return Object.assign({ ...state, loading: false }, action.data);
    case 'NOTIFICATIONS/FETCH_FAILURE':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
