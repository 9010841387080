// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export const {
  fetchSearchResults,
  fetchSearchResultsSuccess,
  fetchSearchResultsFailure,
  toggleSearchFilter,
  filterSearchResultsSuccess,
  clearSearchFilters,
  fetchMoreFilteredProductResultsSuccess,
  setSearchFilters
} = actionCreators;

export default reducer;
