// @flow

import './SocialButtons.scss';

import * as React from 'react';

import { type FromType } from 'components/Authentication/Container';

import facebookIcon from 'assets/images/shared/button-icon-facebook.svg';
import googleIcon from 'assets/images/shared/button-icon-google.svg';

type Props = {|
  buttonsValue: string,
  showFacebook?: boolean,
  showGoogle?: boolean,
  onFacebookClick?: (from: FromType, newAuth?: boolean) => void,
  onGoogleClick?: (from: FromType, newAuth?: boolean) => void
|};

const SocialButtons = ({
  buttonsValue,
  showFacebook = true,
  showGoogle = true,
  onFacebookClick: onFacebookHandler,
  onGoogleClick: onGoogleClickHandler
}: Props) => (
  <div className="SocialButtons">
    <div className="SocialButtons__separator fg-condensed--body tc">or</div>
    <div className="SocialButtons__wrapper">
      {showFacebook && (
        <button
          onClick={onFacebookHandler}
          className="uppercase SocialButtons__button button--facebook fg-condensed--body"
        >
          <p>{buttonsValue}</p>
          <img src={facebookIcon} alt="Facebook Icon" />
        </button>
      )}
      {showGoogle && (
        <button
          onClick={onGoogleClickHandler}
          className="uppercase SocialButtons__button button--google fg-condensed--body"
        >
          <p>{buttonsValue}</p>
          <img src={googleIcon} alt="Google Icon" />
        </button>
      )}
    </div>
  </div>
);

export default SocialButtons;
