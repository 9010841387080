// @flow

import './PappyDaySignupView.scss';

import * as React from 'react';
import { useSelector } from 'react-redux';

import SignupEmailStep from './SignupEmailStep';
import SignupPasswordStep from './SignupPasswordStep';

import { events as analytics, trackAction } from 'modules/analytics';

import type { Views } from 'modules/auth-modal/types';

export type Step = 'email' | 'password' | null;

type Props = {|
  onChangeView: (Views) => void,
  onClose: (method: string) => void
|};

const PappyDaySignupView = ({ onChangeView, onClose }: Props) => {
  const authModalEmail: string | null = useSelector(
    (state) => state.authModal.email
  );

  React.useEffect(() => {
    const contextStepSequenceSpecification = authModalEmail
      ? 'second-step'
      : 'first-step';

    trackAction(analytics.SOFT_GATE_STEP_VIEWED, {
      label: 'pappy-day',
      context_step_sequence: 'signup',
      context_step_sequence_specification: contextStepSequenceSpecification
    });
  }, [authModalEmail]);

  return (
    <div className="PappyDaySignupView">
      {authModalEmail ? (
        <SignupPasswordStep onChangeView={onChangeView} onClose={onClose} />
      ) : (
        <SignupEmailStep onChangeView={onChangeView} />
      )}
    </div>
  );
};

export default PappyDaySignupView;
