// @flow

import './SignupEmailStep.scss';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import AuthenticationContainer, {
  type AuthenticationProps
} from 'components/Authentication/Container';
import EmailForm from 'components/Shared/AuthForm/TwoStepSignup/EmailForm';
import CTALink from 'components/Shared/AuthForm/CTALink';
import AutoLink from 'components/utils/AutoLink';

import { close } from 'modules/auth-modal';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (Views) => void
|};

const SignupEmailStep = ({ onChangeView }: Props) => {
  const dispatch = useDispatch();

  const onChangeViewHandler = React.useCallback(() => onChangeView('login'), [
    onChangeView
  ]);

  const onTermsClickHandler = React.useCallback(() => dispatch(close()), [
    dispatch
  ]);

  return (
    <div className="SignupEmailStep">
      <div className="SignupEmailStep__wrapper tc">
        <p className="SignupEmailStep__eyebrow fg-condensed--medium fw--600">
          The Huckberry Membership
        </p>
        <p className="SignupEmailStep__title fg-condensed--medium fw--600">
          Join and Get Our Award Winning Newsletter
        </p>
        <p className="SignupEmailStep__content fg-condensed--medium">
          You&apos;ll also receive the coolest gear, inspirational stories, and
          a hell of a lot more delivered straight to your inbox. You know, just
          the good stuff.
        </p>
        <div className="SignupEmailStep__form">
          <AuthenticationContainer trait="signup-from-email-only-soft-gate-modal">
            {({
              onClickFacebookSignup,
              onClickGoogleSignup
            }: AuthenticationProps) => (
              <EmailForm
                buttonValue="Send It"
                onClickFacebookSignup={onClickFacebookSignup}
                onClickGoogleSignup={onClickGoogleSignup}
                trigger="Email Only Softgate View"
              />
            )}
          </AuthenticationContainer>
          <CTALink
            textBefore="Already a member?"
            linkText="Log in"
            onClick={onChangeViewHandler}
          />
        </div>
      </div>

      <div className="SignupEmailStep__footer fg-condensed--medium">
        *Offer excludes international and expedited orders.
        <br />
        By continuing you agree to our{' '}
        <AutoLink to="/terms" target="_blank" onClick={onTermsClickHandler}>
          terms and conditions
        </AutoLink>
      </div>
    </div>
  );
};

export default SignupEmailStep;
