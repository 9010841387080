// @flow

import filter from 'lodash/filter';

import type { PaymentMethod, Wallet, WalletAction } from './types';

const initialState = {
  paymentMethods: [],
  isLoading: false
};

const reducer = (state: Wallet = initialState, action: WalletAction) => {
  switch (action.type) {
    case 'WALLET/GET_PAYMENT_METHODS_SUCCESS':
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        isLoading: false
      };
    case 'WALLET/DELETE_PAYMENT_METHOD_FAILURE':
    case 'WALLET/CREATE_PAYMENT_METHOD_FAILURE':
    case 'WALLET/GET_PAYMENT_METHODS_FAILURE':
      return {
        ...state,
        isLoading: false
      };
    case 'WALLET/GET_PAYMENT_METHODS':
    case 'WALLET/DELETE_PAYMENT_METHOD':
    case 'WALLET/CREATE_PAYMENT_METHOD':
      return {
        ...state,
        isLoading: true
      };
    case 'WALLET/CREATE_PAYMENT_METHOD_SUCCESS':
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, action.paymentMethod],
        isLoading: false
      };
    case 'WALLET/DELETE_PAYMENT_METHOD_SUCCESS':
      return {
        ...state,
        paymentMethods: filter<PaymentMethod>(
          state.paymentMethods,
          (paymentMethod: PaymentMethod) => {
            return paymentMethod.id !== action.id;
          }
        ),
        isLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
