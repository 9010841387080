import camelCase from 'lodash/camelCase';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertSnakeCaseObjectToCamel = (snakeCaseObj: any): any => {
  if (Array.isArray(snakeCaseObj)) {
    return snakeCaseObj.map((item) => convertSnakeCaseObjectToCamel(item));
  } else if (snakeCaseObj !== null && typeof snakeCaseObj === 'object') {
    return Object.entries(snakeCaseObj).reduce(
      (acc: AnyObject, [key, value]) => {
        const newKey = camelCase(key);
        acc[newKey] = convertSnakeCaseObjectToCamel(value);
        return acc;
      },
      {}
    );
  }
  return snakeCaseObj;
};

export default convertSnakeCaseObjectToCamel;
