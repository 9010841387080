// @flow

import qs from 'qs';

import api from 'utils/huckberryApi';

export const fetchCallouts = (ids: Array<string>) => {
  const idQueryString = qs.stringify({ id: ids }, { arrayFormat: 'brackets' });

  return api.get(`/api/pages/tiles/callouts?${idQueryString}`);
};
