// @flow

import { call, put, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import * as actions from './action-creators';
import * as requests from './requests';

function* walletSaga(): Saga {
  yield takeEvery('WALLET/GET_PAYMENT_METHODS', getPaymentMethodsSaga);
  yield takeEvery('WALLET/CREATE_PAYMENT_METHOD', createPaymentMethodSaga);
  yield takeEvery('WALLET/DELETE_PAYMENT_METHOD', deletePaymentMethodSaga);
}

function* getPaymentMethodsSaga() {
  try {
    const response = yield call(requests.getPaymentMethods);

    yield put(actions.getPaymentMethodsSuccess(response.data));
  } catch (error) {
    yield put(actions.getPaymentMethodsFailure());
  }
}

function* createPaymentMethodSaga(action) {
  const { token, address, deviceData } = action;

  try {
    const response = yield call(requests.createPaymentMethod, {
      deviceData,
      source: {
        nameOnSource: `${address.firstname} ${address.lastname}`,
        nonce: token,
        addressAttributes: address
      }
    });

    yield put(actions.createPaymentMethodSuccess(response.data.source));
  } catch (error) {
    yield put(actions.createPaymentMethodFailure());
  }
}

function* deletePaymentMethodSaga(action) {
  const { id } = action;

  try {
    yield call(requests.deletePaymentMethod, id);

    yield put(actions.deletePaymentMethodSuccess(id));
  } catch (error) {
    yield put(actions.deletePaymentMethodFailure());
  }
}

export default walletSaga;
