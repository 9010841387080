// @flow

const AUTH_MODAL_OPEN = 'AUTH_MODAL/OPEN';
const AUTH_MODAL_OPEN_SUCCESS = 'AUTH_MODAL/OPEN_SUCCESS';
const AUTH_MODAL_CLOSE = 'AUTH_MODAL/CLOSE';
const AUTH_MODAL_CHANGE_VIEW = 'AUTH_MODAL/CHANGE_VIEW';
const AUTH_MODAL_CLEAR_EMAIL = 'AUTH_MODAL/CLEAR_EMAIL';
const AUTH_MODAL_NEWSLETTER_OPTIN = 'AUTH_MODAL/NEWSLETTER_OPTIN';

export default {
  AUTH_MODAL_OPEN,
  AUTH_MODAL_OPEN_SUCCESS,
  AUTH_MODAL_CLOSE,
  AUTH_MODAL_CHANGE_VIEW,
  AUTH_MODAL_CLEAR_EMAIL,
  AUTH_MODAL_NEWSLETTER_OPTIN
};
