import { NavigationState } from './types';
export type { NavigationState } from './types';

const reducer = (
  state: NavigationState = {
    headings: []
  }
): NavigationState => state;

export default reducer;
