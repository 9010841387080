// @flow

const updateHistory = (pathname: string) => {
  return {
    type: 'HISTORY/UPDATE',
    pathname
  };
};

export { updateHistory };
