export function fetchStates(countryId) {
  return {
    type: 'ADDRESS_FIELDS/REQUEST_STATES',
    countryId
  };
}

export function receiveStates(countryId, data) {
  return {
    type: 'ADDRESS_FIELDS/RECEIVE_STATES',
    countryId,
    states: data.states
  };
}
