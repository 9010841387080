// @flow

import type { Trait } from 'modules/authentication';

const oauthPostSubmitForm = (url: string, trait: Trait) => {
  const form = document.createElement('form');
  const tokenElement = document.querySelector('meta[name="csrf-token"]');
  const formUrl = `${url}?source=${trait}`;

  if (tokenElement && tokenElement instanceof HTMLMetaElement) {
    const csrfToken = tokenElement.content;
    const csrfInput = document.createElement('input');
    csrfInput.name = 'authenticity_token';
    csrfInput.value = csrfToken;
    csrfInput.type = 'hidden';
    form.appendChild(csrfInput);
  }

  form.method = 'POST';
  form.action = formUrl;

  if (document.body) {
    document.body.appendChild(form);
  }

  form.submit();
};

export default oauthPostSubmitForm;
