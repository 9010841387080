import api from 'utils/huckberryApi';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { NotificationNamesType } from './types';

export const getNotifications = () => api.get('/api/notifications');

export const setNotification = (name: NotificationNamesType, viewed: boolean) =>
  api.post('/api/notifications', {
    notification: omitBy({ name, viewed }, isNil)
  });
