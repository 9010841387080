import './Button.scss';

import React from 'react';

type Props = {
  value: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
};

const Button = ({ value, type = 'button', onClick: onClickHandler }: Props) => (
  <button
    className="Button button--primary--yellow"
    type={type}
    onClick={onClickHandler}
  >
    {value}
  </button>
);

export default Button;
