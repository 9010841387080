// @flow

import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';

import type { Address, AddressBookState, AddressBookAction } from './types';

const initialState = {
  addresses: [],
  isLoading: false
};

const reducer = (
  state: AddressBookState = initialState,
  action: AddressBookAction
) => {
  switch (action.type) {
    case 'ADDRESS_BOOK/GET_ADDRESSES':
    case 'ADDRESS_BOOK/CREATE_ADDRESS':
    case 'ADDRESS_BOOK/UPDATE_ADDRESS':
    case 'ADDRESS_BOOK/DELETE_ADDRESS':
      return {
        ...state,
        isLoading: true
      };
    case 'ADDRESS_BOOK/GET_ADDRESSES_SUCCESS':
      return {
        ...state,
        addresses: action.addresses,
        isLoading: false
      };
    case 'ADDRESS_BOOK/CREATE_ADDRESS_SUCCESS':
    case 'ADDRESS_BOOK/UPDATE_ADDRESS_SUCCESS':
      return {
        ...state,
        addresses: uniqBy<Address>(
          [action.address, ...state.addresses],
          (address) => address.id
        ),
        isLoading: false
      };
    case 'ADDRESS_BOOK/DELETE_ADDRESS_SUCCESS':
      return {
        ...state,
        addresses: filter<Address>(state.addresses, (address: Address) => {
          return address.id !== action.id;
        }),
        isLoading: false
      };
    case 'ADDRESS_BOOK/CREATE_ADDRESS_FAILURE':
    case 'ADDRESS_BOOK/UPDATE_ADDRESS_FAILURE':
      return {
        ...state,
        reason: action.reason,
        isLoading: false
      };
    case 'ADDRESS_BOOK/GET_ADDRESSES_FAILURE':
    case 'ADDRESS_BOOK/DELETE_ADDRESS_FAILURE':
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
