import React from 'react';
import classnames from 'classnames';

type Props = {
  linkText: string;
  textBefore?: string;
  textAfter?: string;
  dark?: boolean;
  onClick?: () => void;
};

/**
 * TS version of frontend/components/Shared/AuthForm/CTALink.js
 * with updated typography
 * which will be slowly be replaced after TS migration to the auth form
 */
const CTALink = ({
  linkText,
  textBefore,
  textAfter,
  dark = false,
  onClick: onClickHandler
}: Props) => (
  <p
    className={classnames(
      'color--white padt6 padb4 text-base-no-break-point font-primary fw--400',
      {
        'color--blue': dark
      }
    )}
  >
    {textBefore && textBefore}{' '}
    <span
      className="primary-link-preset-4 fw--400 font-primary color-primary hover-blue-faded-70"
      onClick={onClickHandler}
    >
      {linkText}
    </span>{' '}
    {textAfter && textAfter}
  </p>
);

export default CTALink;
