import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchOutfitsSuccess, fetchOutfitsFailure } from './action-creators';
import { StyliticsFetchType } from './types';

import { getStyliticsOutfits } from './requests';

function* styliticsSaga(): SagaIterator {
  yield takeEvery('STYLITICS/FETCH_OUTFITS', fetchOutfits);
}

function* fetchOutfits(action: StyliticsFetchType): SagaIterator {
  try {
    const outfits = yield call(getStyliticsOutfits, action.seed);
    yield put(fetchOutfitsSuccess(outfits));
  } catch {
    yield put(fetchOutfitsFailure(action.seed));
  }
}

export default styliticsSaga;
