import {
  StyliticsOutfitItem,
  StyliticsInsightsType,
  StyliticsTrackActionType
} from './types';

export const fetchOutfits = (seed: string) => ({
  type: 'STYLITICS/FETCH_OUTFITS',
  seed
});

export const fetchOutfitsSuccess = (outfits: StyliticsOutfitItem[]) => ({
  type: 'STYLITICS/FETCH_OUTFITS_SUCCESS',
  outfits
});

export const fetchOutfitsFailure = (seed: string) => ({
  type: 'STYLITICS/FETCH_OUTFITS_FAILURE',
  seed
});

export const trackStylitics = (action: StyliticsTrackActionType) => {
  const styliticsAnalytics = window?.StyliticsInsights?.create({
    client: 'huckberry'
  });

  if (styliticsAnalytics) {
    // https://docs.stylitics.com/hc/en-us/articles/24428109884315-Stylitics-Insights-Script
    // Documentation on Stylitics Analytic Events
    const { event, data } = action;
    switch (event) {
      case 'trackItemClick':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) =>
          insights.trackItemClick({
            item: data.item,
            placement: data.placement,
            position: data.position
          })
        );
      case 'trackItemView':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) =>
          insights.trackItemView({
            item: data.item,
            // Note: camelCase is intentional here, see documentation above
            itemImageType: data.itemImageType,
            position: data.position
          })
        );
      case 'trackOutfitClick':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) => {
          insights.trackOutfitClick({
            outfit: data.outfit,
            outfit_position: data.outfit_position,
            uiComponent: data.uiComponent
          });
        });
      case 'trackOutfitView':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) => {
          insights.trackOutfitView({
            outfit: data.outfit,
            outfit_position: data.outfit_position
          });
        });
      case 'trackPageVisit':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) =>
          // Docs say N/A so I'm assuming it's empty data.
          insights.trackPageVisit()
        );
      case 'trackAddItemToCart':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) =>
          insights.trackAddItemToCart({
            item_id: data.item_id,
            placement: data.placement,
            price: data.price
          })
        );
      case 'trackPurchase':
        return styliticsAnalytics.then((insights: StyliticsInsightsType) =>
          insights.trackPurchase({
            client_user_id: data.client_user_id,
            order_id: data.order_id,
            order_total: data.order_total,
            items: data.items
          })
        );

      default:
        return;
    }
  }
};
