export default {
  APPLE_PAY_CLICKED: 'Apple Pay Clicked',
  CART_VIEWED: 'Cart Viewed',
  CHECKOUT_STARTED: 'Checkout Started',
  CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
  CHECKOUT_STEP_ERRORED: 'Checkout Step Errored',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  COUPON_ENTERED: 'Coupon Entered',
  COUPON_APPLIED: 'Coupon Applied',
  COUPON_DENIED: 'Coupon Denied',
  NAV_CLICKED: 'Navigation Clicked',
  FOOTER_LINK_CLICKED: 'Footer Link Clicked',
  ORDER_COMPLETED: 'Order Completed',
  LOGIN_VIEWED: 'Login Viewed',
  SIGNUP_VIEWED: 'Signup Viewed',
  PAYMENT_INFO_ENTERED: 'Payment Info Entered',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_CLICKED: 'Product Clicked',
  PRODUCT_BRAND_CLICKED: 'Product Brand Clicked',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_REMOVED: 'Product Removed',
  PROMOTION_VIEWED: 'Promotion Viewed',
  PROMOTION_CLICKED: 'Promotion Clicked',
  PRODUCTS_SEARCHED: 'Products Searched',
  JOURNAL_POST_VIEWED: 'Journal Post Viewed',
  JOURNAL_CATEGORY_VIEWED: 'Journal Category Viewed',
  JOURNAL_HOMEPAGE_VIEWED: 'Journal Homepage Viewed',
  JOURNAL_LINK_CLICKED: 'Journal Link Clicked',
  HARD_GATE_VIEWED: 'Hard Gate Viewed',
  SOFT_GATE_VIEWED: 'Soft Gate Viewed',
  SOFT_GATE_CLOSED: 'Soft Gate Closed',
  USER_REGISTERED: 'User Registered',
  USER_REGISTRATION_SUBMITTED: 'User Registration Submitted',
  USER_LOGGED_IN: 'User Logged In',
  USER_LOG_IN_SUBMITTED: 'User Log In Submitted',
  USER_NEWSLETTER_OPTIN_SUBMITTED: 'User Newsletter Optin Submitted',
  SOFT_GATE_STEP_VIEWED: 'Soft Gate Step Viewed',
  HEADER_SIGNUP_CLICKED: 'Header Sign Up Clicked',
  POST_BLOCK_SIGNUP_CLICKED: 'On Page Block Sign Up Clicked',
  SEARCH_RESULTS_VIEWED: 'Search Results Viewed',
  AUTOCOMPLETE_SEARCH_PRODUCT_CLICKED: 'Autocomplete Search Product Clicked',
  AUTOCOMPLETE_SEARCH_ITEM_CLICKED: 'Autocomplete Search Item Clicked',
  SEARCH_FOCUS_STATE_ITEM_CLICKED: 'Search Focus State Item Clicked',
  SEARCH_ICON_CLICKED: 'Search Icon Clicked',
  EXTERNAL_LINK_CLICKED: 'External Link Clicked',
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  NOTIFICATION_VIEWED: 'Notification Viewed',
  NOTIFICATION_CLICKED: 'Notification Clicked',
  SMS_REGISTRATION_VIEWED: 'SMS Registration Viewed',
  USER_SMS_REGISTERED: 'User SMS Registered',
  MARKETING_SUBSCRIBE_CLICKED: 'Marketing Subscribe Clicked',
  DROPDOWN_OPENED: 'Dropdown Opened',
  DROPDOWN_ITEM_CLICKED: 'Dropdown Item Clicked',
  FIT_BLOCK_INTERACTED: 'Fit Block Interacted',
  FIT_BLOCK_VIEWED: 'Fit Block Viewed',
  WISHLIST_MODAL_OPENED: 'Wishlist Modal Opened',
  WISHLIST_MODAL_VIEWED: 'Wishlist Modal Viewed',
  WISHLIST_CREATED: 'Wishlist Created',
  WISHLIST_VIEWED: 'Wishlist Viewed',
  WISHLIST_UPDATED: 'Wishlist Updated',
  /* ___ Page Block Events ___ */
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  PRODUCT_LIST_FILTERED: 'Product List Filtered',
  PAGE_BLOCK_CLICKED: 'Page Block Clicked',
  PAGE_BLOCK_VIEWED: 'Page Block Viewed',
  CALLOUT_CLICKED: 'Callout Clicked',
  PRODUCT_RECOMMENDATION_CLICKED: 'Product Recommendation Clicked',
  PRODUCT_ADDED_TO_WISHLIST: 'Product Added to Wishlist',
  PRODUCT_REMOVED_FROM_WISHLIST: 'Product Removed from Wishlist',
  FAVORITE_MESSAGE_CLOSED: 'Favorite Message Closed',
  FAVORITE_MESSAGE_VIEWED: 'Favorite Message Viewed',
  FAVORITE_NOTIFICATION_ITEM_CLICKED: 'Favorite Notification Item Clicked',
  PODCAST_PLAYER_INTERACTED: 'Podcast Player Interacted',
  PODCAST_PLAYER_DISPLAYED: 'Podcast Player Displayed',
  COMPARE_PAGE_VIEWED: 'Compare Page Viewed',
  COMPARE_PAGE_ITEM_CLICKED: 'Compare Page Item Clicked',
  VIDEO_DISPLAYED: 'Video Displayed',
  VIDEO_INTERACTED: 'Video Interacted',
  LINKABLE_IMAGE_BLOCK_CLICKED: 'Linkable Image Block Clicked',
  FULL_WIDTH_IMAGE_BLOCK_CLICKED: 'Full Width Image Block Clicked',
  JOURNAL_POST_AD_VIEWED: 'Journal Post Ad Viewed',
  JOURNAL_POST_AD_CLICKED: 'Journal Post Ad Clicked',
  VIDEO_THUMB_CLICKED: 'Video Thumb Clicked',
  EPPO_ASSIGNMENT: 'Eppo Randomized Assignment'
};
