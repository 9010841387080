// @flow

import { createStore as reduxCreateStore } from 'redux';
import createReducer, { defaultReducersList } from 'reducers';

import { saveState, savableModules } from './localStorage';

let store;

// Remove all the initial state from SSR
// which belongs to the async reducers.
// The SSR state related to async reducers will
// be injected using the loadable lazy load.
const cleanUpTheInitialState = (initialState) => {
  const massagedInitialState = {};

  Object.keys(initialState).forEach((stateKey) => {
    if (defaultReducersList.includes(stateKey)) {
      massagedInitialState[stateKey] = initialState[stateKey];
    }
  });

  return massagedInitialState;
};

const createStore = (browserHistory: any, initialState: {}, enhancers: any) => {
  const massagedInitialState = cleanUpTheInitialState(initialState);

  store = reduxCreateStore(
    createReducer(browserHistory),
    massagedInitialState,
    enhancers
  );

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (reducerKey, reducer) => {
    if (!reducerKey || store.asyncReducers[reducerKey]) {
      return;
    }

    store.asyncReducers[reducerKey] = reducer;
    store.replaceReducer(createReducer(browserHistory, store.asyncReducers));
  };

  // Listen for updates and save the store on the session storage
  store.subscribe(() => {
    const currentState = store.getState();

    savableModules.forEach((savableModule) =>
      saveState(savableModule, currentState[savableModule])
    );
  });

  return store;
};

const getStore = () => {
  return store;
};

export { getStore };
export default createStore;
