// @flow

import './BackInStockLoginView.scss';

import * as React from 'react';

import AuthenticationContainer from 'components/Authentication/Container';

import AuthForm from 'components/Shared/AuthForm';
import CTALink from 'components/Shared/AuthForm/CTALink';

import type { AuthenticationProps } from 'components/Authentication/Container';
import { type Views } from 'modules/auth-modal/types';

import huckberryTreeLogoUrl from 'assets/images/shared/icon-huckberry-tree-black.svg';

type Props = {|
  onChangeView: (view: Views) => void
|};

const BackInStockLoginView = ({ onChangeView }: Props) => (
  <div className="BackInStockLoginView tc">
    <img
      className="BackInStockLoginView__tree-logo"
      src={huckberryTreeLogoUrl}
    />
    <h3 className="BackInStockLoginView__title fg-condensed--medium fw--600">
      Welcome Back
    </h3>
    <h4 className="BackInStockLoginView__subtitle fg-condensed--body">
      Log in to add this item to your Back In Stock list
    </h4>
    <div className="BackInStockLoginView__auth--wrapper">
      <AuthenticationContainer trait="signup-from-back-in-stock-modal">
        {(authenticationProps: AuthenticationProps) => (
          <AuthForm
            view="login"
            onChangeView={onChangeView}
            submitText="Log In"
            {...authenticationProps}
          />
        )}
      </AuthenticationContainer>
    </div>

    <CTALink
      textBefore="Not a member?"
      linkText="Sign Up"
      dark
      onClick={() => onChangeView('signup')}
    />
  </div>
);

export default BackInStockLoginView;
