// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export type { WishlistType } from './types';

const { fetchWishlist, updateWishlist, clearWishlist } = actionCreators;

export { fetchWishlist, updateWishlist, clearWishlist };

export default reducer;
