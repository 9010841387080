// from https://usehooks.com/useOnClickOutside/
// @flow

import * as React from 'react';

type Ref = {
  current: null | HTMLDivElement | HTMLInputElement | HTMLSpanElement
};

function useOnClickOutside(
  ref: Ref,
  handler: (MouseEvent | TouchEvent) => void
) {
  React.useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains((event.target: any))) {
        return;
      }

      handler(event);
    };

    document.body?.addEventListener('mousedown', listener);
    document.body?.addEventListener('touchstart', listener);

    return () => {
      document.body?.removeEventListener('mousedown', listener);
      document.body?.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
