import { CartAddonsState, CartAddonsAction } from './types';

const initialState: CartAddonsState = {
  addonsProducts: [],
  googleRecommendedProducts: []
};

const reducer = (
  state: CartAddonsState = initialState,
  action: CartAddonsAction
) => {
  switch (action.type) {
    case 'CART_ADDONS/GET_CART_ADDONS_PRODUCTS_SUCCESS': {
      return {
        ...state,
        addonsProducts: action.addonProducts
      };
    }
    case 'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS_SUCCESS': {
      return {
        ...state,
        googleRecommendedProducts: action.googleRecommendedProducts
      };
    }
    default:
      return state;
  }
};

export default reducer;
