import reducer from './reducer';

export {
  fetchOutfits,
  fetchOutfitsSuccess,
  fetchOutfitsFailure,
  trackStylitics
} from './action-creators';

export type { StyliticsStateType } from './types';

export default reducer;
