export const yotpoWidgetLoad = (refreshWidgetTimeout: number) => ({
  type: 'YOTPO_WIDGET/LOAD',
  refreshWidgetTimeout
});

export const yotpoWidgetLoadSuccess = () => ({
  type: 'YOTPO_WIDGET/LOAD_SUCCESS'
});

export const yotpoWidgetLoadFailure = () => ({
  type: 'YOTPO_WIDGET/LOAD_FAILURE'
});
