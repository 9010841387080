import './EventMessage.scss';

import React from 'react';
import classnames from 'classnames';

import ResponsiveMedia from 'components/Shared/ResponsiveMedia/ResponsiveMedia';

import PopUpArrowIcon from 'assets/images/shared/icon-popup-arrow.svg';

type Props = {
  children: React.ReactNode;
  showCarrotIcon: boolean;
  imageUrl?: string;
  duration?: number;
  hideCloseButton?: boolean;
  onCloseNotificationHandler?: () => void;
};

const EventMessage = ({
  children,
  showCarrotIcon,
  imageUrl,
  duration,
  hideCloseButton = false,
  onCloseNotificationHandler
}: Props) => {
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const [visible, setVisible] = React.useState(true);

  const onCloseHandler = React.useCallback(() => {
    onCloseNotificationHandler && onCloseNotificationHandler();
    setVisible(false);
  }, [onCloseNotificationHandler]);

  React.useEffect(() => {
    if (children && duration) {
      timeoutRef.current = setTimeout(() => {
        onCloseHandler();
      }, duration);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [duration, children, onCloseHandler]);

  const onMouseLeaveHandler = React.useCallback(() => {
    if (duration) {
      timeoutRef.current = setTimeout(() => {
        onCloseHandler();
      }, duration);
    }
  }, [duration, onCloseHandler]);

  const onMouseEnterHandler = React.useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div
      className={classnames('EventMessage', {
        'EventMessage--without-image': !imageUrl,
        'EventMessage--without-close-btn': hideCloseButton
      })}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <div className="EventMessage__content">
        <ResponsiveMedia
          src={imageUrl}
          width={50}
          height={50}
          useHeight
          mediaProps={{ className: 'EventMessage__image' }}
        />
        <div className="EventMessage__main-content">{children}</div>
        {!hideCloseButton && (
          <div className="EventMessage__close-btn" onClick={onCloseHandler}>
            +
          </div>
        )}
      </div>

      {showCarrotIcon && (
        <img
          className={classnames('EventMessage__popup-arrow')}
          src={PopUpArrowIcon}
        />
      )}
    </div>
  );
};

export default EventMessage;
