import { QueryData } from './types';

export const fetchQuery = (queryIds: string[]) => ({
  type: 'QUERY/FETCH',
  queryIds
});

export const fetchQuerySuccess = (queries: QueryData) => ({
  type: 'QUERY/FETCH_SUCCESS',
  queries
});

export const fetchQueryFailure = (error: any) => ({
  type: 'QUERY/FETCH_FAILURE',
  error
});
