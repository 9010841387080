// @flow

import type { ShippingRulesActionDataType } from './types';

export const getShippingRules = (
  shippableId: number,
  shippableType: string,
  ruleType: string,
  shippingMethodId?: number,
  clearShippingCache?: boolean = false
) => ({
  type: 'SHIPPING_RULES/FETCH',
  payload: {
    shippableId,
    shippableType,
    ruleType,
    shippingMethodId,
    clearShippingCache
  }
});

export const getShippingRulesSuccess = (
  data: ShippingRulesActionDataType,
  shippableKey: string
) => ({
  type: 'SHIPPING_RULES/FETCH_SUCCESS',
  data,
  shippableKey
});

export const getShippingRulesFailure = () => ({
  type: 'SHIPPING_RULES/FETCH_FAILURE'
});
