// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export type AboutState = ?{|
  instagram: InstagramBlocksType
|};
export type InstagramBlocksType = Array<{|
  url: string,
  image: string
|}>;

export const { getInstagramBlocks } = actionCreators;

export default reducer;
