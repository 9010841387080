// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import loadable from '@loadable/component';

import LoadingFallback from 'components/utils/LoadingFallback';

import { open as openCart, close as closeCart } from 'modules/cart-widget';
import { fetchCurrent as fetchCart } from 'modules/cart';

import {
  type ShippingTouchpointsStateType,
  getShippingTouchpoints
} from 'modules/shipping-touchpoints';
import type { SessionState } from 'modules/session';

import type { Cart } from '../CartMini';

const CartMiniLoader = loadable(() => import('components/CartMini/CartMini'), {
  fallback: LoadingFallback()
});

type ConnectedProps = {|
  open: () => void,
  close: () => void,
  shippingTouchpoints: ShippingTouchpointsStateType,
  getShippingTouchpoints: () => ShippingTouchpointsStateType,
  fetchCart: () => void,
  cart: Cart,
  contained: boolean,
  session: SessionState,
  pathname: string
|};

type Props = {|
  ...ConnectedProps,
  contained?: boolean
|};

const PopoverCart = ({
  open,
  close,
  shippingTouchpoints,
  getShippingTouchpoints,
  fetchCart,
  cart,
  contained,
  session,
  pathname
}: Props) => {
  React.useEffect(() => {
    !shippingTouchpoints.loaded && getShippingTouchpoints();
    !cart?.loaded && fetchCart();

    // Opens cart widget if the URL contains a hash
    window?.location?.hash?.replace('#', '') === 'cart' && open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseHandler = () => {
    close();
    clearLocation();
  };

  const clearLocation = React.useCallback(() => {
    const location = window && window.location;

    if ('replaceState' in history) {
      history.replaceState(
        '',
        document.title,
        location.pathname + location.search
      );
    }
  }, []);

  if (!cart?.loaded || !session.loadedAt || pathname === '/checkout/onepage') {
    return null;
  }

  return (
    <CartMiniLoader
      onCloseHandler={onCloseHandler}
      contained={contained}
      cart={cart}
      session={session}
      shippingTouchpoints={shippingTouchpoints}
    />
  );
};

PopoverCart.defaultProps = {
  contained: false
};

const mapStateToProps = ({ shippingTouchpoints, cart, session, router }) => ({
  shippingTouchpoints,
  cart,
  session,
  pathname: router.location.pathname
});

const mapDispatchToProps = (dispatch) => ({
  open: () => dispatch(openCart()),
  close: () => dispatch(closeCart()),
  getShippingTouchpoints: () => dispatch(getShippingTouchpoints()),
  fetchCart: () => dispatch(fetchCart())
});

export default connect(mapStateToProps, mapDispatchToProps)(PopoverCart);
