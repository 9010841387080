import { NotificationsStateType, NotificationNamesType } from './types';

export const fetchNotifications = () => ({
  type: 'NOTIFICATIONS/FETCH'
});

export const fetchNotificationsSuccess = (data: NotificationsStateType) => ({
  type: 'NOTIFICATIONS/FETCH_SUCCESS',
  data
});

export const fetchNotificationsFailure = () => ({
  type: 'NOTIFICATIONS/FETCH_FAILURE'
});

export const updateNotification = (
  name: NotificationNamesType,
  viewed: boolean
) => ({
  type: 'NOTIFICATIONS/UPDATE',
  name,
  viewed
});
