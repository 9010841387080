// @flow

import api from 'utils/huckberryApi';

import type { AxiosPromise } from 'axios';

import type { Address } from './types';

type AddressResponse = {
  success: boolean,
  address?: Address,
  errors: Array<string>
};

export function getAddresses(): AxiosPromise<[Address]> {
  return api.get('/api/addressbook');
}

export function createAddress(address: Address): AxiosPromise<AddressResponse> {
  return api.post('/api/addressbook', { address });
}

export function updateAddress(address: Address): AxiosPromise<AddressResponse> {
  return api.put(`/api/addressbook/${address.id}`, { address });
}

export function deleteAddress(id: number): AxiosPromise<{ success: boolean }> {
  return api.delete(`/api/addressbook/${id}`);
}
