// @flow

import { select, takeEvery, put } from 'redux-saga/effects';
import { DateTime } from 'luxon';

import actionsTypes from './action-types';
import * as actions from './action-creators';

import type { AuthModalOpenAction } from './types';
import type { Saga } from 'redux-saga';

function* authModalSaga(): Saga {
  yield takeEvery(actionsTypes.AUTH_MODAL_OPEN, openAuthModal);
}

function* openAuthModal(action: AuthModalOpenAction) {
  // Update this to update the account link
  // default auth domain
  const defaultAuthDomain = 'promotion';

  // TODO: remove after the sale weeks
  const serverCurrentDate = yield select(
    (state) => state.session.config.fakeCurrentDate
  );

  // TODO: remove after the sale weeks
  const currentDate = serverCurrentDate
    ? DateTime.fromISO(serverCurrentDate, { zone: 'utc' })
    : DateTime.utc();

  // TODO: remove after the sale weeks and
  // replace this block with the commented one below
  let authDomain = action.authDomain;
  if (action.authDomain === undefined) {
    if (currentDate < DateTime.utc(2022, 11, 29, 6)) {
      authDomain = 'email-only';
    } else {
      authDomain = defaultAuthDomain;
    }
  }

  // const authDomain =
  //   action.authDomain === undefined ? defaultAuthDomain : action.authDomain;

  yield put(actions.openSuccess(action.view, authDomain, action.force));
}

export default authModalSaga;
