import { ProductTilePayloadType } from 'types/products';
import { RelatedProductType } from 'modules/product/types';

const getCartAddonsProductsAddons = () => ({
  type: 'CART_ADDONS/GET_CART_ADDONS_PRODUCTS'
});

const getCartAddonsProductsSuccess = (
  addonProducts: ProductTilePayloadType[]
) => ({
  type: 'CART_ADDONS/GET_CART_ADDONS_PRODUCTS_SUCCESS',
  addonProducts
});

const getCartAddonsProductsFailure = (err: string) => ({
  type: 'CART_ADDONS/GET_CART_ADDONS_PRODUCTS_FAILURE',
  error: err
});

const getGoogleRecommendations = () => ({
  type: 'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS'
});

const getGoogleRecommendationsSuccess = (
  googleRecommendedProducts: (ProductTilePayloadType | RelatedProductType)[]
) => ({
  type: 'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS_SUCCESS',
  googleRecommendedProducts
});

const getGoogleRecommendationsFailure = (err: string) => ({
  type: 'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS_FAILURE',
  error: err
});

export {
  getCartAddonsProductsAddons,
  getCartAddonsProductsSuccess,
  getCartAddonsProductsFailure,
  getGoogleRecommendations,
  getGoogleRecommendationsFailure,
  getGoogleRecommendationsSuccess
};
