// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export const getPaymentMethods = actionCreators.getPaymentMethods;
export const createPaymentMethod = actionCreators.createPaymentMethod;
export const deletePaymentMethod = actionCreators.deletePaymentMethod;

export type { PaymentMethod, Wallet } from './types';

export default reducer;
