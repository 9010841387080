import * as actionCreators from './action-creators';
import reducer from './reducer';

export type {
  SearchState,
  SearchAutocompleteState,
  AlgoliaProductType,
  AlgoliaTileType,
  DropdownLinks,
  SearchLink
} from './types';

export const {
  updateSearchResults,
  clearSearchResults,
  loadSearchResults,
  fetchSearchHistory,
  fetchDropdownLinks
} = actionCreators;

export default reducer;
