// @flow

import type { SitemapStateType } from './types';

export const getSitemap = () => ({
  type: 'SITEMAP/FETCH'
});

export const getSitemapSuccess = (data: SitemapStateType) => ({
  type: 'SITEMAP/FETCH_SUCCESS',
  data
});

export const getSitemapFailure = (err: any) => ({
  type: 'SITEMAP/FETCH_FAILURE',
  err
});
