// @flow

import api from 'utils/huckberryApi';

const optin = (
  email: string,
  onSuccessful: () => void,
  onFailure: (code: 409 | 422, errorMessage: string) => void
) => {
  api
    .post('/api/email_submissions', { email_submission: { email } })
    .then(() => {
      onSuccessful();
    })
    .catch(({ response: { data, status } }) => {
      onFailure(status, data.message);
    });
};

export default optin;
