import { SagaIterator } from 'redux-saga';
import { select, takeLatest, put } from 'redux-saga/effects';

import {
  yotpoWidgetLoadSuccess,
  yotpoWidgetLoadFailure
} from './action-creators';
import { YotpoStateType, YotpoWidgetLoad } from './types';

import { loadYotpo } from 'utils/loadYotpo';

import { ApplicationState } from 'types/rootState';

function* yotpoSaga(): SagaIterator {
  yield takeLatest('YOTPO_WIDGET/LOAD', yotpoLoadSaga);
}

function* yotpoLoadSaga(action: YotpoWidgetLoad) {
  const yotpoAppKey: string = yield select(
    (state: ApplicationState) => state.session.config.yotpoAppKey
  );
  const yotpoState: YotpoStateType = yield select(
    (state: ApplicationState) => state.yotpo
  );

  setTimeout(() => {
    if (
      window?.yotpoWidgetsContainer &&
      window?.yotpoWidgetsContainer?.initWidgets
    ) {
      window?.yotpoWidgetsContainer?.initWidgets();
    }
  }, action.refreshWidgetTimeout);

  if (yotpoState.loaded) {
    return;
  }

  try {
    loadYotpo(yotpoAppKey);

    yield put(yotpoWidgetLoadSuccess());
  } catch {
    yield put(yotpoWidgetLoadFailure());
  }
}

export default yotpoSaga;
