// @flow

import type { AboutState, InstagramBlocksType } from './index';

const reducer = (
  state: AboutState = null,
  action: { type: string, data: InstagramBlocksType }
) => {
  switch (action.type) {
    case 'ABOUT/FETCH_INSTAGRAM_BLOCKS_SUCCESS':
      return { ...state, instagram: action.data };
    default:
      return state;
  }
};

export default reducer;
