// @flow

export type HistoryState = Array<string>;

type HistoryUpdateAction = {
  type: 'HISTORY/UPDATE',
  pathname: string
};

const defaultHistoryState = [];

const reducer = (
  state: HistoryState = defaultHistoryState,
  action: HistoryUpdateAction
) => {
  switch (action.type) {
    case 'HISTORY/UPDATE': {
      return [...state, action.pathname];
    }
    default:
      return state;
  }
};

export default reducer;
