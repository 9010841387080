// @flow

import type { WishlistType } from './types';

const fetchWishlist = (wishlistId: string) => ({
  type: 'WISHLIST/FETCH',
  wishlistId
});

const fetchWishlistSuccess = (wishlistData: WishlistType) => ({
  type: 'WISHLIST/FETCH_SUCCESS',
  wishlistData
});

const fetchWishlistFailure = (error: any) => ({
  type: 'WISHLIST/FETCH_FAILURE',
  error
});

const clearWishlist = () => ({
  type: 'WISHLIST/CLEAR'
});

const updateWishlist = (wishlistData: WishlistType) => ({
  type: 'WISHLIST/UPDATE',
  wishlistData
});

const updateWishlistSuccess = (wishlistData: WishlistType) => ({
  type: 'WISHLIST/UPDATE_SUCCESS',
  wishlistData
});

const updateWishlistMessage = () => ({
  type: 'WISHLIST/UPDATE_CLEANUP'
});

const updateWishlistFailure = (error: any) => ({
  type: 'WISHLIST/UPDATE_FAILURE',
  error
});

export {
  fetchWishlist,
  fetchWishlistSuccess,
  fetchWishlistFailure,
  clearWishlist,
  updateWishlist,
  updateWishlistSuccess,
  updateWishlistMessage,
  updateWishlistFailure
};
