// @flow

import type { ShippingTouchpointsType } from './types';

export const getShippingTouchpoints = () => ({
  type: 'SHIPPING_TOUCHPOINTS/FETCH'
});

export const getShippingTouchpointsSuccess = (
  data: ShippingTouchpointsType
) => ({
  type: 'SHIPPING_TOUCHPOINTS/FETCH_SUCCESS',
  data
});

// TODO: Figure out shipping touch points failure data type
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Unexpected any. Specify a different type.
export const getShippingTouchpointsFailure = (err: any) => ({
  type: 'SHIPPING_TOUCHPOINTS/FETCH_FAILURE',
  err
});
