import './AlertMessages.scss';

import * as React from 'react';
import classnames from 'classnames';
import some from 'lodash/some';

interface Props {
  messages: {
    type: string;
    content: string;
  }[];
  customDuration?: number | undefined;
}

const AlertMessages = ({ messages, customDuration = 8000 }: Props) => {
  const [visible, setVisible] = React.useState(true);

  const messageTimeoutId = React.useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  React.useEffect(() => {
    messageTimeoutId.current = setTimeout(() => {
      setVisible(false);
    }, customDuration);

    return () => {
      if (messageTimeoutId.current) {
        clearTimeout(messageTimeoutId.current);
      }
    };
  }, [messages, customDuration]);

  if (!visible || messages.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames('AlertMessages fg--small', {
        AlertMessage__success: some(messages, ['type', 'success']),
        AlertMessage__error: some(messages, ['type', 'error'])
      })}
    >
      {messages.map((message, index) => {
        return (
          <React.Fragment key={index}>
            <span>{message.content}</span>{' '}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default AlertMessages;
