// @flow

import reducer from './reducer';

import * as actionCreators from './action-creators';
const { getCallouts } = actionCreators;
export { getCallouts };

export type { CalloutsStateType } from './types';

export default reducer;
