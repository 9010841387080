// @flow

import './PortraitForgotPasswordView.scss';

import * as React from 'react';

import ForgotPasswordForm from 'components/Shared/ForgotPasswordForm';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (view: Views) => void
|};

const PortraitForgotPasswordView = ({ onChangeView }: Props) => (
  <div className="PortraitForgotPasswordView">
    <div className="PortraitForgotPasswordView__wrapper">
      <ForgotPasswordForm onChangeView={onChangeView} textColor="dark" />
    </div>
  </div>
);

export default PortraitForgotPasswordView;
