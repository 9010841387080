// @flow

import type { AddressState } from 'modules/next/checkout/types';

import type { PaymentMethod } from './types';

export function getPaymentMethods() {
  return {
    type: 'WALLET/GET_PAYMENT_METHODS'
  };
}

export function getPaymentMethodsSuccess(paymentMethods: Array<PaymentMethod>) {
  return {
    type: 'WALLET/GET_PAYMENT_METHODS_SUCCESS',
    paymentMethods
  };
}

export function getPaymentMethodsFailure() {
  return {
    type: 'WALLET/GET_PAYMENT_METHODS_FAILURE'
  };
}

export function createPaymentMethod(
  token: string,
  address: AddressState,
  deviceData: string
) {
  return {
    type: 'WALLET/CREATE_PAYMENT_METHOD',
    token,
    address,
    deviceData
  };
}

export function createPaymentMethodSuccess(paymentMethod: PaymentMethod) {
  return {
    type: 'WALLET/CREATE_PAYMENT_METHOD_SUCCESS',
    paymentMethod
  };
}

export function createPaymentMethodFailure() {
  return {
    type: 'WALLET/CREATE_PAYMENT_METHOD_FAILURE'
  };
}

export function deletePaymentMethod(id: number) {
  return {
    type: 'WALLET/DELETE_PAYMENT_METHOD',
    id
  };
}

export function deletePaymentMethodSuccess(id: number) {
  return {
    type: 'WALLET/DELETE_PAYMENT_METHOD_SUCCESS',
    id
  };
}

export function deletePaymentMethodFailure() {
  return {
    type: 'WALLET/DELETE_PAYMENT_METHOD_FAILURE'
  };
}
