// @flow

import { call, put, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import * as actions from './action-creators';
import * as requests from './requests';

function* addressBookSaga(): Saga {
  yield takeEvery('ADDRESS_BOOK/GET_ADDRESSES', getAddressesSaga);
  yield takeEvery('ADDRESS_BOOK/CREATE_ADDRESS', createAddressSaga);
  yield takeEvery('ADDRESS_BOOK/UPDATE_ADDRESS', updateAddressSaga);
  yield takeEvery('ADDRESS_BOOK/DELETE_ADDRESS', deleteAddressSaga);
}

function* getAddressesSaga() {
  try {
    const response = yield call(requests.getAddresses);

    yield put(actions.getAddressesSuccess(response.data));
  } catch (error) {
    yield put(actions.getAddressesFailure());
  }
}

function* createAddressSaga(action) {
  const { address } = action;

  try {
    const response = yield call(requests.createAddress, address);

    yield put(actions.createAddressSuccess(response.data.address));
  } catch (error) {
    const reason = error.response.data.errors.base[0];
    yield put(actions.createAddressFailure(reason));
  }
}

function* updateAddressSaga(action) {
  const { address } = action;

  try {
    const response = yield call(requests.updateAddress, address);
    yield put(actions.updateAddressSuccess(response.data.address));
  } catch (error) {
    const reason = error.response.data.errors.base[0];
    yield put(actions.updateAddressFailure(reason));
  }
}

function* deleteAddressSaga(action) {
  const { id } = action;

  try {
    yield call(requests.deleteAddress, id);

    yield put(actions.deleteAddressSuccess(id));
  } catch (error) {
    yield put(actions.deleteAddressFailure());
  }
}

export default addressBookSaga;
