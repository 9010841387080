// @flow

import setupStore from './setupStore';

import { loadState } from './localStorage';

const persistedState = loadState();
let preloadedState =
  typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : {};

preloadedState = { ...preloadedState, ...persistedState };

const devTools =
  typeof window !== 'undefined' &&
  window.localStorage.getItem('use_redux_devtools') === 'true'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : undefined;

let store;

if (typeof window !== 'undefined' && window.HB?.store) {
  store = window.HB.store;
} else {
  store = setupStore(preloadedState, null, devTools);

  if (typeof window !== 'undefined') {
    window.HB = window.HB || {};
    window.HB.store = store;
  }
}

export default store;
