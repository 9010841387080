// @flow

import type { Address } from './types';

export function getAddresses() {
  return {
    type: 'ADDRESS_BOOK/GET_ADDRESSES'
  };
}

export function getAddressesSuccess(addresses: Array<Address>) {
  return {
    type: 'ADDRESS_BOOK/GET_ADDRESSES_SUCCESS',
    addresses
  };
}

export function getAddressesFailure() {
  return {
    type: 'ADDRESS_BOOK/GET_ADDRESSES_FAILURE'
  };
}

export function createAddress(address: $Shape<Address>) {
  return {
    type: 'ADDRESS_BOOK/CREATE_ADDRESS',
    address
  };
}

export function createAddressSuccess(address: Address) {
  return {
    type: 'ADDRESS_BOOK/CREATE_ADDRESS_SUCCESS',
    address
  };
}

export function createAddressFailure(reason: string) {
  return {
    type: 'ADDRESS_BOOK/CREATE_ADDRESS_FAILURE',
    reason
  };
}

export function updateAddress(address: $Shape<Address>) {
  return {
    type: 'ADDRESS_BOOK/UPDATE_ADDRESS',
    address
  };
}

export function updateAddressSuccess(address: Address) {
  return {
    type: 'ADDRESS_BOOK/UPDATE_ADDRESS_SUCCESS',
    address
  };
}

export function updateAddressFailure(reason: string) {
  return {
    type: 'ADDRESS_BOOK/UPDATE_ADDRESS_FAILURE',
    reason
  };
}

export function deleteAddress(id: number) {
  return {
    type: 'ADDRESS_BOOK/DELETE_ADDRESS',
    id
  };
}

export function deleteAddressSuccess(id: number) {
  return {
    type: 'ADDRESS_BOOK/DELETE_ADDRESS_SUCCESS',
    id
  };
}

export function deleteAddressFailure() {
  return {
    type: 'ADDRESS_BOOK/DELETE_ADDRESS_FAILURE'
  };
}
