const SET_SCREEN_WIDTH = 'UI/SET_SCREEN_WIDTH';
const ENTER_FULLSCREEN = 'UI/ENTER_FULLSCREEN';
const EXIT_FULLSCREEN = 'UI/EXIT_FULLSCREEN';

type UiState = {
  browserWidth: number;
  fullscreen: boolean;
};

const setScreenWidth = (width: number) => {
  return {
    type: actionTypes.SET_SCREEN_WIDTH,
    browserWidth: width
  };
};

const enterFullscreen = () => {
  return {
    type: ENTER_FULLSCREEN
  };
};

const exitFullscreen = () => {
  return {
    type: EXIT_FULLSCREEN
  };
};

const defaultState = {
  browserWidth: 600,
  fullscreen: false
};

const reducer = (
  state: UiState = defaultState,
  action: {
    type: string;
    browserWidth: number;
  }
) => {
  switch (action.type) {
    case SET_SCREEN_WIDTH:
      return {
        ...state,
        browserWidth: action.browserWidth
      };
    case ENTER_FULLSCREEN:
      return {
        ...state,
        fullscreen: true
      };
    case EXIT_FULLSCREEN:
      return {
        ...state,
        fullscreen: false
      };
    default:
      return state;
  }
};

export const actionTypes = {
  SET_SCREEN_WIDTH,
  ENTER_FULLSCREEN,
  EXIT_FULLSCREEN
};

export { setScreenWidth, enterFullscreen, exitFullscreen, UiState };

export default reducer;
