import axios from 'axios';
import camelCase from 'lodash/camelCase';

const camelCaseTransformer = (facet: unknown): unknown => {
  if (Array.isArray(facet)) {
    return facet.map((el: unknown) => camelCaseTransformer(el));
  } else if (typeof facet === 'function' || facet !== Object(facet)) {
    return facet;
  }
  return Object.fromEntries(
    Object.entries(facet as Record<string, unknown>).map(
      ([key, value]: [string, unknown]) => [
        camelCase(key),
        // Recursively transform the nested objects, if needed
        camelCaseTransformer(value)
      ]
    )
  );
};

export const getStyliticsOutfits = (seed: string) =>
  axios
    .get('https://widget-api.stylitics.com/api/outfits', {
      params: {
        item_number: seed,
        locale: 'en-US',
        max: 6,
        min: 3,
        price_hide_double_zero_cents: false,
        price_rounding: 'none',
        return_object: true,
        total: 6,
        username: 'huckberry',
        with_item_coords: false
      }
    })
    .then((response) => camelCaseTransformer(response.data.outfits));
