import * as React from 'react';

import subscribe from 'api/attentive/subscribe';
import unsubscribe from 'api/attentive/unsubscribe';
import eligibility from 'api/attentive/eligibility';
import {
  AttentiveSuccessResponseType,
  AttentiveFailureResponseType
} from 'api/attentive/types';

type AttentiveActions = 'subscribe' | 'unsubscribe' | 'eligibility';
type AttentiveStateType = {
  success: boolean;
  loading: boolean;
  hasRun: boolean;
  attentiveResponse:
    | AttentiveSuccessResponseType
    | AttentiveFailureResponseType;
};

const useAttentive = () => {
  const [attentiveState, setAttentiveState] = React.useState({
    success: false,
    loading: false,
    hasRun: false,
    attentiveResponse: {}
  });

  const attentiveSubscribe = React.useCallback(
    (phone: string, email: string, action: AttentiveActions = 'subscribe') => {
      setAttentiveState({
        success: false,
        loading: true,
        hasRun: false,
        attentiveResponse: {}
      });

      const onSuccessHandler = (responseData: AttentiveSuccessResponseType) => {
        setAttentiveState({
          success: true,
          loading: false,
          hasRun: true,
          attentiveResponse: responseData
        });
      };

      const onFailureHandler = (responseData: AttentiveFailureResponseType) => {
        setAttentiveState({
          success: false,
          loading: false,
          hasRun: true,
          attentiveResponse: responseData
        });
      };

      switch (action) {
        case 'subscribe':
          subscribe(phone, email, onSuccessHandler, onFailureHandler);
          break;
        case 'unsubscribe':
          unsubscribe(phone, email, onSuccessHandler, onFailureHandler);
          break;
        case 'eligibility':
          eligibility(phone, email, onSuccessHandler, onFailureHandler);
          break;
        default:
          onFailureHandler({
            error: `Invalid Attentive action: ${action || ''}`,
            success: false
          });
          break;
      }
    },
    []
  );

  return [attentiveState, attentiveSubscribe] as [
    AttentiveStateType,
    (phone: string, email: string, action: AttentiveActions) => void
  ];
};

export default useAttentive;
