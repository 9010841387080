import { parsePhoneNumber } from 'libphonenumber-js/mobile';

import { email as emailRegex } from 'utils/regex';

const MIN_PASSWD_LENGTH = 6;

const validateEmail = (email: string) => emailRegex.test(email);

const validatePassword = (password: string) =>
  password.length >= MIN_PASSWD_LENGTH;

export const validateUSMobilePhone = (phoneNumber: string) => {
  let parsedPhoneNumber;

  try {
    parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'US');
  } catch {
    return false;
  }

  // NOTE: only MOBILE numbers will pass since we're importing from
  // libphonenumber-js/mobile, others will be parsed but will not be recognized
  // as valid
  // https://github.com/catamphetamine/libphonenumber-js#min-vs-max-vs-mobile-vs-core
  return parsedPhoneNumber.isValid();
};

export { validateEmail, validatePassword };
