// @flow

import types from './action-types';

import type { CalloutsStateType } from './types';

export const getCallouts = (ids: Array<string>) => ({
  type: types.CALLOUTS_FETCH,
  ids
});

export const getCalloutsSuccess = (data: CalloutsStateType) => ({
  type: types.CALLOUTS_FETCH_SUCCESS,
  data
});

export const getCalloutsFailure = (data: CalloutsStateType) => ({
  type: types.CALLOUTS_FETCH_FAILURE,
  data
});

export const getCalloutsCancel = () => ({
  type: types.CALLOUTS_FETCH_CANCEL
});
