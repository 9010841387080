import React from 'react';

import ResponsiveVideo from './ResponsiveVideo';
import ResponsiveImage from './ResponsiveImage';

import { SharedMediaProps } from './responsive-types';

const ResponsiveMedia = ({
  fit,
  crop,
  alt,
  src,
  useHeight,
  mediaProps,
  width,
  height,
  sizes,
  forceImage = false,
  isPicture = false,
  trim,
  aspectRatio,
  lazyLoad = true,
  trimColor,
  trimMeanDifference,
  fill,
  fillColor
}: SharedMediaProps) => {
  const mediaSource = React.useMemo(() => {
    if (!src) {
      return '';
    }

    // This removes any existing parameters that may have been added to the URL
    // and encodes the path.
    if (src.match(/:\/\//)) {
      const srcUrl = new URL(src);
      return `${srcUrl.protocol}//${srcUrl.hostname}${srcUrl.pathname}`;
    }

    return src;
  }, [src]);

  if (!src) {
    return null;
  }

  const useVideo = mediaSource.slice(-4) === '.gif' && !forceImage;

  return (
    <>
      {useVideo && (
        <ResponsiveVideo
          mediaSource={mediaSource}
          useHeight={useHeight}
          width={width}
          height={height}
          sizes={sizes}
          mediaProps={mediaProps}
        />
      )}
      {!useVideo && (
        <ResponsiveImage
          mediaSource={mediaSource}
          fit={fit}
          crop={crop}
          alt={alt}
          useHeight={useHeight}
          width={width}
          height={height}
          mediaProps={mediaProps}
          sizes={sizes}
          isPicture={isPicture}
          trim={trim}
          aspectRatio={aspectRatio}
          lazyLoad={lazyLoad}
          trimColor={trimColor}
          trimMeanDifference={trimMeanDifference}
          fill={fill}
          fillColor={fillColor}
        />
      )}
    </>
  );
};

export default ResponsiveMedia;
