// @flow

import types from './action-types';

export const addItemRequest = (data: any) => ({
  type: types.ADD_ITEM_REQUEST,
  data
});

export const addItemSuccess = (data: any) => ({
  type: types.ADD_ITEM_SUCCESS,
  data
});

export const addItemFailure = (error: string) => ({
  type: types.ADD_ITEM_FAILURE,
  error
});

export const deleteItemRequest = (id: number, placement: string) => ({
  type: types.DELETE_ITEM_REQUEST,
  id,
  placement
});

export const deleteItemSuccess = (id: number, cart: any) => ({
  type: types.DELETE_ITEM_SUCCESS,
  id,
  cart
});

export const deleteItemFailure = (error: string, id: number) => ({
  type: types.DELETE_ITEM_FAILURE,
  error,
  id
});

export const fetchRequest = (orderNumber: string, orderToken: string) => ({
  type: types.FETCH_REQUEST,
  orderNumber,
  orderToken
});

export const fetchSuccess = (data: any) => ({
  type: types.FETCH_SUCCESS,
  data
});

export const fetchFailure = (error: string) => ({
  type: types.FETCH_FAILURE,
  error
});

export const fetchCurrent = () => ({
  type: types.FETCH_CURRENT
});

export const updateItemRequest = (
  id: number,
  quantity: number,
  placement: string
) => ({
  type: types.UPDATE_ITEM_REQUEST,
  id,
  quantity,
  placement
});

export const updateItemSuccess = (id: number, quantity: number, cart: any) => ({
  type: types.UPDATE_ITEM_SUCCESS,
  id,
  quantity,
  cart
});

export const updateItemFailure = (error: string, id: number) => ({
  type: types.UPDATE_ITEM_FAILURE,
  error,
  id
});

export const applyCouponCodeRequest = (couponCode: string) => ({
  type: types.APPLY_COUPON_CODE_REQUEST,
  couponCode
});

export const applyCouponCodeSuccess = (data: any) => ({
  type: types.APPLY_COUPON_CODE_SUCCESS,
  data
});

export const applyCouponCodeFailure = (error: string) => ({
  type: types.APPLY_COUPON_CODE_FAILURE,
  error
});

export const clearErrors = () => ({
  type: types.CLEAR_ERRORS
});
