// @flow

import * as React from 'react';

type Props = {
  autoFocus?: boolean,
  emailInputClassName: string,
  email: string,
  onChangeEmail: (SyntheticKeyboardEvent<*>) => void,
  emailTooltip: React.Node,
  emailError: React.Node
};

const SuggestedEmailInput = ({
  autoFocus,
  emailInputClassName,
  email,
  onChangeEmail,
  emailTooltip,
  emailError
}: Props) => {
  const [showError, setShowError] = React.useState(emailError);
  React.useEffect(() => {
    if (showError) {
      setShowError(false);
    }
  }, [email]);

  return (
    <>
      <div className="input__row pr">
        <input
          type="email"
          id="user_email"
          name="user[email]"
          placeholder="Your Email Address"
          autoFocus={autoFocus}
          className={emailInputClassName}
          value={email}
          onChange={onChangeEmail}
          onBlur={() => setShowError(true)}
        />
        {emailTooltip}
      </div>

      {showError && emailError}
    </>
  );
};
export default SuggestedEmailInput;
