// @flow

import { applyMiddleware, compose } from 'redux';

import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import sagas from 'sagas';

import history from 'store/history';

import createStore from './createStore';

let sagaMiddleware;

const setupStore = (
  preloadedState: any,
  preloadedPathname: ?string,
  enhancers?: any
) => {
  sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  let composeEnhancers = compose;

  const browserHistory = (() => {
    if (typeof window === 'undefined') {
      const splitPath = preloadedPathname?.split('?', 1);
      const pathname = splitPath ? splitPath[0] : '/';
      const search = splitPath ? splitPath[1] : '';
      return {
        location: {
          pathname,
          search
        }
      };
    }

    return history;
  })();

  if (
    process.env.NODE_ENV === 'development' ||
    (typeof window !== 'undefined' &&
      window.localStorage.getItem('use_redux_devtools') === 'true')
  ) {
    const createLogger = require('redux-logger').createLogger;
    const logger = createLogger();
    middlewares.push(logger);
  }

  composeEnhancers = enhancers || compose;
  middlewares.push(routerMiddleware(browserHistory));

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
      if (action.type === 'ACCOUNT_DETAILS/UPDATE') {
        // Return a transformed action to remove password information
        return {
          ...action,
          currentPassword: null,
          password: null,
          passwordConfirmation: null
        };
      }
      return action;
    }
  });

  middlewares.push(routerMiddleware(sentryReduxEnhancer));

  const store = createStore(
    browserHistory,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(function* () {
    yield all(sagas);
  });

  if (typeof window !== 'undefined') {
    // Literal used here to avoid circular dependency
    store.dispatch({ type: 'SESSION/SETUP' });
  }

  return store;
};

export { sagaMiddleware };
export default setupStore;
