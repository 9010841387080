import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';

import { getAnnouncementsById, getAnnouncements } from './requests';
import {
  fetchAnnouncementsError,
  fetchAnnouncementsSuccess
} from './action-creators';

import { FetchAnnouncementByIdAction } from './types';

function* announcementSaga(): SagaIterator {
  yield takeLatest('ANNOUNCEMENTS/FETCH', fetchAnnouncement);
  yield takeLatest('ANNOUNCEMENTS/FETCH_BY_ID', fetchAnnouncementById);
}

function* fetchAnnouncement(): SagaIterator {
  try {
    const response = yield call(getAnnouncements);
    yield put(fetchAnnouncementsSuccess(response.data));
  } catch (e) {
    yield put(fetchAnnouncementsError());
  }
}

function* fetchAnnouncementById(
  action: FetchAnnouncementByIdAction
): SagaIterator {
  try {
    const response = yield call(getAnnouncementsById, action.id);
    yield put(fetchAnnouncementsSuccess([response.data]));
  } catch (e) {
    yield put(fetchAnnouncementsError());
  }
}

export default announcementSaga;
