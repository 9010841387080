// @flow

import type { Saga } from 'redux-saga';
import { call, takeEvery, takeLatest, put, delay } from 'redux-saga/effects';

import { fetchWishlist, updateWishlist } from './requests';
import {
  fetchWishlistSuccess,
  fetchWishlistFailure,
  updateWishlistSuccess,
  updateWishlistFailure,
  updateWishlistMessage
} from './action-creators';

function* wishlistSaga(): Saga {
  yield takeEvery('WISHLIST/FETCH', fetchWishlistSaga);
  yield takeEvery('WISHLIST/UPDATE', updateWishlistSaga);
  yield takeLatest('WISHLIST/UPDATE_SUCCESS', updateWishlistMessageSaga);
}

function* fetchWishlistSaga(action) {
  try {
    const response = yield call(fetchWishlist, action.wishlistId);
    const { data } = response.data;
    yield put(fetchWishlistSuccess(data));
  } catch (err) {
    const { error } = err.response.data;
    yield put(fetchWishlistFailure(error));
  }
}

function* updateWishlistMessageSaga() {
  yield delay(2000);
  yield put(updateWishlistMessage());
}

function* updateWishlistSaga(action) {
  const wishlistData = action;
  try {
    const response = yield call(updateWishlist, wishlistData);
    const { data } = response.data;
    yield put(updateWishlistSuccess(data));
  } catch (err) {
    const { error } = err.response.data;
    yield put(updateWishlistFailure(error));
  }
}

export default wishlistSaga;
