// @flow

import type { LocationChangeAction } from 'connected-react-router';

import { LOCATION_CHANGE } from 'connected-react-router';
import { takeEvery, put } from 'redux-saga/effects';

import { updateHistory } from './action-creators';

function* backInBrowserSaga(): Generator<any, any, any> {
  yield takeEvery(LOCATION_CHANGE, updateScrollHistory);
}

function* updateScrollHistory(action: LocationChangeAction) {
  const pathname = action.payload.location.pathname;
  if (!pathname.includes('/category/p/')) {
    yield put(updateHistory(pathname));
  }
}

export default backInBrowserSaga;
