// @flow

import type { ApplicationState } from 'types';
import type { SessionState } from 'modules/session';

import * as React from 'react';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';

import LoadingFallback from 'components/utils/LoadingFallback';

import PopoverCart from 'components/CartMini/PopoverCart';
import AuthModal from 'components/AuthModal/AuthModal';
import AlertMessages from 'components/AppContainer/Shared/AlertMessages';
import SMSModalContainer from 'components/SMSModal/SMSModalContainer';

import JournalContainerLoader from 'components/JournalContainer/JournalContainerLoader';

import { trackAction, events } from 'modules/analytics';
import { fetchSession } from 'modules/session';
import { setScreenWidth } from 'modules/ui';

const LoadableMainContainer = loadable(
  () => import('components/MainContainer'),
  {
    fallback: LoadingFallback()
  }
);

const Preferences = loadable(() => import('components/Legal/Preferences'), {
  fallback: LoadingFallback()
});

const CartPageLoader = loadable(
  () => import('components/CartPage/CartPageRefresh'),
  {
    fallback: LoadingFallback()
  }
);

const Checkout = loadable(
  () => import('components/CheckoutFlow/CheckoutContainer'),
  {
    fallback: LoadingFallback()
  }
);

const LoadableAdminToolbar = loadable(() =>
  import('components/admin/Toolbar/Toolbar')
);

const FullPageAuthLoader = loadable(
  () => import('components/FullPageAuth/FullPageAuth'),
  {
    fallback: LoadingFallback()
  }
);

const AboutPageLoader = loadable(
  // @ts-ignore
  () => import('components/Pages/Hardcoded/About'),
  {
    fallback: LoadingFallback()
  }
);

type ConnectedProps = {|
  session: SessionState,
  fetchSession: () => void,
  setScreenWidth: (number) => void
|};

export type AppProps = {|
  ...ConnectedProps
|};

type ModalLoaderProps = {|
  session: SessionState
|};

const ModalLoaders = ({ session }: ModalLoaderProps) => {
  if (session.loadedAt && !session.signedIn && !session.showHardGate) {
    return <AuthModal />;
  }

  if (session.signedIn && !session.signedInUser.phoneNumber) {
    return <SMSModalContainer />;
  }

  return null;
};

const App = ({ fetchSession, setScreenWidth, session }: AppProps) => {
  const { loadedAt, messages, signedInUser, showHardGate } = session;
  React.useEffect(() => {
    fetchSession();
    setScreenWidth(window.innerWidth);

    window.addEventListener(
      'resize',
      debounce(() => {
        setScreenWidth(window.innerWidth);
      }, 150)
    );

    window.HB.debug = (state: boolean) => {
      window.localStorage.setItem('use_redux_devtools', state);
    };
  }, [fetchSession, setScreenWidth]);

  const hasToolbar = signedInUser && signedInUser.hasToolbar;

  return (
    <React.Fragment>
      <AlertMessages messages={messages} />

      <Switch>
        <Route
          path="/"
          exact
          render={(props) => {
            if (!loadedAt) {
              return null;
            }

            if (showHardGate) {
              trackAction(events.HARD_GATE_VIEWED, {});

              return <FullPageAuthLoader />;
            }

            return (
              <>
                <LoadableMainContainer {...props} />

                <ModalLoaders session={session} />
              </>
            );
          }}
        />

        <Route path="/login" exact component={FullPageAuthLoader} />
        <Route path="/signup" exact component={FullPageAuthLoader} />
        <Route path="/password/recover" exact component={FullPageAuthLoader} />
        <Route path="/password/change" exact component={FullPageAuthLoader} />
        <Route path="/promos/:promoId" exact component={FullPageAuthLoader} />
        <Route path="/checkout/next" exact component={Checkout} />
        <Route path="/cart" exact component={CartPageLoader} />

        <Route
          path="/journal"
          render={(props) => (
            <>
              <JournalContainerLoader {...props} />

              <ModalLoaders session={session} />
            </>
          )}
        />

        <Route path="/about" exact component={AboutPageLoader} />
        <Route path="/preferences" exact component={Preferences} />

        <Route
          path="*"
          render={(props) => (
            <>
              <LoadableMainContainer {...props} />

              <ModalLoaders session={session} />
            </>
          )}
        />
      </Switch>

      {hasToolbar && <LoadableAdminToolbar />}

      <PopoverCart contained />
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    session: state.session
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSession: () => dispatch(fetchSession()),
  setScreenWidth: (width) => dispatch(setScreenWidth(width))
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Sentry.withProfiler(App)): React.ComponentType<
  $Diff<AppProps, ConnectedProps>
>);
