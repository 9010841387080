// @flow

import type { Saga } from 'redux-saga';

import { LOCATION_CHANGE } from 'connected-react-router';
import { select, call, takeEvery, put } from 'redux-saga/effects';

import isEmpty from 'lodash/isEmpty';
import api from 'utils/huckberryApi';

import { updateRedirects } from './action-creators';

const getRedirects = (state) => state.redirects;

function apiGetRedirects() {
  return api.get('/api/redirects');
}

function* redirectsSaga(): Saga {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}

function* handleLocationChange() {
  const redirectRoutes = yield select(getRedirects);

  if (isEmpty(redirectRoutes)) {
    const redirects = yield call(apiGetRedirects);
    yield put(updateRedirects(redirects.data));
  }
}

export default redirectsSaga;
