// @flow

const loadState: Object = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState) {
      return JSON.parse(serializedState);
    }

    return {};
  } catch (error) {
    return {};
  }
};

const saveState = (key: string, state: Object) => {
  try {
    const serializedState = JSON.stringify({ [key]: state });
    localStorage.setItem('state', serializedState);
  } catch (error) {
    // Ignore errors
  }
};

// Here we can define which modules should be saved
// inside the session storage.
const savableModules = ['authModal'];

export { loadState, saveState, savableModules };
