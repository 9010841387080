import { RestockNotificationState, RestockNotificationActions } from './types';

const defaultState = {
  isLoading: false,
  variantIds: []
};

const reducer = (
  state: RestockNotificationState = defaultState,
  action: RestockNotificationActions
): RestockNotificationState => {
  switch (action.type) {
    case 'RESTOCK_NOTIFICATIONS/FAILURE':
      return {
        ...state,
        isLoading: false
      };
    case 'RESTOCK_NOTIFICATIONS/ADD':
    case 'RESTOCK_NOTIFICATIONS/FETCH_START':
      return {
        ...state,
        isLoading: true
      };
    case 'RESTOCK_NOTIFICATIONS/FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        variantIds: action.variantIds
      };
    case 'RESTOCK_NOTIFICATIONS/ADD_SUCCESS':
      return {
        ...state,
        isLoading: false,
        variantIds: [...state.variantIds, action.variantId]
      };
    default:
      return state;
  }
};

export default reducer;
