// @flow

import * as React from 'react';

import JournalWelcomeView from './JournalWelcomeView';

import { events as analytics, trackAction } from 'modules/analytics';

import type { Views, AuthDomain } from 'modules/auth-modal/types';

type Props = {|
  modalDomain: AuthDomain,
  onChangeView: (Views) => void
|};

const WelcomeView = ({ modalDomain, onChangeView }: Props) => {
  React.useEffect(() => {
    trackAction(analytics.SOFT_GATE_STEP_VIEWED, {
      label: modalDomain,
      context_step_sequence: 'welcome'
    });
  }, [modalDomain]);

  switch (modalDomain) {
    case 'journal':
      return <JournalWelcomeView onChangeView={onChangeView} />;
    case 'email-only':
    case 'big-event-coming':
    case 'sitewide-sale':
    case 'pappy-day':
    case 'promotion':
      onChangeView('signup');
      return null;
    default:
      return null;
  }
};

export default WelcomeView;
