import api from 'utils/huckberryApi';

export const fetchCart = (orderNumber, orderToken) =>
  api.get(`/api/carts/${orderNumber}`, {
    headers: {
      'X-Spree-Order-Token': orderToken
    }
  });

export const fetchCurrent = () => api.get('/api/carts/current.json');

export const populateCart = (data) => api.post('/orders/populate', data);

export const updateCart = (id, quantity) => {
  const params = {
    order: {
      line_items_attributes: [{ id, quantity }]
    }
  };

  return api.patch('/cart.json', params);
};

export const applyCouponCode = (orderNumber, orderToken, couponCode) => {
  const params = {
    couponCode
  };

  return api.put(`/api/carts/${orderNumber}/apply_coupon_code`, params, {
    headers: {
      'X-Spree-Order-Token': orderToken
    }
  });
};
