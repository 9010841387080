// @flow

import api from 'utils/huckberryApi';

import type { WishlistType } from './types';

export const fetchWishlist = (wishlistId: string) =>
  api.get(`/api/wishlist/${wishlistId}`);

export const updateWishlist = (wishlistData: WishlistType) => {
  return api.put('/api/wishlist', wishlistData);
};
