import includes from 'lodash/includes';

import { ActivePageFiltersState, PageFiltersOnly } from 'modules/page';

export default function toggleFilter(
  filtersActive: ActivePageFiltersState,
  type: keyof PageFiltersOnly,
  value: string,
  defaultSort?: string
): ActivePageFiltersState {
  const { sort, ...filters } = filtersActive;
  const filtersForType: string[] = filters[type];

  switch (value) {
    // Handle Sort
    case 'price_asc':
    case 'price_desc':
    case 'published_desc':
    case 'trending':
    case 'primary':
      if (sort === value || value === defaultSort) {
        return { ...filtersActive, sort: null };
      }

      return { ...filtersActive, sort: value };

    default:
      if (includes(filtersForType, value)) {
        // This removes a Filter
        return {
          ...filtersActive,
          [type]: filtersForType.filter((e) => e !== value)
        };
      }

      // This adds a Filter
      return {
        ...filtersActive,
        [type]: [...filtersForType, value]
      };
  }
}
